import { Account } from '../../../../../../../../store/account/account.types';

export const moveCurrentAccountToTop = (accountsList: Account[] = [], currentAccountId: number) => {
  const listCopy = [...accountsList];
  const index = listCopy.findIndex((acc) => acc.id === currentAccountId);
  if (index > 0) {
    listCopy.unshift(listCopy.splice(index, 1)[0]);
  }

  return listCopy;
};
