import RedisApiAxiosInstance from '../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { AaChangeRequestPayload, ExecuteAaSubsCallParams } from './pro.types';

export const aaChangeRequest = (body: { aaChangeRequest: AaChangeRequestPayload }) =>
  RedisApiAxiosInstance.post<{ aaChangeRequest: AaChangeRequestPayload }>(
    '/AaChangeRequests',
    body
  );

export const getAAChangeRequestStatus = (aaChangeRequestId: number) =>
  RedisApiAxiosInstance.get<{ aaChangeRequest: AaChangeRequestPayload }>(
    `/AaChangeRequests/${aaChangeRequestId}`
  );

export const executeAaChangeRequest = ({
  aaChangeRequestId,
  subscriptionName,
  paymentId,
  isMarketplace
}: ExecuteAaSubsCallParams) =>
  RedisApiAxiosInstance.post(`/AaChangeRequests/${aaChangeRequestId}/execute`, {
    aaChangeRequestExecution: {
      payment_info_id: paymentId,
      subscription_name: subscriptionName,
      is_marketplace: isMarketplace
    }
  });
