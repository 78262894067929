import { MfaActionTypes, MfaActionTypesEnum, MfaState } from './mfa.types';

const initialState = {
  status: 'pending',
  error: '',
  data: {}
} as MfaState;

const mfaReducer = (state = initialState, action: MfaActionTypes): typeof initialState => {
  switch (action.type) {
    case MfaActionTypesEnum.MFA_INFO_SUCCESS: {
      return {
        ...state,
        status: 'idle',
        data: action.payload
      };
    }

    case MfaActionTypesEnum.MFA_SMS_VERIFY_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          phoneNumber: action.payload,
          smsFactorAvailable: true
        }
      };
    }

    case MfaActionTypesEnum.MFA_SMS_VERIFY_FAILURE:
    case MfaActionTypesEnum.MFA_TOTP_VERIFY_FAILURE: {
      return {
        ...state,
        error: action.error
      };
    }

    case MfaActionTypesEnum.MFA_TOTP_VERIFY_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          totpFactorAvailable: true
        }
      };
    }

    case MfaActionTypesEnum.MFA_TOTP_DISABLE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          totpFactorAvailable: false
        }
      };
    }

    case MfaActionTypesEnum.MFA_SET_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }

    default:
      return state;
  }
};

export default mfaReducer;
