type Unit = 'Bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB';

export interface FormatBytesProps {
  bytes: number;
  decimals?: number;
  unit?: Unit;
  isSpace?: boolean;
  withoutSuffix?: boolean;
}

const formatBytes = ({
  bytes,
  decimals = 0,
  unit,
  isSpace = true,
  withoutSuffix = false
}: FormatBytesProps) => {
  if (bytes === 0 && withoutSuffix) return '0';
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const explicitSizeIndex = sizes.findIndex((size) => size === unit);

  const i = explicitSizeIndex >= 0 ? explicitSizeIndex : Math.floor(Math.log(bytes) / Math.log(k));

  const formattedBytes = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toString();

  if (withoutSuffix) {
    return formattedBytes;
  }

  return `${formattedBytes}${isSpace ? ' ' : ''}${sizes[i]}`;
};

export default formatBytes;
