import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { TypeOptions, ToastOptions, toast, Slide } from 'react-toastify';
import { NOTIFICATION_CONTAINER_ID, toastDataTestId, variantIcons } from './Notification.constants';
import { NotificationInnerContentProps } from './Notification.types';
import * as S from './Notification.style';

const NotificationInnerContent = ({
  variant,
  title,
  body,
  buttonProps,
  cancelIcon = true,
  dataTestId
}: NotificationInnerContentProps) => {
  return (
    <S.NotificationWrapper
      data-testid={dataTestId || toastDataTestId[variant] || 'toast-container'}
    >
      <S.NotificationIcon fill="white" width="2rem" height="2rem" name={variantIcons[variant]} />
      <S.NotificationBody>
        {title && <S.Title>{title}</S.Title>}
        {body}
      </S.NotificationBody>
      {buttonProps && (
        <S.ActionButton type="button" colorVariant={variant} {...buttonProps}>
          {buttonProps.content}
        </S.ActionButton>
      )}
      {cancelIcon && (
        <S.CancelIcon fill="white" width="2rem" height="2rem" name={IconNamesEnum.CANCEL} />
      )}
    </S.NotificationWrapper>
  );
};

export const showNotification = (
  componentProps: Omit<NotificationInnerContentProps, 'variant'>,
  variant: TypeOptions,
  notificationOptions?: Omit<ToastOptions, 'type'>
) => {
  toast[variant](<NotificationInnerContent variant={variant} {...componentProps} />, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 15000,
    hideProgressBar: true,
    transition: Slide,
    pauseOnHover: true,
    containerId: NOTIFICATION_CONTAINER_ID,
    ...notificationOptions,
    closeButton: false
  });
};

export const NotificationContainer = () => {
  return <S.NotificationContainer enableMultiContainer containerId={NOTIFICATION_CONTAINER_ID} />;
};
