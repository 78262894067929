export const IMPORTING_DB_TO_BDB_LINK = 'https://docs.redis.com/latest/rc/databases/import-data/';

export const ACL_DOCS_LINK =
  'https://docs.redis.com/latest/rc/security/access-control/data-access-control/role-based-access-control/';

export const REDIS_ON_FLASH_LINK =
  'https://docs.redis.com/latest/rs/concepts/memory-architecture/redis-flash/';

export const ACTIVE_ACTIVE_LINK =
  'https://docs.redis.com/latest/rc/subscriptions/create-active-active-subscription/';

export const HIGH_AVAILABILITY_LINK =
  'https://docs.redis.com/latest/rc/databases/high-availability/';

export const MULTI_ZONE_AVAILABILITY_LINK =
  'https://docs.redis.com/latest/rc/databases/high-availability/';

export const CLUSTER_LINK =
  'https://docs.redis.com/latest/rs/concepts/data-access/oss-cluster-api/';

export const DATA_PERSISTENCE_LINK = 'https://docs.redis.com/latest/rc/concepts/data-persistence/';

export const DATA_EVICTION_LINK =
  'https://docs.redis.com/latest/rc/concepts/data-eviction-policies/?s=data%20eviction';

export const ACTIVE_PASSIVE_REDIS_LINK =
  'https://docs.redis.com/latest/rc/databases/migrate-databases/#sync-using-active-passive';

export const DEFAULT_USER_LINK =
  'https://docs.redis.com/latest/rc/security/access-control/data-access-control/default-user/';

export const ALLOW_LIST_LINK =
  'https://docs.redis.com/latest/rc/security/database-security/network-security/';

export const TLS_LINK = 'https://docs.redis.com/latest/rc/security/database-security/tls-ssl/';

export const DB_BACKUP_LINK =
  'https://docs.redis.com/latest/rc/administration/configuration/backups/';

export const MODULES_LINK = 'https://docs.redis.com/latest/modules/?s=modules';

export const HASHING_LINK =
  'https://docs.redis.com/latest/rc/databases/configuration/clustering/#changing-the-hashing-policy';

export const ITEM_SIZE_LINK =
  'https://docs.redis.com/latest/rs/concepts/memory-architecture/redis-flash/';

export const CLOUD_ACCOUNT_LINK =
  'https://docs.redis.com/latest/rc/how-to/view-edit-cloud-account/';

export const SASL_AUTH_LINK =
  'https://docs.redis.com/latest/rs/administering/designing-production/security/';

export const ENFORCE_MFA =
  'https://docs.redis.com/latest/rc/security/access-control/multi-factor-authentication/#enforcing';

export const API_KEYS_LINK = 'https://docs.redis.com/latest/rc/api/';

export const DATABASE_CLUSTERING_LINK = 'https://docs.redis.com/latest/rc/concepts/clustering/';

export const REDISEARCH_SIZING_CALCULATOR_LINK =
  'https://redis.com/modules/redis-search/redisearch-sizing-calculator/';

export const MEMORY_LIMIT_LINK =
  'https://docs.redis.com/latest/rc/databases/create-database/#memory-size';

export const CREATE_DB_MODULES_OPTIONS_LINK =
  'https://docs.redis.com/latest/rc/databases/create-database/#flexible-and-annual-module-options';

export const SUPPORT_LINK = 'https://redis.com/company/support/';

export const CREATE_DATABASE_SCALABILITY_LINK =
  'https://docs.redis.com/latest/rc/databases/create-database/#scalability-section';

export const CLOUD_ACCOUNT_INFO_LINK = 'https://docs.redis.com/latest/rc/cloud-accounts/';

export const GCP_CONSOLE_PSC_LINK = 'https://console.cloud.google.com/networking/networks/';

export const SAML_LINK = 'https://docs.redis.com/latest/rc/security/access-control/saml-sso/';

export const SAML_LINK_SETUP_APP =
  'https://docs.redis.com/latest/rc/security/access-control/saml-sso/#set-up-app';

export const REDIS_PRIVATE_SERVICE_CONNECT_LINK =
  'https://docs.redis.com/latest/rc/security/private-service-connect/';

export const PRIVATE_SERVICE_CONNECT_GCLOUD_LINK = 'https://cloud.google.com/sdk/docs/install';

export const TERMS_LINK = 'https://redis.com/legal/cloud-tos/';
export const PRIVACY_LINK = 'https://redis.com/legal/privacy-policy/';

export const VPC_PEERING_LINK = 'https://docs.redis.com/latest/rc/security/vpc-peering/';

export const REDIS_CLI_LINK = 'https://redis.io/topics/quickstart';

export const REDIS_INSIGHT_LINK = 'https://redis.com/redis-enterprise/redis-insight/';

export const REDIS_7_PREVIEW_LINK =
  'https://docs.redis.com/latest/rc/subscriptions/create-fixed-subscription/#create-a-fixed-subscription';

export const REDIS_7_ACL_RULES_LINK =
  'https://docs.redis.com/latest/rc/security/access-control/data-access-control/configure-acls/#pubsub-acl-rules';

export const ACL_RULES_LINK = 'https://redis.io/docs/management/security/acl/#acl-rules';
