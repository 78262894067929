import { AxiosResponse } from 'axios';
import RedisApiAxiosInstance from '../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { GetSystemLogsPayload, GetSystemLogsResponse } from './systemLogs.types';

export const getSystemLogs = ({
  textSearch,
  pageSize,
  page,
  sortAscending,
  sortedColumn
}: GetSystemLogsPayload): Promise<AxiosResponse<GetSystemLogsResponse>> =>
  RedisApiAxiosInstance.get('/systemLogs', {
    params: {
      filter_by: textSearch || '',
      page,
      per_page: pageSize,
      sortAscending,
      sortedColumn
    }
  });
