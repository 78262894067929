import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import * as S from './Redis7OptInTooltipContent.style';
import { getDataTestId } from '../../../../../../../../../../utils/dataAttributesHelpers';
import { REDIS_7_PREVIEW_LINK } from '../../../../../../../../../../utils/constants/docLinks';

const Redis7OptInTooltipContent = () => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.redis7OptIn;

  return (
    <div>
      <S.Title {...getDataTestId('readonlylabel', 'redis-7-explanation-title')}>
        {t(keyPrefix.optInTooltipTitle)}
      </S.Title>
      <p {...getDataTestId('readonlyvalue', 'redis-7-explanation')}>
        {t(keyPrefix.optInInfoDescription)}
      </p>
      <S.ReadMore readMoreLink={REDIS_7_PREVIEW_LINK} />
    </div>
  );
};

export default Redis7OptInTooltipContent;
