import { isInteger } from 'lodash';
import { EssentialPlan } from '../../store/createSubscription/essentials/essentials.types';

const parseRegionIds = (redis7RegionIds: string | number) => {
  if (typeof redis7RegionIds !== 'string') {
    if (isInteger(redis7RegionIds)) {
      return [redis7RegionIds];
    }

    return [];
  }

  const result = redis7RegionIds
    .replace(/\s+/g, '')
    .split(',')
    .filter((str) => str)
    .map(Number);

  if (!result || result?.some((regionId) => !Number.isInteger(regionId))) {
    return [];
  }

  return result;
};

const getOnlyRedis7Plans = (plans: EssentialPlan[], redis7RegionIds: string) =>
  plans.filter((plan) => {
    return parseRegionIds(redis7RegionIds).includes(plan.region_id);
  });

const getIsSupportingRedis7 = (redis7RegionIds: string | number) =>
  parseRegionIds(redis7RegionIds).length > 0;

const redis7Utils = {
  getOnlyRedis7Plans,
  parseRegionIds,
  getIsSupportingRedis7
};

export default redis7Utils;
