import styled, { css } from 'styled-components/macro';
import { SvgIcon, Tooltip } from '@redislabsdev/redislabs-ui-components';

const textEllipsisStyle = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NameAndArrowContainer = styled.div<{ shouldNotifyProductionForAgent: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme, shouldNotifyProductionForAgent }) =>
    shouldNotifyProductionForAgent ? theme.colors.white : theme.colors.darkBlue1};
`;

export const UserName = styled.span<{ shouldNotifyProductionForAgent: boolean }>`
  ${textEllipsisStyle};
  color: ${({ theme, shouldNotifyProductionForAgent }) =>
    shouldNotifyProductionForAgent ? theme.colors.white : theme.colors.blue2};
  font-size: ${({ theme }) => theme.fontSizes.s12}; ;
`;

export const AccountName = styled.span`
  ${textEllipsisStyle};
`;

export const MenuContainer = styled.div<{ shouldNotifyProductionForAgent: boolean }>(
  ({ theme, shouldNotifyProductionForAgent }) => css`
    display: flex;
    flex: 1;
    padding: 0.4rem 0.8rem;
    flex-direction: column;
    cursor: pointer;
    max-width: 24rem;
    &:hover {
      ${NameAndArrowContainer} {
        color: ${shouldNotifyProductionForAgent ? theme.colors.white : theme.colors.blue6};
      }
      ${UserName} {
        color: ${shouldNotifyProductionForAgent ? theme.colors.white : theme.colors.blue6};
      }
      border-radius: 4px;
      background-color: ${shouldNotifyProductionForAgent
        ? theme.colors.pink2
        : theme.colors.lightGray4};
    }
  `
);

export const ArrowIcon = styled(SvgIcon)<{ $isMenuOpen: boolean }>`
  transform: rotate(${({ $isMenuOpen }) => ($isMenuOpen ? 180 : 0)}deg);
  transition: transform 0.3s;
  margin-left: 0.8rem;
  path {
    fill: currentColor;
  }
`;

export const UserMenuContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 12rem;
  margin-right: 1.6rem;
`;

export const UserMenuTooltip = styled(Tooltip)`
  flex: 1;
`;
