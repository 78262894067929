import styled from 'styled-components/macro';
import { SideBar } from '@redislabsdev/redislabs-ui-components';

export const SideBarButton = styled(SideBar.Button)`
  margin-top: 4.2rem;
  margin-bottom: 2rem;
`;

export const FooterLinksContainer = styled.div`
  margin-bottom: 0.2rem;
`;

export const SideBarHeader = styled(SideBar.Header)`
  height: 7rem;
  flex-shrink: 0;
`;

export const ScrollContainer = styled.div`
  overflow: hidden;
`;

export const Content = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const SideBarFooterMetaData = styled(SideBar.Footer.MetaData)`
  white-space: nowrap;
  overflow: hidden;
`;
