import { put, select, call, takeLatest, delay } from 'redux-saga/effects';
import { isEqual, keyBy } from 'lodash';
import { AxiosResponse } from 'axios';
import { routes } from '../../utils/constants/routes';
import { SubscriptionsActionTypes, UpdateSubscriptionRequestAction } from './subscriptions.types';
import { subscriptionStateSelector } from './subscriptions.selectors';
import * as subscriptionActions from './subscriptions.actions';
import * as planActions from './plans/plans.actions';
import history from '../../hashHistory';
import { callsInterval } from '../../utils/constants/api/intervals';
import { sortById } from '../../utils/helpers/misc';
import { getWindowOpenTarget, routeToFirstSubscription } from './subscriptions.utils';
import { isLoggedInSelector, productTypeForPollingSelector } from '../auth/auth.selectors';
import { extractErrorAndShowToast } from '../../utils/helpers/extractErrorAndShowToast';
import { isOnSubsOrDatabasesRoute } from '../../utils/isOnSubsOrDatabasesRoute';
import { subscriptionsApi } from '../../services/api/resources/subscriptions/subscriptions.resource';

function* startSubscriptionsPolling() {
  const subRootPath = routes.subscriptions.root;

  while (isOnSubsOrDatabasesRoute().isOnDbsOrSubs) {
    const isLoggedIn = yield select(isLoggedInSelector);
    if (!isLoggedIn) {
      break;
    }

    if (history.location.pathname === subRootPath) {
      routeToFirstSubscription();
    }

    if (document?.visibilityState === 'visible') {
      try {
        const productType: ProductType = yield select(productTypeForPollingSelector);

        yield setSubscriptions({ productType, includeNextPaymentDate: false });
      } catch (e) {
        if (e.response) {
          const { data } = e.response;
          yield put(subscriptionActions.getSubscriptionsFailure(data));
          break;
        }
      }
    }

    yield delay(callsInterval);
  }
}

function* getSubscriptions() {
  const productType: ProductType = yield select(productTypeForPollingSelector);
  try {
    yield setSubscriptions({ productType, includeNextPaymentDate: true });
  } catch (e) {
    yield put(subscriptionActions.getSubscriptionsFailure(e.response.data));
  }
}

function* setSubscriptions(params: GetSubscriptionParams) {
  const rootPath = routes.subscriptions.root;
  const { data: subscriptions, status: subsStatus }: SubscriptionsState = yield select(
    subscriptionStateSelector
  );

  try {
    const { data: subs }: AxiosResponse<{ subscriptions: Subscription[] }> = yield call(
      subscriptionsApi.getAll,
      params
    );

    const sortedSubs: Subscription[] = sortById(subs.subscriptions);
    sortedSubs.forEach((sub) => sortById(sub.minimal_pricing_regions));

    const subsById = keyBy(sortedSubs, 'id');

    if (!isEqual(subsById, subscriptions) || subsStatus === 'pending') {
      yield put(subscriptionActions.getSubscriptionsSuccess(subsById));
      yield put(planActions.getPlans());
    }

    if (history.location.pathname === rootPath && !subscriptions?.length) {
      routeToFirstSubscription(sortedSubs);
    }
  } catch (e) {
    if (e.response) {
      const { data, status } = e.response;
      yield put(subscriptionActions.getSubscriptionsFailure(data));
      throw status;
    }
  }
}

export function* updateSubscriptionSaga({ payload }: UpdateSubscriptionRequestAction) {
  try {
    const { data }: AxiosResponse<{ subscription: Subscription }> = yield call(
      subscriptionsApi.update,
      payload.subscription
    );
    yield put(subscriptionActions.updateSubscriptionSuccess(data.subscription));

    if (payload.onSuccess) {
      payload.onSuccess();
    }

    if (data.subscription.pay_now_url) {
      window.open(data.subscription.pay_now_url, getWindowOpenTarget(data.subscription.id));
    }
  } catch (e) {
    yield put(subscriptionActions.updateSubscriptionFailure('Failed to update subscription'));
    extractErrorAndShowToast(e);

    if (payload.onError) {
      payload.onError();
    }
  } finally {
    yield delay(2000);
    yield put(subscriptionActions.resetUpdateSubscriptionState());
  }
}

function* subscriptionSaga() {
  yield takeLatest(SubscriptionsActionTypes.START_SUBSCRIPTIONS_POLLING, startSubscriptionsPolling);
  yield takeLatest(SubscriptionsActionTypes.GET_SUBSCRIPTIONS_REQUEST, getSubscriptions);
  yield takeLatest(SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_REQUEST, updateSubscriptionSaga);
}

export default subscriptionSaga;
