import { UseComboboxStateChange } from 'downshift';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import * as S from './CreationRegionSelect.style';
import { CreationRegionSelectProps } from './CreationRegionSelect.types';
import Redis7OptInToggle from './components/Redis7OptInToggle/Redis7OptInToggle';
import RenderRegionItem from './components/RenderRegionItem/RenderRegionItem';
import { typeGuard } from '../../../../../../utils/typeGuard';
import { parametersQueries } from '../../../../../../queryClient/parameters/parameters.queries';
import { accountIdSelector } from '../../../../../../store/auth/auth.selectors';
import getIsRequestMoreRegionsVisible from './utils/getIsRequestMoreRegionsVisible';

const CreationRegionSelect = ({
  selectedRegion,
  onRegionSelect,
  isSupportingRedis7OptIn,
  onRedis7OptInClick,
  withMoreRegionsPageLink,
  isOptedToRedis7,
  allRegions,
  dropdownProps,
  withTitle = true
}: CreationRegionSelectProps) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.createSubscription.cloudVendor;
  const { systemParams } = parametersQueries.useSystemParameters();
  const handleRegionSelect = (option: UseComboboxStateChange<CloudRegion>) => {
    if (!option.selectedItem.region_id) {
      return;
    }

    onRegionSelect(option.selectedItem);
  };

  const accountId = useSelector(accountIdSelector);

  const isRequestMoreRegionsVisible = getIsRequestMoreRegionsVisible({
    withMoreRegionsPageLink,
    systemParams,
    currentAccountId: Number(accountId)
  });

  const regionsItems = isRequestMoreRegionsVisible
    ? [...allRegions, { moreRegionsPageLink: true }]
    : allRegions;

  const handleRegionFilter = (items: typeof regionsItems, searchValue: string) => {
    if (!searchValue) return items;

    return items.filter((item) => {
      const isCloudRegion = typeGuard<CloudRegion>('region_id');

      if (isCloudRegion(item)) {
        return [item.name, item.city_name, item.country_name]
          .join(' ')
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      }

      return true;
    });
  };

  const renderRegionItem = (region: CloudRegion) => {
    const isCloudRegion = typeGuard<CloudRegion>('region_id');

    return isCloudRegion(region) ? (
      <RenderRegionItem region={region} isSupportingRedis7OptIn={isSupportingRedis7OptIn} />
    ) : (
      <S.MoreRegionsPageLink readMoreLink={systemParams.more_regions_page_link.toString()}>
        {t(keyPrefix.moreRegionsPageLinkText)}
      </S.MoreRegionsPageLink>
    );
  };

  return (
    <S.RegionSelectContainer data-testid="single-region-select">
      <S.HeaderContainer>
        {withTitle && <S.Title>{t(keyPrefix.selectRegion)}</S.Title>}
        {isSupportingRedis7OptIn && (
          <Redis7OptInToggle {...{ onRedis7OptInClick, isOptedToRedis7 }} />
        )}
      </S.HeaderContainer>
      <S.DropdownMenu
        items={regionsItems}
        renderItem={renderRegionItem}
        selectedItem={selectedRegion}
        onSelectedItemChange={handleRegionSelect}
        dataTestIdSuffix="region"
        toggleButtonId="selection-area-button--region-select"
        filterFunction={handleRegionFilter}
        isSearchable
        isRequestMoreRegionsVisible={isRequestMoreRegionsVisible}
        {...dropdownProps}
      />
    </S.RegionSelectContainer>
  );
};

export default CreationRegionSelect;
