import { isEmpty } from 'lodash';
import { UseQueryOptions } from '@tanstack/react-query';
import { convertToEssentialPlans } from './convertToEssentialPlans';
import useGetSubscriptionByParam from '../useGetSubscriptionByParam';
import { EssentialPlan } from '../../store/createSubscription/essentials/essentials.types';
import { plansApi } from '../../services/api/resources/plans/plans.resource';
import useSmQuery from '../../queryClient/useSmQuery';

export const getEssentialPlansQueryKey = (subscriptionId?: string) => [
  'essential-plans',
  String(subscriptionId) || 'unifiedrc'
];

const getEssentialPlansAndUpdateSelectable = async (subscription?: Subscription) => {
  const unifiedRc = { db_type: 'unifiedrc' };
  const selectableParams = isEmpty(subscription) ? unifiedRc : { subscription: subscription.id };
  const essentialParams = isEmpty(subscription) ? unifiedRc : { sub_id: subscription.id };

  const essentialPlansRequest = plansApi.getAll(essentialParams);
  const selectablePlansRequest = plansApi.getAllSelectable(selectableParams);

  const [essentialPlansResult, selectablePlansResult] = await Promise.all([
    essentialPlansRequest,
    selectablePlansRequest
  ]);

  return convertToEssentialPlans(
    essentialPlansResult.data.plans,
    selectablePlansResult.data,
    subscription
  );
};

export const useEssentialPlansQuery = (options: UseQueryOptions<EssentialPlan[]> = {}) => {
  const { subscription } = useGetSubscriptionByParam();

  return useSmQuery({
    permissionKey: 'plan_get_plans',
    queryKey: getEssentialPlansQueryKey(subscription?.id?.toString()),
    queryFn: () => getEssentialPlansAndUpdateSelectable(subscription),
    refetchOnWindowFocus: false,
    ...options
  });
};
