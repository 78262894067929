// eslint-disable-next-line import/no-extraneous-dependencies
import { createHashHistory } from 'history';
import ReactGA from 'react-ga';

const history = createHashHistory();

if (process.env.NODE_ENV !== 'test') {
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
}

export default history;
