import { TemplateBlock } from '@redislabsdev/redislabs-ui-components';
import { useRef } from 'react';
import { NewTabIcon } from '@redislabsdev/redis-ui-icons';
import * as S from './ConnectionWizardCli.style';
import * as CWS from '../../ConnectionWizard.style';
import usePrefixedTranslation from '../../../../hooks/usePrefixedTranslation';
import { REDIS_CLI_LINK } from '../../../../utils/constants/docLinks';
import { EndpointSelection } from '../EndpointSelection/EndpointSelection';
import { CopySnippet } from '../CopySnippet/CopySnippet';
import RegionSelect from '../../../RegionSelect/RegionSelect';
import { useConnectionWizardData } from '../../hooks/useConnectionWizardData';
import {
  detectDisplayableReplaceMessage,
  getTemplateBlockForCodeSnippets,
  TEMPLATE_BLOCK_STATIC
} from '../utils';

interface ConnectionWizardCliProps {
  connectionWizardCliCommand: ConnectionWizardCliCommand;
}

export function ConnectionWizardCli({ connectionWizardCliCommand }: ConnectionWizardCliProps) {
  const sectionName = 'cli';
  const { prefixedT } = usePrefixedTranslation('databasesTable.connectionWizard');
  const {
    availableRegions,
    bdb,
    crdb,
    selectedRegion,
    endpoint,
    handleRegionSelect,
    handleOnEndpointChange,
    radioButtonChecked
  } = useConnectionWizardData(sectionName);

  const ref = useRef();

  if (!bdb) {
    return null;
  }

  let template = bdb.oss_cluster
    ? connectionWizardCliCommand.cli_command_oss_template
    : connectionWizardCliCommand.cli_command_template;

  if (bdb.access_control.enforce_client_authentication) {
    template = bdb.oss_cluster
      ? connectionWizardCliCommand.cli_command_oss_tls_with_client_auth_template
      : connectionWizardCliCommand.cli_command_tls_with_client_auth_template;
  } else if (bdb.access_control.has_ssl_auth) {
    template = bdb.oss_cluster
      ? connectionWizardCliCommand.cli_command_oss_tls_template
      : connectionWizardCliCommand.cli_command_tls_template;
  }

  return (
    <S.Wrapper>
      {crdb && (
        <CWS.RegionSelectDropdownContainer>
          <span>{prefixedT('selectProducerRegion')}</span>
          <RegionSelect
            withTitle={false}
            onRegionSelect={handleRegionSelect}
            selectedRegion={selectedRegion}
            allRegions={availableRegions}
          />
        </CWS.RegionSelectDropdownContainer>
      )}
      {bdb.dns_master_private ? (
        <EndpointSelection
          section={sectionName}
          handleOnEndpointChange={handleOnEndpointChange}
          radioButtonChecked={radioButtonChecked}
        />
      ) : null}
      <span>{prefixedT('copyCliSnippet')}</span>
      <CWS.CodeSnippetBox>
        <CWS.CodeSnippetBoxContent data-testid="connectionWizardCli" ref={ref}>
          <TemplateBlock templates={getTemplateBlockForCodeSnippets(endpoint)} text={template} />
        </CWS.CodeSnippetBoxContent>
      </CWS.CodeSnippetBox>
      <CWS.CopyWrapper>
        <span>
          <TemplateBlock
            templates={TEMPLATE_BLOCK_STATIC}
            text={prefixedT(
              detectDisplayableReplaceMessage(
                template,
                bdb.access_control.enforce_client_authentication || bdb.access_control.has_ssl_auth
              )
            )}
          />
        </span>
        <CopySnippet copyReference={ref} />
      </CWS.CopyWrapper>
      <S.Separator />
      <S.DownloadAction>
        <a
          data-testid="link--download-redis-cli"
          href={REDIS_CLI_LINK}
          target="_blank"
          rel="noreferrer"
        >
          <NewTabIcon size="M" />
          <span>{prefixedT('downloadRedisCli')}</span>
        </a>
      </S.DownloadAction>
    </S.Wrapper>
  );
}
