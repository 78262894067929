import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { getDataTestId } from '../../../utils';
import Card from '../Card/Card';
import * as S from './Collapse.style';
import CollapseArrow from './components/CollapseArrow/CollapseArrow';
import CollapseBody from './components/CollpaseBody/CollapseBody';
import CollapseCompose from './components/CollapseCompose/CollapseCompose';
import CollapseHeader from './components/CollpaseHeader/CollapseHeader';
import CollapseTitle from './components/CollapseTitle/CollapseTitle';

export interface CollapseProps {
  title: string | React.ReactNode;
  open: boolean;
  onToggled: (isOpen: boolean) => void;
  children: React.ReactNode;
  testId?: string;
  headerIcon?: IconNamesEnum;
  className?: string;
}

const Collapse = ({
  children,
  title,
  open,
  onToggled,
  testId,
  className,
  headerIcon
}: CollapseProps) => {
  return (
    <CollapseCompose
      {...getDataTestId('collapse', testId)}
      open={open}
      aria-expanded={open}
      className={className}
    >
      <CollapseHeader
        role="button"
        onToggled={() => onToggled(!open)}
        {...getDataTestId('summary', testId)}
      >
        <Card.Title>
          {headerIcon && <S.HeaderIcon name={headerIcon} width={20} height={20} />}
          {title}
        </Card.Title>

        <CollapseArrow />
      </CollapseHeader>
      <Card.Body>{children}</Card.Body>
    </CollapseCompose>
  );
};

Collapse.Container = CollapseCompose;
Collapse.CollapseHeader = CollapseHeader;
Collapse.CollapseTitle = CollapseTitle;
Collapse.CollapseArrow = CollapseArrow;
Collapse.CollapseBody = CollapseBody;

export default Collapse;
