import { ReactNode, FC } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const ModalParagraph: FC<Props> = ({ children, ...props }) => (
  <p data-role="dialog-intro" {...props}>
    {children}
  </p>
);

export default ModalParagraph;
