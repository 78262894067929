import { ROOTS } from '../../../utils/constants/routes';
import { PermissionKey } from '../protectedComponents.types';
import { protectedRoutesPermissions } from './permissions.constants';

export const getPermissionsFromPath = (path: string | string[]) => {
  let requiredRoutePermissions: PermissionKey;
  let isRoot = false;
  const pathArr = Array.isArray(path) ? path : [path];
  
  pathArr.forEach((p) => {
    if (!requiredRoutePermissions) {
      requiredRoutePermissions = protectedRoutesPermissions[p];
      isRoot = ROOTS.includes(p);
    }
  }); 

  return { requiredRoutePermissions, isRoot };
};
