import OffboardingFeedback from './OffboardingFeedback';
import { useDeleteSubscriptionStore } from './store/useDeleteSubscriptionStore';

const OffboardingFeedbackWrapper = () => {
  const deleteSubscriptionStore = useDeleteSubscriptionStore();

  if (deleteSubscriptionStore.deletedSubscriptionId !== -1) {
    return <OffboardingFeedback />;
  }

  return null;
};

export default OffboardingFeedbackWrapper;
