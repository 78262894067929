import { FC, useState } from 'react';
import { IconNamesEnum, Loader } from '@redislabsdev/redislabs-ui-components';
import * as S from '../LoginRelatedComponents.style';
import FreeDBLeftEllipse from '../../../assets/icons/FreeDBLeftEllipse.svg';
import FreeDBRightEllipse from '../../../assets/icons/FreeDBRightEllipse.svg';
import usePrefixedTranslation from '../../../hooks/usePrefixedTranslation';
import useAccount from '../../../hooks/useAccount';
import useRegions from '../../../hooks/useRegions';
import { useEssentialPlansQuery } from '../../../hooks/useEssentialPlansQuery/useEssentialPlansQuery';
import FreeDBCreateAnimation from '../FreeDBLoaderAnimation';
import FreeDbForm from './components/FreeDbForm';
import { parametersQueries } from '../../../queryClient/parameters/parameters.queries';

const FreeDb: FC = () => {
  const { prefixedT } = usePrefixedTranslation('LoginScreen.welcomeScreen.freeDB');
  const { account } = useAccount();
  const regionsQuery = useRegions();
  const { systemParams } = parametersQueries.useSystemParameters();
  const isRedisStackEnabled = systemParams.is_redis_stack_supported;
  const [showLoader, setShowLoader] = useState(false);

  const plansQuery = useEssentialPlansQuery();

  return (
    <S.FreeDBContainer data-testid="free-db-container">
      <S.FreeDBWrapper>
        {showLoader ? (
          <FreeDBCreateAnimation />
        ) : (
          <>
            <S.LeftSide>
              <S.LeftEllipse>
                <img src={FreeDBLeftEllipse} alt="" />
              </S.LeftEllipse>
              <S.RightEllipse>
                <img src={FreeDBRightEllipse} alt="" />
              </S.RightEllipse>
              <S.WelcomeText>
                {prefixedT('title')}
                {account?.name && `, ${account.name}`}
              </S.WelcomeText>
              <S.SubTitle data-testid="free-db-subtitle">
                {prefixedT('subtitle1')}
                <S.BoldSpan>{` ${prefixedT('free')} `}</S.BoldSpan>
                {prefixedT('subtitle2')}
              </S.SubTitle>
              <S.Row>
                <S.BlueIcon>
                  <S.Icon name={IconNamesEnum.USAGE} />
                </S.BlueIcon>
                {prefixedT('row1')}
              </S.Row>
              <S.Row>
                <S.BlueIcon>
                  <S.Icon name={IconNamesEnum.DATABASE} />
                </S.BlueIcon>
                {prefixedT('row2')}
              </S.Row>
              <S.Row>
                <S.BlueIcon>
                  <S.Icon name={IconNamesEnum.NODES} />
                </S.BlueIcon>
                {prefixedT('row3')}
              </S.Row>
              <S.Row>
                <S.Icon name={IconNamesEnum.ROCKET} />
                <div>{prefixedT('row4')}</div>
              </S.Row>
              {isRedisStackEnabled ? (
                <S.Row>
                  <S.Icon name={IconNamesEnum.REDIS_STACK} padding="0.076rem" />
                  <div>
                    <div>{prefixedT('row5redisStack')}</div>
                    <S.SmallDiv>{prefixedT('row5redisStackSubtitle')}</S.SmallDiv>
                  </div>
                </S.Row>
              ) : (
                <S.Row>
                  <S.Icon name={IconNamesEnum.MODULES} />
                  {prefixedT('row5')}
                </S.Row>
              )}
            </S.LeftSide>
            <S.RightSide>
              {regionsQuery.isInitialLoading || plansQuery.isInitialLoading ? (
                <Loader />
              ) : (
                <FreeDbForm setShowLoader={setShowLoader} />
              )}
            </S.RightSide>
          </>
        )}
      </S.FreeDBWrapper>
    </S.FreeDBContainer>
  );
};

export default FreeDb;
