import styled from 'styled-components/macro';
import { SvgIcon } from '@redislabsdev/redislabs-ui-components';
import { IFontSize } from '../../types/styled';

export const Container = styled.div<{ marginTop: string }>`
  margin-top: ${({ marginTop }) => marginTop};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`;

export const Label = styled.p<{ fontSize: IFontSize; bold: boolean }>`
  margin-bottom: 2.4rem;
  white-space: pre-wrap;
  color: ${({ theme: { colors } }) => colors.blue2};
  font-size: ${({ fontSize, theme: { fontSizes } }) => fontSizes[fontSize]};
  font-family: ${({ bold, theme }) => (bold ? theme.fontTypes.semiBold : theme.fontTypes.regular)};
`;

export const PlusIcon = styled(SvgIcon)`
  margin-right: 0.2rem;
  height: 100%;
`;

export const StoryWrapper = styled.div`
  padding: 5rem;
`;
