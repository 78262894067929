import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { usePrefixedTranslation } from '../../../hooks';
import { showNotification } from '../../../components/Notification/Notification';
import OffboardingFeedbackDialog from './components/OffboardingFeedbackDialog/OffboardingFeedbackDialog';
import { offboardingFeedbackQueries } from '../../../queryClient/offboardingFeedback/offboardingFeedback.queries';
import { offboardingFeedbackMutations } from '../../../queryClient/offboardingFeedback/offboardingFeedback.mutations';
import { useOffboardingExperienceEnabled } from '../../../hooks/useOffboardingExperienceEnabled';
import { extractErrorAndShowToast } from '../../../utils/helpers/extractErrorAndShowToast';
import { useDeleteSubscriptionStore } from './store/useDeleteSubscriptionStore';

const OffboardingFeedback = (): JSX.Element => {
  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [selectedReasonId, setSelectedReasonId] = useState('');
  const [comments, setComments] = useState('');

  const deleteSubscriptionStore = useDeleteSubscriptionStore();
  const isLastSubscriptionDeleted = deleteSubscriptionStore.isLastPaindSubscriptionDeleted;

  const isOffboardingExperienceEnabled = useOffboardingExperienceEnabled();
  const { prefixedT } = usePrefixedTranslation('offboardingFeedback');

  const { data: queryUseReasonsData = [], isInitialLoading: queryUseReasonsIsLoading } =
    offboardingFeedbackQueries.useReasons({
      enabled: isQueryEnabled,
      onError: (error: AxiosError) => {
        setIsDialogVisible(false);
        extractErrorAndShowToast(error);
      }
    });
  const mutationUseFeedback = offboardingFeedbackMutations.useFeedback();

  const reasons = queryUseReasonsData.map((reason) => {
    return { id: reason, label: prefixedT(`reasonsMap.${reason}`) };
  });

  useEffect(() => {
    const DELAY_BEFORE_DIALOG_SHOW = 1500;
    let timeout = null;

    if (isLastSubscriptionDeleted && isOffboardingExperienceEnabled) {
      setIsQueryEnabled(true);

      timeout = setTimeout(async () => {
        setSelectedReasonId('');
        setComments('');

        setIsDialogVisible(true);
      }, DELAY_BEFORE_DIALOG_SHOW);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isLastSubscriptionDeleted, isOffboardingExperienceEnabled]);

  const onClickButtonNotNow = () => {
    deleteSubscriptionStore.reset();
    setIsDialogVisible(false);
  };

  const onClickButtonSendFeedback = () => {
    deleteSubscriptionStore.reset();
    setIsDialogVisible(false);
    showNotification({ body: prefixedT('thankYou') }, 'success');

    const selectedReason = reasons.find((item) => item.id === selectedReasonId);

    mutationUseFeedback.mutate({
      selectedReasonId,
      selectedReasonText: selectedReason.label,
      comments
    });
  };

  const onChangeSelection = (selectedFeedbackIdParam: string) => {
    setSelectedReasonId(selectedFeedbackIdParam);
  };

  const onChangeComments = (commentsParam: string) => {
    setComments(commentsParam);
  };

  if (!isDialogVisible) {
    return null;
  }

  return (
    <OffboardingFeedbackDialog
      title={prefixedT('title')}
      subTitle={prefixedT('subTitle')}
      cancelButtonLabel={prefixedT('notNow')}
      submitButtonLabel={prefixedT('sendFeedback')}
      commentsTitle={prefixedT('commentsTitle')}
      commentsPlaceHolder={prefixedT('commentsPlaceHolder')}
      reasons={reasons}
      selectedReasonId={selectedReasonId}
      comments={comments}
      onClickButtonNotNow={onClickButtonNotNow}
      onClickButtonSendFeedback={onClickButtonSendFeedback}
      onChangeSelection={onChangeSelection}
      onChangeComments={onChangeComments}
      isLoadingVisible={queryUseReasonsIsLoading}
      isButtonSendFeedbackEnabled={Boolean(selectedReasonId)}
    />
  );
};

export default OffboardingFeedback;
