import { useState } from 'react';
import { Loader } from '@redislabsdev/redislabs-ui-components';
import { OffboardingFeedbackDialogProps } from './OffboardingFeedbackDialog.types';
import * as S from './OffboardingFeedbackDialog.style';
import { RadioButtonProps } from '../../../../../components/RadioButtonGroup/components/RadioButton/RadioButton.types';

const OffboardingFeedbackDialog = ({
  title,
  subTitle,
  cancelButtonLabel,
  submitButtonLabel,
  commentsTitle,
  commentsPlaceHolder,
  reasons,
  selectedReasonId,
  comments,
  onClickButtonNotNow,
  onClickButtonSendFeedback,
  onChangeSelection,
  onChangeComments,
  isLoadingVisible,
  isButtonSendFeedbackEnabled
}: OffboardingFeedbackDialogProps): JSX.Element => {
  const [selectedReasonIdState, setSelectedReasonIdState] = useState(selectedReasonId);
  const [commentsState, setCommentsState] = useState(comments);

  const onChangeSelectedReason = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reasonId = e.target.id;

    setSelectedReasonIdState(reasonId);
    onChangeSelection(reasonId);
  };

  const onChangeCommentsText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;

    setCommentsState(text);
    onChangeComments(text);
  };

  const radioButtons: RadioButtonProps<string>[] = reasons.map((reason) => ({
    id: reason.id,
    label: reason.label,
    onChange: onChangeSelectedReason
  }));

  return (
    <S.ConfirmationDialog
      title={title}
      subTitle={subTitle}
      cancelButtonLabel={cancelButtonLabel}
      submitButtonLabel={submitButtonLabel}
      handleModalCancel={onClickButtonNotNow}
      handleModalSubmit={onClickButtonSendFeedback}
      modalOverlayStyle={S.modalOverlayStyle}
      modalContentStyle={S.modalContentStyle}
      isOpen
      submitDisabled={!isButtonSendFeedbackEnabled}
    >
      <S.ContentContaier>
        {isLoadingVisible && (
          <S.LoaderContaier data-testid="offboarding-feedback-loader">
            <Loader />
          </S.LoaderContaier>
        )}

        {reasons.length > 0 && (
          <S.FeedbacksContaier>
            <S.RadioButtonGroup
              radioButtons={radioButtons}
              checkedRadioBtnId={selectedReasonIdState}
              name="offboarding-feedback-reasons"
            />

            <S.CommentsContainer>
              <div>{commentsTitle}</div>
              <div>
                <S.TextArea
                  name="comments"
                  dataTestIdSuffix="offboarding-feedback-comments"
                  placeholder={commentsPlaceHolder}
                  maxLength={256}
                  onChange={onChangeCommentsText}
                  value={commentsState}
                />
              </div>
            </S.CommentsContainer>
          </S.FeedbacksContaier>
        )}
      </S.ContentContaier>
    </S.ConfirmationDialog>
  );
};

export default OffboardingFeedbackDialog;
