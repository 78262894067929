import * as EnvBannerIcons from '../Icons';

export const IconsMap = {
  AWS_MP: EnvBannerIcons.AwsMarketplace,
  GCP_MP: EnvBannerIcons.GoogleCloudMarketplace,
  HEROKU_LOGO_PURPLE: EnvBannerIcons.HerokuLogoPurple
};

export const IconTextColorMap = {
  AWS_MP: 'black',
  GCP_MP: 'black',
  HEROKU_LOGO_PURPLE: 'purple2'
};

export const HEROKU_ADD_ON_LINK = 'https://elements.heroku.com/addons/rediscloud';
export const AWS_MP_LINK = 'https://aws.amazon.com/marketplace';
export const GCP_MP_LINK = 'https://cloud.google.com/marketplace';
