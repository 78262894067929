import styled from 'styled-components/macro';
import { PriceValueProps } from './PriceValue.types';

export const PriceValueContainer = styled.span`
  display: flex;
  align-items: baseline;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.darkBlue1};
  line-height: unset;
  font-weight: 600;
`;

const priceFontSizeMapper: Record<PriceValueProps['size'], string> = {
  large: '2.8rem',
  medium: '1.8rem',
  small: '1.4rem;'
};

export const Price = styled.span<{ size: PriceValueProps['size'] }>`
  font-size: ${({ size }) => priceFontSizeMapper[size]};
  font-weight: bold;
`;
