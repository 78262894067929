import { theme, IconNamesEnum } from '@redislabsdev/redislabs-ui-components/styles';
import { TypeOptions } from 'react-toastify';

export const notificationVariantColors: Record<TypeOptions, string> = {
  warning: theme.colors.yellow2,
  success: theme.colors.green1,
  info: theme.colors.purple1,
  error: theme.colors.red1,
  default: theme.colors.green1,
  dark: theme.colors.green1
};

export const variantIcons: Record<TypeOptions, IconNamesEnum> = {
  warning: IconNamesEnum.ATTENTION2,
  success: IconNamesEnum.VALIDATED,
  info: IconNamesEnum.ATTENTION2,
  error: IconNamesEnum.ATTENTION2,
  default: IconNamesEnum.ATTENTION2,
  dark: IconNamesEnum.ATTENTION2
};

export const NOTIFICATION_CONTAINER_ID = 'notification-new';

export const toastDataTestId: Record<TypeOptions, string> = {
  warning: 'toastify__toast--warning',
  success: 'toastify__toast--success',
  info: 'toastify__toast--info',
  error: 'toastify__toast--error',
  default: 'toastify__toast--default',
  dark: 'toastify__toast--dark'
};
