import { HEROKU_ADD_ON_LINK, GCP_MP_LINK, AWS_MP_LINK } from './EnvBanner.constants';
import { EnvBannerProps } from './EnvBanner.types';

export const getEnvBannerProps = (
  isSuperOrSupportAgent = false,
  isHerokuAccount,
  isGcpAccount,
  isAWSAccount
): EnvBannerProps | null => {
  if (isHerokuAccount) {
    return {
      iconName: 'HEROKU_LOGO_PURPLE',
      text: 'herokuAddOn',
      href: HEROKU_ADD_ON_LINK,
      isSuperOrSupportAgent
    };
  }
  if (isGcpAccount) {
    return {
      iconName: 'GCP_MP',
      text: 'GCPMarketplace',
      href: GCP_MP_LINK,
      isSuperOrSupportAgent
    };
  }
  if (isAWSAccount) {
    return {
      iconName: 'AWS_MP',
      text: 'AWSMarketplace',
      href: AWS_MP_LINK,
      isSuperOrSupportAgent
    };
  }

  return null;
};
