import styled from 'styled-components/macro';

export const PADDING = 1.6;
const LIST_ITEM_PADDING = 3.2;

export const Header = styled.div`
  padding: ${PADDING}rem;
`;

export const Body = styled.div`
  padding: ${PADDING}rem;
`;

export const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.8rem;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.05);

  ${Header} ~ ${Body} {
    border-top: 1px solid ${({ theme }) => theme.colors.lightGray2};
  }
`;

export const ListGroupItem = styled.div`
  :not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray2};
  }

  padding: ${LIST_ITEM_PADDING}rem ${PADDING}rem;

  :first-of-type {
    padding: ${LIST_ITEM_PADDING - PADDING}rem ${PADDING}rem ${LIST_ITEM_PADDING}rem ${PADDING}rem;
  }

  :last-of-type {
    padding: ${LIST_ITEM_PADDING}rem ${PADDING}rem ${LIST_ITEM_PADDING - PADDING}rem ${PADDING}rem;
  }

  :only-child {
    padding: ${LIST_ITEM_PADDING - PADDING}rem ${PADDING}rem ${LIST_ITEM_PADDING - PADDING}rem
      ${PADDING}rem;
  }
`;

export const ListGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-size: 1.8rem;
  font-family: ${({ theme: { fontTypes } }) => fontTypes.semiBold};
  color: ${({ theme }) => theme.colors.darkBlue1};
`;
