import { TemplateBlock } from '@redislabsdev/redislabs-ui-components';
import { NewTabIcon } from '@redislabsdev/redis-ui-icons';
import { useRef, useState } from 'react';
import * as S from './ConnectionWizardClient.style';
import * as CWS from '../../ConnectionWizard.style';
import usePrefixedTranslation from '../../../../hooks/usePrefixedTranslation';
import { EndpointSelection } from '../EndpointSelection/EndpointSelection';
import { CopySnippet } from '../CopySnippet/CopySnippet';
import RegionSelect from '../../../RegionSelect/RegionSelect';
import { useConnectionWizardData } from '../../hooks/useConnectionWizardData';
import {
  detectDisplayableReplaceMessage,
  getTemplateBlockForCodeSnippets,
  TEMPLATE_BLOCK_STATIC
} from '../utils';

interface ConnectionWizardClientProps {
  clients: ConnectionWizardClientTemplate[];
}

export function ConnectionWizardClient({ clients }: ConnectionWizardClientProps) {
  const sectionName = 'client';
  const { prefixedT } = usePrefixedTranslation('databasesTable.connectionWizard');
  const {
    availableRegions,
    bdb,
    crdb,
    selectedRegion,
    endpoint,
    handleRegionSelect,
    handleOnEndpointChange,
    radioButtonChecked
  } = useConnectionWizardData(sectionName);

  const [selectedClient, setSelectedClient] = useState(clients[0]);
  const ref = useRef();

  if (!bdb) {
    return null;
  }

  let template = bdb.oss_cluster ? selectedClient.code_snippet_oss : selectedClient.code_snippet;

  if (bdb.access_control.enforce_client_authentication) {
    template = bdb.oss_cluster
      ? selectedClient.code_snippet_oss_tls_with_client_auth
      : selectedClient.code_snippet_tls_with_client_auth;
  } else if (bdb.access_control.has_ssl_auth) {
    template = bdb.oss_cluster
      ? selectedClient.code_snippet_oss_tls
      : selectedClient.code_snippet_tls;
  }

  return (
    <S.Wrapper>
      {crdb && (
        <CWS.RegionSelectDropdownContainer>
          <span>{prefixedT('selectProducerRegion')}</span>
          <RegionSelect
            withTitle={false}
            onRegionSelect={handleRegionSelect}
            selectedRegion={selectedRegion}
            allRegions={availableRegions}
          />
        </CWS.RegionSelectDropdownContainer>
      )}
      {bdb.dns_master_private ? (
        <EndpointSelection
          section={sectionName}
          handleOnEndpointChange={handleOnEndpointChange}
          radioButtonChecked={radioButtonChecked}
        />
      ) : null}
      <span>{prefixedT('selectClient')}</span>
      <S.DropdownMenu
        items={clients}
        selectedItem={selectedClient}
        dataTestIdSuffix="client-selection"
        renderItem={(item: ConnectionWizardClientTemplate) => item.client_name}
        onSelectedItemChange={({ selectedItem }) => {
          setSelectedClient(selectedItem as ConnectionWizardClientTemplate);
        }}
      />
      <span>{prefixedT('copySnippet')}</span>
      <CWS.CodeSnippetBox>
        <CWS.CodeSnippetBoxContent data-testid="connectionWizardClient" ref={ref}>
          <TemplateBlock templates={getTemplateBlockForCodeSnippets(endpoint)} text={template} />
        </CWS.CodeSnippetBoxContent>
      </CWS.CodeSnippetBox>
      <CWS.CopyWrapper>
        <span>
          <TemplateBlock
            templates={TEMPLATE_BLOCK_STATIC}
            text={prefixedT(
              detectDisplayableReplaceMessage(
                template,
                bdb.access_control.enforce_client_authentication || bdb.access_control.has_ssl_auth
              )
            )}
          />
        </span>
        <CopySnippet copyReference={ref} />
      </CWS.CopyWrapper>

      <S.Separator />

      <S.DownloadAction>
        <a
          data-testid="link--download-redis-client"
          href={selectedClient.client_url}
          target="_blank"
          rel="noreferrer"
        >
          <NewTabIcon size="M" />
          <span>{prefixedT('downloadRedisClient')}</span>
        </a>
      </S.DownloadAction>
    </S.Wrapper>
  );
}
