import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './mfa.actions';
import { login } from '../auth/auth.action';
import * as calls from './mfa.calls';

import {
  MfaActionTypesEnum,
  MfaDisableAction,
  MfaSmsSetupAction,
  MfaSmsVerifyAction,
  MfaTotpVerifyAction
} from './mfa.types';
import { extractErrorAndShowToast } from '../../utils/helpers/extractErrorAndShowToast';

function* mfaEnable() {
  try {
    yield call(calls.enableMfa);
  } catch (e) {
    const { data } = e.response;
    yield put(actions.mfaEnableFailure(data));
  }
}

function* mfaDisable({ payload }: MfaDisableAction) {
  try {
    yield call(calls.disableMfa);
    yield call(mfaGetInfo);
    yield call(() => payload.successCb());
  } catch (e) {
    const { data } = e.response;
    yield call(() => payload.errorCb());
    yield put(actions.mfaDisableFailure(data));
  }
}

function* mfaGetInfo() {
  try {
    const { data } = yield call(calls.mfaInfo);
    yield put(actions.mfaGetInfoSuccess(data));
  } catch (e) {
    const { data } = e.response;
    yield put(actions.mfaGetInfoFailure(data));
  }
}

function* mfaSmsSetup({ payload }: MfaSmsSetupAction) {
  try {
    yield call(mfaEnable);
    yield call(calls.mfaSmsSetup, payload);
  } catch (e) {
    extractErrorAndShowToast(e);
  }
}

function* mfaSmsVerify({ payload }: MfaSmsVerifyAction) {
  try {
    yield put(actions.mfaSetError());
    yield call(calls.mfaSmsVerify, payload.verificationCode);
    yield put(actions.mfaSmsVerifySuccess(payload.phoneNumber));
    yield put(login({ referer: '', callCsrfFlag: true }));
    yield call(() => payload.cb());
  } catch (e) {
    yield payload.errorCb(e.response?.errorInfo || { message: e.message });
  }
}

function* mfaTotpVerify({ payload }: MfaTotpVerifyAction) {
  try {
    yield put(actions.mfaSetError());
    yield call(calls.mfaTotpVerify, payload.verificationCode);
    yield put(actions.mfaTotpVerifySuccess());
  } catch (e) {
    yield payload.errorCb(e.response?.errorInfo || { message: e.message });
  }
}

function* mfaTotpDisable() {
  try {
    yield call(calls.mfaTotpDisable);
    yield put(actions.mfaTotpDisableSuccess());
  } catch (e) {
    extractErrorAndShowToast(e);
  }
}

function* mfaSaga() {
  yield takeLatest(MfaActionTypesEnum.MFA_INFO_REQUEST, mfaGetInfo);
  yield takeLatest(MfaActionTypesEnum.MFA_ENABLE_REQUEST, mfaEnable);
  yield takeLatest(MfaActionTypesEnum.MFA_DISABLE_REQUEST, mfaDisable);
  yield takeLatest(MfaActionTypesEnum.MFA_SMS_SETUP_REQUEST, mfaSmsSetup);
  yield takeLatest(MfaActionTypesEnum.MFA_SMS_VERIFY_REQUEST, mfaSmsVerify);
  yield takeLatest(MfaActionTypesEnum.MFA_TOTP_VERIFY_REQUEST, mfaTotpVerify);
  yield takeLatest(MfaActionTypesEnum.MFA_TOTP_DISABLE_REQUEST, mfaTotpDisable);
}

export default mfaSaga;
