import styled, { css } from 'styled-components/macro';
import {
  DropdownMenu as DropdownMenuComponent,
  DropdownMenuStyles
} from '@redislabsdev/redislabs-ui-components/ui/components/DropdownMenu';
import ReadMore from '../../../../../../components/ReadMore/ReadMore';
import { RegionValue } from '../../../../../../components/RegionDisplayField/RegionDisplayField.style';
import { RegionDisplayField } from './components/RenderRegionItem/RenderRegionItem.style';

export const Title = styled.h3`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.blue2};
`;

export const RegionSelectContainer = styled.div`
  z-index: 3;
`;

export const DropdownMenu = styled(DropdownMenuComponent)<{ isRequestMoreRegionsVisible: boolean }>`
  width: 100%;

  ${({ isRequestMoreRegionsVisible }) =>
    isRequestMoreRegionsVisible &&
    css`
      ${DropdownMenuStyles.ListItem}:last-of-type {
        padding: 0;
      }
      ${DropdownMenuStyles.ListContainer} {
        padding-bottom: 0;
      }
    `}

  button {
    ${RegionDisplayField} {
      ${RegionValue} {
        color: initial;
      }
    }
  }
`;

export const MoreRegionsPageLink = styled(ReadMore)`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray2};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;
