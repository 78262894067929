import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from '../../../utils/constants/routes';
import PasswordResetExpired from '../../../components/LoginRelatedComponents/PasswordResetExpired';
import * as S from '../Login.style';
import { activateInvitationCallbackRequest } from './utils/resetPassword';
import { SuccessCallbackProps } from './ResetPassword.types';

const ActivateInvitationCallback = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [showPasswordExpired, setShowPasswordExpired] = useState(false);

  const successCallback = ({ key, accId }: SuccessCallbackProps) => {
    if (!key && !accId) {
      history.push(routes.login);
    } else {
      history.push(`${routes.resetPassword}?invitation=true&key=${key}&id=${accId}`);
    }
  };
  const errorCallback = () => {
    setShowPasswordExpired(true);
  };

  useEffect(() => {
    activateInvitationCallbackRequest({ search, successCallback, errorCallback });
  }, []); // eslint-disable-line

  if (showPasswordExpired) {
    return <PasswordResetExpired showActivation />;
  }

  return <S.Loader dataTestId="invitation-loader" />;
};

export default ActivateInvitationCallback;
