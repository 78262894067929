import { ProActions, ProActionTypes, ProPlanState, EstimatedPriceState } from './pro.types';

const initialState = {
  plan: { status: 'idle', data: null, error: '' } as ProPlanState,
  rcpId: null as number | null,
  postProSubscriptionState: { status: 'idle', error: '' } as BaseState,
  estimatedPriceState: {
    status: 'idle',
    data: { shardTypePricingList: [], price: 0 },
    error: ''
  } as EstimatedPriceState
};

const proReducer = (state = initialState, action: ProActions): typeof initialState => {
  switch (action.type) {
    case ProActionTypes.POST_PRO_PLAN_REQUEST:
      return {
        ...state,
        plan: {
          ...state.plan,
          error: '',
          status: 'pending'
        }
      };

    case ProActionTypes.POST_PRO_PLAN_SUCCESS:
      return {
        ...state,
        plan: {
          ...state.plan,
          status: 'resolved',
          data: action.payload.proPlan
        },
        rcpId: action.payload.rcpId
      };

    case ProActionTypes.POST_PRO_PLAN_FAILURE:
      return {
        ...state,
        plan: {
          ...state.plan,
          data: null,
          status: 'rejected'
        },
        rcpId: null
      };

    case ProActionTypes.POST_PRO_SUBSCRIPTION_REQUEST:
    case ProActionTypes.CREATE_AA_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        postProSubscriptionState: {
          error: '',
          status: 'pending'
        }
      };

    case ProActionTypes.POST_PRO_SUBSCRIPTION_SUCCESS:
    case ProActionTypes.CREATE_AA_SUBSCRIPTION_SUCCESS:
      return initialState;

    case ProActionTypes.POST_PRO_SUBSCRIPTION_FAILURE:
    case ProActionTypes.CREATE_AA_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        postProSubscriptionState: {
          error: '',
          status: 'rejected'
        }
      };

    case ProActionTypes.CALCULATE_PRICE_REQUEST:
      return {
        ...state,
        estimatedPriceState: {
          ...state.estimatedPriceState,
          status: 'pending',
          error: ''
        }
      };

    case ProActionTypes.CALCULATE_PRICE_SUCCESS: {
      const { price, shardTypePricingList = [], comparedPrice, comparedSize } = action.payload;

      return {
        ...state,
        estimatedPriceState: {
          status: 'resolved',
          error: '',
          data: { price, shardTypePricingList, comparedPrice, comparedSize }
        }
      };
    }
    case ProActionTypes.CALCULATE_PRICE_FAILURE:
      return {
        ...state,
        estimatedPriceState: {
          data: initialState.estimatedPriceState.data,
          status: 'rejected',
          error: action.payload
        }
      };

    case ProActionTypes.RESET_PRO_STATE:
      return initialState;

    case ProActionTypes.CLEAR_PRO_PLAN:
      return {
        ...state,
        plan: initialState.plan,
        rcpId: null
      };
    default:
      return state;
  }
};

export default proReducer;
