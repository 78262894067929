import { RadioButtonRenderProps } from './RadioButton.types';
import * as S from './RadioButton.style';
import { getDataTestId } from '../../../../utils/dataAttributesHelpers';

const RadioButton = <T extends string>({
  id,
  label,
  disabled,
  onChange,
  testIdSuffix,
  isChecked,
  ...rest
}: RadioButtonRenderProps<T>) => {
  return (
    <S.InputControl label={label} id={id} disabled={disabled} {...rest}>
      <input
        type="radio"
        id={id}
        checked={isChecked}
        onChange={onChange}
        disabled={disabled}
        {...getDataTestId('radiobutton', testIdSuffix)}
      />
    </S.InputControl>
  );
};

export default RadioButton;
