import { useMemo } from 'react';
import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { useSelector, useDispatch } from 'react-redux';
import { usePrefixedTranslation } from '../../../../../../../../hooks';
import * as S from './SwitchAccount.style';
import useAccount from '../../../../../../../../hooks/useAccount';
import { accountsStateSelector } from '../../../../../../../../store/account/account.selectors';
import { switchAccount } from '../../../../../../../../store/account/account.actions';
import { moveCurrentAccountToTop } from './SwitchAccounts.utils';
import { getDataTestId } from '../../../../../../../../utils';

interface Props {
  closeMenu(): void;
}

const SwitchAccount = ({ closeMenu }: Props) => {
  const dispatch = useDispatch();
  const { prefixedT } = usePrefixedTranslation('navBar.userMenu');
  const { account, isLoading } = useAccount();
  const { data: accountsList } = useSelector(accountsStateSelector);
  const modifiedAccountsList = useMemo(
    () => moveCurrentAccountToTop(accountsList, account?.id),
    [account?.id, accountsList]
  );

  const handleAccountClick = (accountId: number) => {
    closeMenu();
    dispatch(switchAccount(accountId));
  };

  return (
    <S.Container>
      {!isLoading && (
        <>
          <S.Title data-testid="readonlylabel--account">{prefixedT('account')}</S.Title>
          <S.AccountsList>
            {modifiedAccountsList.map((acc) => {
              const isCurrentAccount = acc.id === account?.id;

              return (
                <S.AccountItem
                  data-role="navitem"
                  key={acc.id.toString()}
                  onClick={() => !isCurrentAccount && handleAccountClick(acc.id)}
                >
                  <S.AccountNameAndId
                    isCurrentAccount={isCurrentAccount}
                    {...getDataTestId('readonlyvalue', `account-row-${acc.id}`)}
                  >
                    <span {...getDataTestId('readonlyvalue', 'account-name')}>{acc.name}</span>
                    {` #`}
                    <span {...getDataTestId('readonlyvalue', 'account-id')}>{acc.id}</span>
                  </S.AccountNameAndId>
                  {isCurrentAccount && <S.CheckIcon name={IconNamesEnum.CHECK} />}
                </S.AccountItem>
              );
            })}
          </S.AccountsList>
        </>
      )}
    </S.Container>
  );
};

export default SwitchAccount;
