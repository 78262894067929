import parametersUtils from '../../../../../../../utils/parameters/parameters.utils';

interface GetIsRequestMoreRegionsVisibleProps {
  withMoreRegionsPageLink: boolean;
  systemParams: Partial<SystemParams>;
  currentAccountId: number;
}

const getIsRequestMoreRegionsVisible = ({
  withMoreRegionsPageLink,
  systemParams,
  currentAccountId
}: GetIsRequestMoreRegionsVisibleProps) => {
  if (
    !withMoreRegionsPageLink ||
    !systemParams?.external_feature_flag_support_additional_regions_enabled ||
    !systemParams?.more_regions_page_link
  ) {
    return false;
  }

  let isRequestMoreRegionsVisible = false;

  if (systemParams?.support_additional_regions_enabled_accounts === undefined) {
    isRequestMoreRegionsVisible = true;
  } else if (
    systemParams?.support_additional_regions_enabled_accounts === '' ||
    systemParams?.support_additional_regions_enabled_accounts === null
  ) {
    isRequestMoreRegionsVisible = false;
  } else if (
    parametersUtils
      .convertCommaSeparatedParamToArray(systemParams?.support_additional_regions_enabled_accounts)
      .includes(currentAccountId)
  ) {
    isRequestMoreRegionsVisible = true;
  }

  return isRequestMoreRegionsVisible;
};

export default getIsRequestMoreRegionsVisible;
