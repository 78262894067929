import { ConfirmationModalProps } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';
import * as S from './LoginSharedModal.style';

const LoginSharedModal = (props: ConfirmationModalProps) => (
  <S.LoginSharedModal {...props} modalOverlayStyle={S.modalOverlayStyle}>
    {props.children}
  </S.LoginSharedModal>
);

export default LoginSharedModal;
