import { Billing } from '../billingHistory.types';

export const extractBalanceFromBillings = (data: Billing[]) => {
  let balance = 0;
  const billings: Billing[] = [];
  data?.forEach((billing) => {
    if (billing.reference.toLocaleLowerCase() === 'balance') {
      balance = billing.amount;
    } else {
      billings.push({ ...billing, date: new Date(billing.date) });
    }
  });

  return { billingsHistory: billings, balance: parseFloat(balance.toFixed(2)) };
};
