import React from 'react';
import { DrawerProps } from '@redislabsdev/redislabs-ui-components/ui/components/Drawer/Drawer.types';
import * as S from './SMDrawer.style';

interface SMDrawerCompositionProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const SMDrawer = ({ children, ...props }: DrawerProps) => (
  <S.DrawerContainer
    isOpen
    placement="right"
    showMask
    maskStyle={{ backgroundColor: 'white' }}
    width={`${S.DRAWER_SIZE}rem`}
    data-testid="sm--drawer"
    {...props}
  >
    {children}
  </S.DrawerContainer>
);

const SMDrawerHeader = React.forwardRef<HTMLDivElement, SMDrawerCompositionProps>(
  ({ children, ...props }, ref) => (
    <S.Header {...props} ref={ref}>
      {children}
    </S.Header>
  )
);

const SMDrawerTitle = ({ children, ...props }: SMDrawerCompositionProps) => (
  <S.Title {...props}>{children}</S.Title>
);

const SMDrawerCaption = ({ children, ...props }: SMDrawerCompositionProps) => (
  <S.Caption {...props}>{children}</S.Caption>
);

const SMDrawerBody = React.forwardRef<HTMLDivElement, SMDrawerCompositionProps>(
  ({ children, ...props }, ref) => (
    <S.Body {...props} ref={ref}>
      {children}
    </S.Body>
  )
);

const SMDrawerFooter = React.forwardRef<HTMLDivElement, SMDrawerCompositionProps>(
  ({ children, ...props }, ref) => (
    <S.Footer {...props} ref={ref}>
      {children}
    </S.Footer>
  )
);

const SMDrawerDivider = () => <S.DrawerDivider />;

SMDrawer.Header = SMDrawerHeader;
SMDrawer.Title = SMDrawerTitle;
SMDrawer.Caption = SMDrawerCaption;
SMDrawer.Body = SMDrawerBody;
SMDrawer.Footer = SMDrawerFooter;
SMDrawer.Divider = SMDrawerDivider;

export default SMDrawer;
