import { CAPTCHA_FRAME_ID } from 'components/CaptchaFrame/CaptchaFrame';
import { Config } from '../../../../config';

const captchaHandler = async () => {
  const iframe = document.createElement('iframe');
  iframe.src = `${Config.API_URL}/perform-captcha`;
  iframe.style.width = '100%';
  iframe.style.height = '100%';
  const captchaFramePlaceholder = document.getElementById(CAPTCHA_FRAME_ID);
  if (captchaFramePlaceholder.firstChild) {
    captchaFramePlaceholder.removeChild(captchaFramePlaceholder.firstChild);
  }
  captchaFramePlaceholder.appendChild(iframe);
  let iframeLoadCounter = 0;
  await new Promise((resolve) => {
    iframe.addEventListener('load', async () => {
      iframeLoadCounter++;
      if (iframeLoadCounter === 2) {
        document.getElementById(CAPTCHA_FRAME_ID).removeChild(iframe);
        resolve(true);
      }
    });
  });
};

export default captchaHandler;
