import RedisApiAxiosInstance from '../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';

export const getClustersBySubId = async (subscriptionid: number) => {
  const { data } = await RedisApiAxiosInstance.get<{ cluster: BdbCluster }>('/clusters', {
    params: { subscriptionid }
  });

  return data.cluster;
};

export const getModulesByClusterId = async (clusterId: number) => {
  const { data } = await RedisApiAxiosInstance.get<{ modules: Module[] }>('/modules', {
    params: { cluster_id: clusterId }
  });

  return data.modules;
};
