import { ShardTypePricingRegion } from '../../../../../../store/databases/databases.types';

export const defaultValues = {
  access_control: {
    default_user: true,
    enforce_client_authentication: true,
    has_redis_password: true,
    has_sasl_auth: false,
    has_source_ips: false,
    has_ssl_auth: false,
    id: 50981366,
    public_key: null,
    redis_password: '8kq6GqQrwITECB5FTVCFc7DOhFqbDEuA',
    sasl_user_name: null,
    sasl_user_password: null,
    source_ips: [{ id: 8274743, addr: '0.0.0.0/0' }],
    thumbprint: null
  },
  activate_date: null,
  average_value_size: null,
  backup: false,
  backup_failure_reason: null,
  backup_progress: null,
  backup_status: null,
  bdb_alerts: [],
  bdb_modules: [],
  bdb_type: 'redis',
  cluster: {
    absolute_bdb_max_size: 51,
    errors: [],
    id: 25899,
    is_V4: false,
    is_acl_supported: false,
    is_rof: false,
    max_global_memcached_size: 1000000,
    max_global_redis_size: 1000000,
    max_memcached_size: 50,
    max_redis_size: 20,
    max_throughput: 50,
    support_rcp_regex_rules_change: false,
    supports_client_authentication_enforcement: false,
    supports_clustering: true,
    supports_lfu: false,
    supports_noeviction: true,
    supports_oss_cluster: false,
    supports_oss_cluster_api_preferred_ip_type: false,
    supports_security_groups: false,
    supports_slowlog: false,
    supports_ssl: false,
    total_memory: null
  },
  absolute_bdb_max_size: 51,
  is_V4: false,
  is_acl_supported: false,
  is_rof: false,
  max_global_memcached_size: 1000000,
  max_global_redis_size: 1000000,
  max_memcached_size: 50,
  max_redis_size: 20,
  support_rcp_regex_rules_change: false,
  supports_client_authentication_enforcement: false,
  supports_clustering: true,
  supports_lfu: false,
  supports_noeviction: true,
  supports_oss_cluster: false,
  supports_oss_cluster_api_preferred_ip_type: false,
  supports_security_groups: false,
  supports_slowlog: false,
  supports_ssl: false,
  total_memory: null,
  data_persistence: 'disabled',
  db_version: null,
  dns_master_private: null,
  dns_master_public: null,
  errors: [],
  eviction_policy: 'volatile-lru',
  free_memory: null,
  free_rof: null,
  has_sharding: false,
  import_aws_access: null,
  import_aws_secret: null,
  import_email_notification: false,
  import_failure_reason: null,
  import_failure_reason_params: [],
  import_progress: null,
  import_source_type: null,
  import_status: null,
  is_redislabs_backup_path: false,
  last_change_date: null,
  last_request_time: null,
  max_throughput: 0,
  min_size: 0,
  name: 'Db2',
  oss_cluster: false,
  oss_cluster_api_preferred_ip_type: 'internal',
  ram_size: null,
  regex_rules: [],
  replica_of: [],
  shard_type_pricing_bdb_regions: [] as ShardTypePricingRegion[],
  replication: true,
  sharding_policy: 'shards_count' as ShardingPolicy,
  sharding_type: 'default-regex-rules',
  shards_count: null,
  size: 0,
  status: 'pending' as BdbStatuses,
  subscription: 95401,
  sync: false,
  sync_info: null,
  sync_state: 'no-sync',
  usage: 6606027,
  used_ram: null,
  used_rof: null
};

export const paygDefaultValues = {
  is_V4: true,
  is_acl_supported: true,
  is_rof: false,
  support_rcp_regex_rules_change: true,
  supports_client_authentication_enforcement: true,
  supports_lfu: true,
  supports_oss_cluster: true,
  supports_oss_cluster_api_preferred_ip_type: true,
  supports_security_groups: true,
  supports_slowlog: true,
  supports_ssl: true,
  total_memory: 45870,
  min_size: 6606027
};
