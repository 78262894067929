import styled, { css } from 'styled-components/macro';
import { AppBar } from '@redislabsdev/redislabs-ui-components';
import { UserMenuContainer } from './components/UserMenu/UserMenu.style';

export const TopBarContainer = styled(AppBar)<{ superAgent: boolean }>`
  & > * {
    justify-content: flex-end;
  }
  ${({ superAgent, theme: { colors } }) =>
    superAgent &&
    css`
      background-color: ${colors.pink1};
      * &:not(${UserMenuContainer}) {
        color: ${colors.white};
      }
    `}
`;
