import styled from 'styled-components/macro';
import { DropdownMenu as DropdownMenuComponent } from '@redislabsdev/redislabs-ui-components';

export const Wrapper = styled.div`
  padding: 0.8rem 0.8rem 0rem 0.8rem;
  display: flex;
  flex-direction: column;

  & > span {
    margin-bottom: 0.4rem;
  }

  & > fieldset {
    margin-bottom: 2.8rem;
  }

  & button {
    font-size: 1.2rem;
  }
`;

export const Separator = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightGray2};
  margin-top: 3rem;
`;

export const DownloadAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.6rem;

  & span {
    margin-left: 0.4rem;
  }

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const DropdownMenu = styled(DropdownMenuComponent)`
  width: 100%;
  margin-bottom: 2.4rem;
`;
