import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  line-height: 2.2rem;
  justify-content: center;
  white-space: pre-line;
`;

export const Title = styled.div`
  color: ${({ theme: { colors } }) => colors.blue15};
  font-weight: 600;
`;

export const Body = styled.div`
  color: ${({ theme: { colors } }) => colors.blue2};
`;

export const StyledCheckbox = styled.input`
  border-color: ${({ theme: { colors } }) => colors.red1};
  :checked {
    background-color: ${({ theme: { colors } }) => colors.red1};
  }
`;

export const CheckboxContainer = styled.div`
  margin-top: 1.3rem;
  label {
    margin-top: 0.2rem;
    font-weight: 600;
    color: ${({ theme: { colors } }) => colors.red1};
  }
`;
