import { createResourceApi } from '../../createResourceApi';

export const connectionWizardTemplatesApi = createResourceApi(
  '/connectionWizardTemplates',
  (rest) => ({
    getAll: () => rest.get<ConnectionWizardResponse>(),
    logRedisInsightDownload: (data: Pick<ConnectionWizardRedisInsightDownloadLink, 'os_name'>) =>
      rest.post<void>({
        path: `actions/log_ri_download`,
        data
      })
  })
);
