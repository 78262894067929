import { Link } from 'react-router-dom';
import { useState } from 'react';
import { SideBar } from '@redislabsdev/redislabs-ui-components';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { useSelector } from 'react-redux';
import { Config } from 'config';
import SideBarLink from './components/SideBarLink';
import useSideBarItems from './hooks/useSideBarItems';
import * as S from './MainSideBar.style';
import { PRIVACY_LINK, TERMS_LINK } from '../../../../utils/constants/docLinks';
import ProtectedComponent from '../../../../components/PermissionsProtectors/components/ProtectedComponent/ProtectedComponent';
import { parametersQueries } from '../../../../queryClient/parameters/parameters.queries';
import useNavigation from '../../../../services/hooks/useNavigation';
import { isProductionSelector } from '../../../../store/auth/auth.selectors';

const MainSideBar = ({
  hideNavigation,
  children
}: {
  hideNavigation?: boolean;
  children: (allAvailablePaths: string[]) => React.ReactNode;
}) => {
  const isProduction = useSelector(isProductionSelector);
  const navigation = useNavigation();
  const { t } = useTranslation();
  const keyPrefix = i18translation.sidebar;
  const { systemParams } = parametersQueries.useSystemParameters();
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const { topSectionLinks, middleSectionLinks, bottomSectionLinks, allAvailablePaths } =
    useSideBarItems();
  const toggleSidebar = () => setIsSideBarOpen(!isSideBarOpen);

  return (
    <>
      {!hideNavigation && (
        <SideBar
          isExpanded={isSideBarOpen}
          data-testid="sidebar--primary"
          data-state={isSideBarOpen ? 'expanded' : 'confined'}
        >
          <S.SideBarHeader onToggle={toggleSidebar}>
            <Link to="/" title="Redis Home Page">
              <SideBar.RedisLogo />
            </Link>
          </S.SideBarHeader>
          <S.ScrollContainer>
            <S.Content>
              <SideBar.ItemsContainer>
                {topSectionLinks.map((item) => (
                  <SideBarLink key={item.title} {...item} />
                ))}

                <SideBar.Divider />

                {middleSectionLinks.map((item) => (
                  <SideBarLink key={item.title} {...item} />
                ))}

                <ProtectedComponent
                  requiredPermissions={['subscription_create_essentials', 'subscription_create']}
                  partialPermissions
                >
                  <S.SideBarButton
                    onClick={() => {
                      navigation.goToCreateSubscription();
                    }}
                    data-testid="button--add"
                    buttonExpandedContent={t(keyPrefix.buttons.newSubscription)}
                    tooltipProps={{ tooltipContent: t(keyPrefix.buttons.newSubscription) }}
                  />
                </ProtectedComponent>
              </SideBar.ItemsContainer>

              <SideBar.Divider noMargin fullWidth />

              <SideBar.Footer>
                <SideBar.ItemsContainer>
                  {bottomSectionLinks.map((item) => (
                    <SideBarLink key={item.title} {...item} />
                  ))}
                </SideBar.ItemsContainer>

                <SideBar.Divider fullWidth />

                <S.SideBarFooterMetaData>
                  <S.FooterLinksContainer>
                    <SideBar.Footer.Link href={TERMS_LINK} target="_blank" rel="noreferrer">
                      {t(keyPrefix.terms)}
                    </SideBar.Footer.Link>
                    {' | '}
                    <SideBar.Footer.Link href={PRIVACY_LINK} target="_blank" rel="noreferrer">
                      {t(keyPrefix.privacy)}
                    </SideBar.Footer.Link>
                  </S.FooterLinksContainer>
                  <SideBar.Footer.Text>
                    {`© ${systemParams.current_year} Redis`}
                    {!isProduction && systemParams.ui_refresh_version && (
                      <>
                        {` | UI-R v${systemParams.ui_refresh_version}`}
                        <div>({new URL(Config.API_URL).hostname})</div>
                      </>
                    )}
                  </SideBar.Footer.Text>
                </S.SideBarFooterMetaData>
              </SideBar.Footer>
            </S.Content>
          </S.ScrollContainer>
        </SideBar>
      )}
      {children(allAvailablePaths)}
    </>
  );
};

export default MainSideBar;
