import styled from 'styled-components/macro';
import { SvgIcon as SvgIconComponent } from '@redislabsdev/redislabs-ui-components';

export const Container = styled.div`
  display: flex;
  position: absolute;
  bottom: -3.5rem;
  left: 0;
  padding: 1.3rem 2rem;
  border-radius: 0.8rem;
  color: ${({ theme: { colors } }) => colors.white};
  background-color: ${({ theme: { colors } }) => colors.purple1};
  white-space: pre-line;
  width: 100%;
`;

export const SvgIcon = styled(SvgIconComponent)`
  margin-inline-end: 1.5rem;
`;
