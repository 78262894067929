const { VITE_APP_API_URL, NODE_ENV } = process.env;
const DYNAMIC_REMOTE_URL = `${window.location.origin}/api/v1`;
const PROD_HOST = 'app.redislabs.com';
const searchParams = new URLSearchParams(window.location.search);
const APP_API_URL = VITE_APP_API_URL;

let apiUrlParam;
if (APP_API_URL) {
  apiUrlParam = APP_API_URL;
} else if (searchParams.get('api_env')) {
  apiUrlParam = `https://${searchParams.get('api_env')}.redislabs.com/api/v1`;
  window.sessionStorage.setItem('api_env', apiUrlParam);
} else if (window.sessionStorage.getItem('api_env') !== null) {
  apiUrlParam = window.sessionStorage.getItem('api_env');
}

const isProd = window.location.host === PROD_HOST;
const shouldUseDynamicRoute = isProd || !apiUrlParam;

const API_URL = shouldUseDynamicRoute ? DYNAMIC_REMOTE_URL : apiUrlParam;
const ORIGIN_URL = new URL(API_URL).origin;

export const Config = {
  ORIGIN_URL,
  API_URL
};

if (NODE_ENV === 'test') {
  Config.API_URL = 'http://localhost';
}
