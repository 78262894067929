import { parametersQueries } from 'queryClient/parameters/parameters.queries';
import { useSelector } from 'react-redux';
import { getAbPercentages } from 'utils/abTest/abTest';
import { getCreateSubscriptionPlanPath } from 'utils/subscription/createSubscriptionPlanPath';
import history from '../../hashHistory';
import { accountIdSelector } from '../../store/auth/auth.selectors';

const useNavigation = () => {
  const accountId = useSelector(accountIdSelector);
  const { systemParams } = parametersQueries.useSystemParameters();

  const goToCreateSubscription = () => {
    const abPercentages = getAbPercentages(systemParams.ab_percentages_recommended_subscription);
    const planPath = getCreateSubscriptionPlanPath(Number(accountId), abPercentages);

    history.push(planPath);
  };

  return {
    goToCreateSubscription
  };
};

export default useNavigation;
