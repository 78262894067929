import { useSelector } from 'react-redux';
import UserMenu from './components/UserMenu/UserMenu';
import {
  authReducerSelector,
  shouldNotifyProductionForAgentSelector
} from '../../store/auth/auth.selectors';
import { TopBarContainer } from './TopBar.style';
import useTopBarItems from './hooks/useTopBarItems';

const TopBar: React.FC = () => {
  const user = useSelector(authReducerSelector);
  const shouldNotifyProductionForAgent = useSelector(shouldNotifyProductionForAgentSelector);
  const getAppBarItems = useTopBarItems();

  return (
    <TopBarContainer
      superAgent={shouldNotifyProductionForAgent}
      itemList={getAppBarItems()}
      renderUserMenu={UserMenu}
      email={user.email}
      userName={user.name}
    />
  );
};

export default TopBar;
