import RedisApiAxiosInstance from '../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';

const mfaAxiosOptions = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export const enableMfa = () => RedisApiAxiosInstance.post('/mfa/enable_mfa');
export const disableMfa = () => RedisApiAxiosInstance.post('/mfa/disable_mfa');
export const mfaInfo = () => RedisApiAxiosInstance.get('/mfa/get_mfa_info');
export const mfaSmsSetup = (data: string) =>
  RedisApiAxiosInstance.post('/mfa/config_sms', data, mfaAxiosOptions);
export const mfaSmsVerify = (data: string) =>
  RedisApiAxiosInstance.post('/mfa/activate_factor/SMS', data, mfaAxiosOptions);
export const mfaTotpVerify = (data: string) =>
  RedisApiAxiosInstance.post('/mfa/activate_factor/Totp', data, mfaAxiosOptions);
export const mfaTotpDisable = () => RedisApiAxiosInstance.post('/mfa/remove_factor/Totp');
