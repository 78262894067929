import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { usePrefixedTranslation } from '../../hooks';
import { isProductionSelector } from '../../store/auth/auth.selectors';
import * as S from './PocAccountAlert.style';

interface IProps {
  isSuperAgent?: boolean;
  date?: string;
}

const PocAccountAlert: FC<IProps> = ({ isSuperAgent = false, date = '1-Nov-2021' }) => {
  const isProduction = useSelector(isProductionSelector);
  const isSuperAgentOnProduction = isSuperAgent && isProduction;

  const { prefixedT } = usePrefixedTranslation('PocAccountAlert');

  return (
    <S.AlertContainer isSuperAgent={isSuperAgentOnProduction}>
      <S.ClockIcon
        name={IconNamesEnum.PENDING3}
        height={15}
        width={15}
        fill={isSuperAgentOnProduction ? 'white' : 'purple1'}
      />
      <S.Text>{prefixedT('willExpire', { date })}</S.Text>
    </S.AlertContainer>
  );
};

export default PocAccountAlert;
