import styled from 'styled-components/macro';
import { Loader as LoaderComponent } from '@redislabsdev/redislabs-ui-components';
import { Wrapper } from '@redislabsdev/redislabs-ui-components/ui/components/SideBar/SideBar.style';

export const PageContainer = styled.div`
  display: flex;

  ${Wrapper} {
    height: 100vh;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

export const BodyContainer = styled.main<{ $hideNavigation: boolean }>`
  display: flex;
  width: 100%;
  height: ${({ $hideNavigation }) => ($hideNavigation ? '100vh' : 'calc(100vh - 7rem)')};
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.lightGray1};
  overflow-y: auto;
`;

export const Loader = styled(LoaderComponent)`
  height: 100vh;
`;
