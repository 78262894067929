import { FC, useState } from 'react';
import { Button } from '@redislabsdev/redislabs-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { FreeDbFormProps } from '../FreeDb.types';
import * as S from '../../LoginRelatedComponents.style';
import {
  buildFreeDatabaseBody,
  DEFAULT_VENDOR,
  getFirstRegion,
  getFreePlans,
  getVendorRegionPlanMapper,
  handleClick
} from '../../utils/loginRelated';
import FreeDbCloudVendor from './FreeDbCloudVendor/FreeDbCloudVendor';
import usePrefixedTranslation from '../../../../hooks/usePrefixedTranslation';
import { productTypeSelector } from '../../../../store/auth/auth.selectors';
import { useEssentialPlansQuery } from '../../../../hooks/useEssentialPlansQuery/useEssentialPlansQuery';
import { CloudProviders } from '../../../CloudProvider/CloudProvider.types';
import { MAX_DB_NAME_LENGTH } from '../../../../utils/constants/SubscriptionValidations';
import { postEssentialPlanRequest } from '../../../../store/createSubscription/essentials/essentials.actions';
import useAccount from '../../../../hooks/useAccount';
import useRegions from '../../../../hooks/useRegions';
import {
  createUpdateDBRequest,
  setShowRedisStackCongratulations
} from '../../../../store/databases/databases.actions';
import history from '../../../../hashHistory';
import { buildPath, routes } from '../../../../utils/constants/routes';
import { extractErrorAndShowToast } from '../../../../utils/helpers/extractErrorAndShowToast';
import { getModulesByClusterId, getClustersBySubId } from '../../utils/freeDb.calls';
import { showNotification } from '../../../Notification/Notification';
import i18n from '../../../../locale/i18n';
import { unwantedDbNameCharRegex } from '../../../../utils/validations/databasesValidations';
import redis7Utils from '../../../../utils/redis7/redis7.utils';
import { parametersQueries } from '../../../../queryClient/parameters/parameters.queries';
import useNavigation from '../../../../services/hooks/useNavigation';

const FreeDbForm: FC<FreeDbFormProps> = ({ setShowLoader }) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { getRegionByRegionName } = useRegions();
  const productType = useSelector(productTypeSelector);
  const { prefixedT } = usePrefixedTranslation('LoginScreen.welcomeScreen');
  const { systemParams } = parametersQueries.useSystemParameters();
  const { account } = useAccount();
  const { data: plans } = useEssentialPlansQuery();
  const [isOptedToRedis7, setIsOptedToRedis7] = useState(false);
  const freePlans = getFreePlans({
    plans,
    isOptedToRedis7,
    redis7Regions: redis7Utils.parseRegionIds(systemParams.redis_7_region_ids)
  });
  const vendorRegionPlanMapper = getVendorRegionPlanMapper(freePlans);
  const [selectedVendor, setSelectedVendor] = useState<CloudProviders>(DEFAULT_VENDOR);

  const [selectedRegion, setSelectedRegion] = useState(() =>
    getFirstRegion(vendorRegionPlanMapper[DEFAULT_VENDOR], getRegionByRegionName)
  );
  // max allowed name for free subscription is MAX_DB_NAME_LENGTH (18 is ' free subscription' length)
  const accountNameForDB = account?.name
    ? `${account.name.replace(unwantedDbNameCharRegex, '')}`
    : '';
  const subName = account?.name ? `${account.name.substring(0, MAX_DB_NAME_LENGTH - 18)} f` : 'F';
  const dbName = accountNameForDB.length
    ? `${accountNameForDB.substring(0, MAX_DB_NAME_LENGTH - 8)}-f`
    : 'F';

  const hideLoader = () => {
    setShowLoader(false);
    window.localStorage.removeItem('showWelcomeScreen');
  };

  const handleFreeDbError = (subscriptionId: number) => {
    hideLoader();
    history.push(buildPath(routes.subscriptions.subscription.db.createBdb, { subscriptionId }));
  };

  const handleFreeSubError = () => {
    hideLoader();
    history.push(routes.createSubscription.essential);
  };

  const handleFreeSubSuccess = async (subscription: Subscription) => {
    const plan = freePlans.find(
      (essPlan) => essPlan.id === vendorRegionPlanMapper[selectedVendor][selectedRegion]
    );

    try {
      const cluster = await getClustersBySubId(subscription.id);
      let modules = [];
      const isRedisStackSupported =
        systemParams.is_redis_stack_supported &&
        cluster?.supports_redis_stack &&
        plan?.supports_redis_stack;

      if (isRedisStackSupported) {
        modules = await getModulesByClusterId(cluster.id);
      }

      const databaseBody = buildFreeDatabaseBody({
        cluster,
        modules,
        selectedSubscription: subscription,
        plan,
        isRedisStackSupported
      });

      dispatch(
        createUpdateDBRequest({
          shouldUpdateRcp: false,
          updatedBdb: {
            ...databaseBody,
            name: `${dbName}ree-db`
          },
          successCb: (bdb) => {
            if (bdb?.is_redis_stack) {
              dispatch(setShowRedisStackCongratulations(true));
            }

            hideLoader();
            history.push(
              buildPath(routes.subscriptions.subscription.db.root, {
                subscriptionId: bdb.subscription
              })
            );
          },
          errorCb: (e) => {
            showNotification({ body: i18n.t(e.message) }, 'error');
            handleFreeDbError(subscription.id);
          },
          isCreateMode: true,
          selectedSubscription: subscription
        })
      );
    } catch (e) {
      extractErrorAndShowToast(e);
      handleFreeDbError(subscription.id);
    }
  };

  const onSubmit = () => {
    dispatch(
      postEssentialPlanRequest({
        name: `${subName}ree subscription`,
        plan: vendorRegionPlanMapper[selectedVendor][selectedRegion].toString(),
        recurring_payment_info: null,
        successCb: handleFreeSubSuccess,
        errorCb: handleFreeSubError
      })
    );

    setShowLoader(true);
  };

  return (
    <>
      <S.VendorSection>
        <S.FreeDbCloudVendorContainer data-testid="free-db-cloud-vendor">
          <FreeDbCloudVendor
            freePlans={freePlans}
            selectedVendor={selectedVendor}
            setSelectedVendor={setSelectedVendor}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            vendorRegionPlanMapper={vendorRegionPlanMapper}
            isOptedToRedis7={isOptedToRedis7}
            setIsOptedToRedis7={setIsOptedToRedis7}
          />
        </S.FreeDbCloudVendorContainer>
      </S.VendorSection>
      <S.ButtonSection>
        <Button data-testid="btn-start-free-db" type="submit" size="large" onClick={onSubmit}>
          {prefixedT('freeDB.freeBtn')}
        </Button>
        <S.LineContainer>
          <hr />
          {prefixedT('freeDB.or')}
          <hr />
        </S.LineContainer>
        <Button
          data-testid="btn-custom-free-db"
          type="button"
          variant="link"
          onClick={() => {
            handleClick(productType);
            navigation.goToCreateSubscription();
          }}
        >
          {`${prefixedT('freeDB.custom')} >`}
        </Button>
      </S.ButtonSection>
    </>
  );
};

export default FreeDbForm;
