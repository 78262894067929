import { ApiAuthResponse } from '../../../../store/auth/auth.types';
import { createResourceApi } from '../../createResourceApi';

export const usersApi = createResourceApi('/users', (rest) => ({
  getAll: () => rest.get<{ users: User[] }>(),
  getOne: (id: number) => rest.get<{ user: User }>({ path: id.toString() }),
  create: (user: Partial<User>) => rest.post<{ user: User }>({ data: { user } }),
  remove: (id: number) => rest.delete({ path: id.toString() }),
  update: (user: Partial<User>) =>
    rest.put<{ user: User }>({ path: user.id.toString(), data: { user } }),
  getAllRoles: () => rest.get<{ roles: UserRole[] }>({ path: 'roles' }),
  getCurrentUser: () => rest.get<ApiAuthResponse>({ path: 'me' })
}));
