import { mapKeys, merge } from 'lodash';
import { AccountActionTypes } from '../account/account.types';
import { AuthActionTypes, AuthActionTypesEnum, AuthUserState } from './auth.types';

const initialState = {
  id: '',
  name: '',
  email: '',
  role: undefined,
  permissions: {},
  product_type: undefined,
  is_new_ui_access: true,
  production: false,
  current_account_id: '',
  loggedIn: false,
  agent_type: undefined,
  loginErrorCode: null,
  mfaPayload: null,
  loginStatus: 'idle',
  callCsrfFlag: false,
  callCsrfPayload: null
} as AuthUserState;

export type AuthState = typeof initialState;

const authReducer = (state = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case AuthActionTypesEnum.LOGIN_REQUEST: {
      return {
        ...state,
        callCsrfFlag: false,
        loginStatus: 'pending'
      };
    }

    case AuthActionTypesEnum.LOGIN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
        loginErrorCode: null,
        callCsrfFlag: false,
        callCsrfPayload: null,
        loginStatus: 'resolved'
      };
    }

    case AuthActionTypesEnum.SET_LOGIN_ERROR_CODE: {
      return {
        ...state,
        loginErrorCode: action.payload,
        callCsrfFlag: false,
        callCsrfPayload: null,
        loginStatus: 'resolved'
      };
    }

    case AuthActionTypesEnum.MAKE_CSRF_CALL: {
      return {
        ...state,
        callCsrfFlag: true,
        callCsrfPayload: action.payload
      };
    }

    case AuthActionTypesEnum.LOGOUT_REQUEST: {
      return {
        ...state,
        callCsrfFlag: false,
        callCsrfPayload: null,
        loginStatus: 'pending'
      };
    }

    case AuthActionTypesEnum.LOGOUT_SUCCESS: {
      return {
        ...initialState,
        loggedIn: false,
        callCsrfFlag: false,
        callCsrfPayload: null,
        loginStatus: 'resolved'
      };
    }

    case AuthActionTypesEnum.SESSION_EXPIRED: {
      return {
        ...state,
        loggedIn: false,
        callCsrfFlag: false,
        callCsrfPayload: null,
        loginStatus: 'resolved'
      };
    }

    case AuthActionTypesEnum.UPDATE_USER_NAME: {
      return {
        ...state,
        name: action.payload
      };
    }

    case AuthActionTypesEnum.FILTER_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload(state.permissions)
      };

    case AuthActionTypesEnum.UPDATE_PERMISSIONS:
      return {
        ...state,
        permissions: merge({}, state.permissions, mapKeys(action.payload))
      };

    case AccountActionTypes.SET_PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        product_type: action.payload
      };

    case AuthActionTypesEnum.MFA_LOGIN:
      return {
        ...state,
        mfaPayload: action.payload
      };

    case AuthActionTypesEnum.MFA_CLEAR:
      return {
        ...state,
        mfaPayload: null,
        callCsrfFlag: false,
        callCsrfPayload: null,
        loginStatus: 'resolved'
      };

    default:
      return state;
  }
};

export default authReducer;
