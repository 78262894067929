import { newTheme } from '@redislabsdev/redis-ui-styles';
import styled from 'styled-components/macro';

export const ErrorFallbackContainer = styled.div`
  min-width: 15rem;
  margin: 2rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export const ErrorInformationContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const ErrorInformationText = styled.p`
  color: ${newTheme.semantic.color.text.danger500};
`;
