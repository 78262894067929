import styled, { css } from 'styled-components/macro';
import { Button } from '@redislabsdev/redislabs-ui-components';

export const PlusButton = styled(Button)`
  margin-inline-start: auto;
  height: 3.6rem;
  width: 3.6rem;
  background-color: ${({ theme }) => theme.colors.blue10};
  & svg {
    padding-bottom: 0.5px;
    padding-right: 0.5px;
  }

  ${({ disabled, theme }) => {
    if (disabled) {
      return css`
        &:active {
          background-color: ${theme.colors.gray1};
        }
      `;
    }

    return css`
      &:hover {
        background-color: ${theme.colors.blue11};
      }
      &:active {
        background-color: ${theme.colors.blue9};
      }
    `;
  }}
`;
