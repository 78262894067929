import styled from 'styled-components/macro';
import RegionDisplayFieldComponent from '../../../../../../../../components/RegionDisplayField/RegionDisplayField';
import { RegionValue } from '../../../../../../../../components/RegionDisplayField/RegionDisplayField.style';

export const Container = styled.div`
  display: flex;
`;

export const RegionDisplayField = styled(RegionDisplayFieldComponent)`
  ${RegionValue} {
    margin-left: 0.75rem;
    color: ${({ theme }) => theme.colors.blue2};
  }
`;

export const Redis7Text = styled.span`
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.blue1};
`;
