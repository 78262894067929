import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useSelector } from 'react-redux';
import getEnvironmentName from '../utils/getEnvironmentName';
import { authReducerSelector } from '../../../store/auth/auth.selectors';
import { accountIdSelector } from '../../../store/account/account.selectors';
import sentryContexts from '../constants/sentryContexts.constant';
import shouldFilterEvent from '../utils/shouldFilterEvent';

const useSentry = (systemParams: SystemParams) => {
  const authState = useSelector(authReducerSelector);
  const accountId = useSelector(accountIdSelector);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      const environmentName = getEnvironmentName();
      const sentryClientHub = Sentry.getCurrentHub().getClient();
      if (environmentName && !sentryClientHub && systemParams?.sentry_dsn_ui) {
        const release = process.env.VITE_APP_RELEASE_NUMBER || '1.0.0.1';
        Sentry.init({
          ignoreErrors: ['Non-Error promise rejection captured', 'Non-Error exception captured'],
          dsn: systemParams.sentry_dsn_ui,
          tracesSampleRate: 0.2,
          sampleRate: 1.0,
          environment: environmentName,
          integrations: [
            new Sentry.Integrations.Breadcrumbs({ console: false }),
            new BrowserTracing()
          ],
          normalizeDepth: 15,
          release,
          beforeSend: (event) => {
            if (shouldFilterEvent(event)) {
              return null;
            }

            return event;
          }
        });
      }
    }
  }, [systemParams]);

  useEffect(() => {
    if (authState.id) {
      Sentry.setUser({
        id: authState.id,
        role: authState.role,
        permissions: Object.values(authState.permissions)
      });
    }
  }, [authState]);

  useEffect(() => {
    if (accountId) {
      Sentry.setContext(sentryContexts.account, {
        accountId
      });
    }
  }, [accountId]);
};

export default useSentry;
