import { ToggleButton } from '@redislabsdev/redislabs-ui-components';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import * as S from './Redis7OptInToggle.style';
import { Redis7OptInProps } from '../../CreationRegionSelect.types';
import { getDataTestId } from '../../../../../../../../utils';
import HoverableInfoIcon from '../../../../../../../../components/HoverableInfoIcon/HoverableInfoIcon';
import Redis7OptInTooltipContent from './components/Redis7OptInTooltipContent/Redis7OptInTooltipContent';

interface Redis7OptInToggleProps
  extends Required<Pick<Redis7OptInProps, 'onRedis7OptInClick' | 'isOptedToRedis7'>> {}

const Redis7OptInToggle = ({ onRedis7OptInClick, isOptedToRedis7 }: Redis7OptInToggleProps) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.redis7OptIn;
  const handleRedis7OptInClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    onRedis7OptInClick(event.target.checked);
  };

  return (
    <S.Container>
      <ToggleButton
        onChange={handleRedis7OptInClick}
        checked={isOptedToRedis7}
        {...getDataTestId('switch', 'redis-7-opt-in')}
      />
      <S.ToggleLabel {...getDataTestId('readonlylabel', 'redis-7-opt-in')}>
        {t(keyPrefix.optInToggleLabel)}
      </S.ToggleLabel>
      <HoverableInfoIcon
        size="L"
        tooltipProps={{
          delayHide: 200,
          maxWidth: '36rem',
          tooltipContent: <Redis7OptInTooltipContent />
        }}
      />
    </S.Container>
  );
};

export default Redis7OptInToggle;
