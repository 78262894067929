import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { useSupportPage } from 'hooks/useSupportPage';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import useProtectedPermissionsList from '../../../../../components/PermissionsProtectors/hooks/useProtectedPermissionsList';
import { routes } from '../../../../../utils/constants/routes';
import { SideBarLinkProps } from '../MainSideBar.types';
import { parametersQueries } from '../../../../../queryClient/parameters/parameters.queries';

const useSideBarItems = () => {
  const { systemParams } = parametersQueries.useSystemParameters();
  const { t } = useTranslation();

  const keyPrefix = i18translation.sidebar.navLinks;
  const { openSupportPage } = useSupportPage();

  const { allowedList: topSectionLinks } = useProtectedPermissionsList<SideBarLinkProps>([
    {
      title: t(keyPrefix.subscriptions),
      icon: IconNamesEnum.SUBSCRIPTIONS,
      path: routes.subscriptions.root,
      requiredPermissions: 'subscription_get_subscriptions',
      activeOnPaths: [
        routes.subscriptions.root,
        routes.createSubscription.root,
        routes.changeSubscriptionPlan.root
      ]
    },
    {
      title: t(keyPrefix.databases),
      icon: IconNamesEnum.DATABASE,
      path: routes.databases.root,
      requiredPermissions: 'subscription_get_subscriptions'
    },
    {
      title: t(keyPrefix.dataAccessControl),
      icon: IconNamesEnum.IDATA_ACCESS_CONTROL,
      path: routes.dataAccessControl.root,
      requiredPermissions: 'view_acl'
    },
    {
      title: t(keyPrefix.accessManagement),
      icon: IconNamesEnum.USER_MANAGEMENT,
      path: routes.accessManagement.root,
      requiredPermissions: [
        'ui_can_access_team_page',
        'cloud_api_permission',
        'TEMP_can_access_saml_single_sign_on_tab'
      ],
      partialPermissions: true
    },
    {
      title: t(systemParams.session_log_enabled ? keyPrefix.logs : keyPrefix.serviceLog),
      icon: IconNamesEnum.LOG,
      path: routes.serviceLogs.root,
      requiredPermissions: 'system_log_get'
    }
  ]);

  const { allowedList: middleSectionLinks } = useProtectedPermissionsList<SideBarLinkProps>([
    {
      title: t(keyPrefix.accountSettings),
      icon: IconNamesEnum.SETTINGS,
      path: routes.accountSettings.root,
      requiredPermissions: ['account_get_account', 'TEMP_can_access_cloud_accounts_tab'],
      partialPermissions: true
    },
    {
      title: t(keyPrefix.usageReport),
      icon: IconNamesEnum.USAGE_REPORT,
      path: routes.usageReport.root,
      requiredPermissions: 'usage_report'
    },
    {
      title: t(keyPrefix.billingPayments),
      icon: IconNamesEnum.BILLING,
      path: routes.billingHistory.root,
      requiredPermissions: 'ui_can_access_billing_section'
    }
  ]);

  const { allowedList: bottomSectionLinks } = useProtectedPermissionsList<SideBarLinkProps>([
    {
      title: t(keyPrefix.downloadCenter),
      icon: IconNamesEnum.DOWNLOAD_ARROW,
      path: routes.downloadCenter,
      requiredPermissions: 'account_cam_download_rlec'
    },
    {
      title: t(i18translation.sidebar.anchorLinks.support),
      icon: IconNamesEnum.SUPPORT,
      type: 'action',
      action: openSupportPage
    },
    {
      title: t(i18translation.sidebar.anchorLinks.documentation),
      icon: IconNamesEnum.DOCUMENTATION,
      path: 'https://docs.redis.com/latest/rc/',
      type: 'anchor'
    }
  ]);

  const allAvailablePaths = [...topSectionLinks, ...middleSectionLinks, ...bottomSectionLinks]
    .filter((route) => route.type !== 'anchor')
    .map((route) => route.path);

  return { topSectionLinks, middleSectionLinks, bottomSectionLinks, allAvailablePaths };
};

export default useSideBarItems;
