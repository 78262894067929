import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { CloudProviders } from './CloudProvider.types';

export const getIconAndTextByProviderType = (cloudProviderType: CloudProviders): IconNamesEnum => {
  const cloudProviderIconsObj: Record<CloudProviders, IconNamesEnum> = {
    aws: IconNamesEnum.AMAZON,
    'aws-disabled': IconNamesEnum.AMAZON_LIGHT,
    azure: IconNamesEnum.AZURE,
    'azure-disabled': IconNamesEnum.AZURELIGHT,
    'azure-link': IconNamesEnum.AZURE,
    gcp: IconNamesEnum.GOOGLE_CLOUD,
    'gcp-disabled': IconNamesEnum.GOOGLE_CLOUD
  };

  return cloudProviderIconsObj[cloudProviderType];
};
