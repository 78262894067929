import { useState, useCallback } from 'react';

const useModalState = (initialModalState = false) => {
  const [isModalOpen, setModalOpen] = useState(initialModalState);

  const toggleModal = useCallback(() => setModalOpen((prev) => !prev), []);

  const hideModal = () => setModalOpen(false);

  const showModal = () => setModalOpen(true);

  return { isModalOpen, toggleModal, hideModal, showModal };
};

export default useModalState;
