import { ExtendedAxiosError } from '../../../types/interfaces';
import {
  unauthorizedStatus,
  serviceUnavailableStatus,
  notAcceptableStatus,
  unprocessableEntity,
  badRequestStatus
} from '../../../utils/constants/api/statuses';

const httpStatusesToIgnore = [
  unauthorizedStatus,
  serviceUnavailableStatus,
  notAcceptableStatus,
  unprocessableEntity
];

const shouldFilterHttpError = (error: ExtendedAxiosError, isGeneralErrorMessage: boolean) => {
  return (
    isMappedBadRequestError(isGeneralErrorMessage, error.response.status) ||
    isIgnoreStatusCode(error.response.status)
  );
};

const isIgnoreStatusCode = (statusCode: number) => httpStatusesToIgnore.includes(statusCode);

const isMappedBadRequestError = (isGeneralErrorMessage: boolean, statusCode: number) => {
  return !isGeneralErrorMessage && statusCode === badRequestStatus;
};

export default shouldFilterHttpError;
