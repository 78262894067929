import styled from 'styled-components/macro';

export const ReSendSmsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DidNotReceive = styled.p`
  margin-right: 0.2rem;
`;
