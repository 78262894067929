import RedisApiAxiosInstance from '../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';

export const getCoupons = () => RedisApiAxiosInstance.get('/coupons');

export const applyCoupon = (couponCode: string) =>
  RedisApiAxiosInstance.post('/coupons', couponCode, {
    headers: {
      'Content-Type': 'application/text'
    }
  });
