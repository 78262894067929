import { useMutation } from '@tanstack/react-query';
import { offboardingApi } from '../../services/api/resources/offboarding/offboarding.resource';
import offboardingFeedbackKeys from './offboardingFeedback.keys';

const useFeedbackMutation = () => {
  const mutationFn = async (data: {
    selectedReasonId: string;
    selectedReasonText: string;
    comments: string;
  }) => {
    await offboardingApi.postFeedback({
      reason_id: data.selectedReasonId,
      reason_text: data.selectedReasonText,
      reason_comments: data.comments
    });
  };

  return useMutation({
    mutationKey: offboardingFeedbackKeys.postReasons,
    mutationFn
  });
};

export const offboardingFeedbackMutations = {
  useFeedback: useFeedbackMutation
};
