import { useEffect } from 'react';
import ReactGA from 'react-ga';

const isProduction = process.env.NODE_ENV === 'production';

const useAnalytics = (systemParams: SystemParams) => {
  useEffect(() => {
    if (systemParams?.ga_enabled) {
      ReactGA.initialize(systemParams.ga_id, {
        titleCase: false
      });
      window[`ga-disable-${systemParams.ga_id}`] = !isProduction;
    }
  }, [systemParams]);
};

export default useAnalytics;
