import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../store/auth/auth.action';
import { routes } from '../utils/constants/routes';
import { Loader } from './Login/Login.style';

const LogOut: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout({ redirect: routes.login }));
  }, [dispatch]);

  return <Loader dataTestId="loader-logout-screen" />;
};

export default LogOut;
