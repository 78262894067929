import { useSelector } from 'react-redux';
import { utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { accountTimezoneSelector } from '../store/account/account.selectors';
import localeMapper from '../utils/constants/dateLocaleMapper';

const useTimezoneFormat = () => {
  const accountTimezone = useSelector(accountTimezoneSelector);

  const { i18n } = useTranslation();

  return (timestamp: string, timeFormat = 'dd-MMM-yyyy HH:mm:ss') => {
    let formattedTime = '';
    if (!!accountTimezone && !!timestamp) {
      try {
        const convertedTime = utcToZonedTime(timestamp, accountTimezone);
        formattedTime = format(convertedTime, timeFormat, { locale: localeMapper[i18n.language] });
      } catch (e) {
        console.error(`Failed to format time '${timestamp}' with zone '${accountTimezone}': ${e}`); // eslint-disable-line
      }
    }

    return formattedTime;
  };
};

export default useTimezoneFormat;
