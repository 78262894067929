import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { accountsStateSelector } from '../../../../store/account/account.selectors';
import { Account } from '../../../../store/account/account.types';
import RedisApiAxiosInstance from '../../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import useSmQuery from '../../../../queryClient/useSmQuery';

export interface ExtendedAccount extends Account {
  isEligible: boolean;
}

const useMarketplaceAccountsQuery = () => {
  const fetchMarketplaceAccounts = async () => {
    const { data } = await RedisApiAxiosInstance.get<{ accounts: Account[] }>(
      '/accounts?marketplace-subscribable=true'
    );

    return data.accounts;
  };

  return useSmQuery({
    permissionKey: 'account_get_accounts',
    queryKey: ['marketplace-accounts'],
    queryFn: fetchMarketplaceAccounts
  });
};

const useMarketplaceAccounts = () => {
  const { isInitialLoading, isSuccess, data: marketplaceAccounts } = useMarketplaceAccountsQuery();
  const { data: allAccounts, status: allAccountsStatus } = useSelector(accountsStateSelector);
  const [newMarketplaceAccountsList, setNewMarketplaceAccountsList] = useState<ExtendedAccount[]>(
    []
  );

  useEffect(() => {
    if (isSuccess && allAccountsStatus === 'resolved') {
      const notEligible = _.differenceWith(allAccounts, marketplaceAccounts, _.isEqual).map(
        (acc) => ({ ...acc, isEligible: false })
      );

      const EligibleAccounts = marketplaceAccounts.map((acc) => ({ ...acc, isEligible: true }));
      setNewMarketplaceAccountsList([...EligibleAccounts, ...notEligible]);
    }
  }, [isSuccess, allAccountsStatus, allAccounts, marketplaceAccounts]);

  const areAllAccountsNotEligible = newMarketplaceAccountsList.every((acc) => !acc.isEligible);

  return {
    newMarketplaceAccountsList,
    areAllAccountsNotEligible,
    isLoading: isInitialLoading || allAccountsStatus === 'idle' || allAccountsStatus === 'pending'
  };
};

export default useMarketplaceAccounts;
