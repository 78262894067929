import { SvgIcon, Button } from '@redislabsdev/redislabs-ui-components';
import { theme } from '@redislabsdev/redislabs-ui-components/styles';
import { ToastContainer, TypeOptions } from 'react-toastify';
import styled from 'styled-components/macro';
import { notificationVariantColors } from './Notification.constants';

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NotificationBody = styled.div`
  max-width: 50rem;
  width: 100%;
  min-width: 32rem;
  font-size: 1.4rem;
`;

export const NotificationContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress'
})`
  width: unset;
  max-width: unset;
  padding: 0;
  margin: 0;
  color: ${theme.colors.white};
  .Toastify__toast {
    padding: 1.2rem 2.4rem;
    border-radius: 8px;
  }

  .Toastify__toast--error {
    background-color: ${notificationVariantColors.error};
  }
  .Toastify__toast--warning {
    background-color: ${notificationVariantColors.warning};
  }
  .Toastify__toast--success {
    background-color: ${notificationVariantColors.success};
  }
  .Toastify__toast--info {
    background-color: ${notificationVariantColors.info};
  }
`;

export const NotificationIcon = styled(SvgIcon)`
  min-width: 2rem;
  margin-right: 1.6rem;
`;

export const CancelIcon = styled(SvgIcon)`
  min-width: 2rem;
  margin-left: 2.4rem;
`;

export const ActionButton = styled(Button)<{ colorVariant: TypeOptions }>`
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.white};
  margin: 0 1.2rem;
  min-width: auto;
  &:hover {
    color: ${({ colorVariant }) => notificationVariantColors[colorVariant]};
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${({ colorVariant }) => notificationVariantColors[colorVariant]};
  }
`;

export const Title = styled.p`
  font-family: ${({ theme: { fontTypes } }) => fontTypes.semiBold};
`;
