import styled from 'styled-components/macro';
import { DropdownMenu as DropdownMenuComponent } from '@redislabsdev/redislabs-ui-components';
import RegionDisplayFieldComponent from '../RegionDisplayField/RegionDisplayField';
import { RegionValue } from '../RegionDisplayField/RegionDisplayField.style';

export const Title = styled.h3`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.blue2};
  margin-bottom: 0.5rem;
`;

export const RegionSelectContainer = styled.div`
  z-index: 3;
`;

export const RegionDisplayField = styled(RegionDisplayFieldComponent)`
  ${RegionValue} {
    margin-left: 0.75rem;
    color: ${({ theme }) => theme.colors.blue2};
  }
`;

export const DropdownMenu = styled(DropdownMenuComponent)`
  width: 100%;

  button {
    ${RegionDisplayField} {
      ${RegionValue} {
        color: initial;
      }
    }
  }
`;
