import CloudProvider from '../../../../../../components/CloudProvider/CloudProvider';
import { CloudProviders } from '../../../../../../components/CloudProvider/CloudProvider.types';
import { CardsContainer } from './CloudSelect.style';
import { parametersQueries } from '../../../../../../queryClient/parameters/parameters.queries';

interface CloudSelectProps {
  cloudProviders: CloudProviders[];
  onCloudSelect: (provider: CloudProviders) => void;
  selectedProvider?: CloudProviders;
}

const CloudSelect = ({ cloudProviders, onCloudSelect, selectedProvider }: CloudSelectProps) => {
  const { systemParams } = parametersQueries.useSystemParameters();

  return (
    <CardsContainer providersLen={cloudProviders.length} data-testid="list--cloud-vendors">
      {cloudProviders.map((provider) => (
        <CloudProvider
          key={provider}
          cloudProvider={provider}
          onSelect={() => onCloudSelect(provider)}
          isSelected={provider === selectedProvider}
          azureLink={systemParams.azure_marketplace_flexible_link}
        />
      ))}
    </CardsContainer>
  );
};

export default CloudSelect;
