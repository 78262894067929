import React, { FC } from 'react';
import * as Sentry from '@sentry/react';
import GeneralFallback from './GeneralFallback/GeneralFallback';
import onRuntimeError from '../utils/onRuntimeError';
import sentryTags from '../constants/sentryTags.constants';
import { SentryErrorAreaTagValues } from '../sentry.types';

interface Props extends Omit<Sentry.ErrorBoundaryProps, 'beforeCapture' | 'showDialog'> {
  errorArea: SentryErrorAreaTagValues;
}

const SentryErrorBoundary: FC<Props> = ({ children, fallback, errorArea, ...rest }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={(fallbackProps) => {
        if (fallback) {
          const FallbackComponent = fallback as Sentry.FallbackRender;

          return React.createElement(FallbackComponent, fallbackProps, fallback);
        }

        return (
          <GeneralFallback
            {...fallbackProps}
            isContactSupportDisplayed={errorArea !== 'authentication' && errorArea !== 'main-app'}
          />
        );
      }}
      onError={onRuntimeError}
      beforeCapture={(scope) => {
        scope.setTag(sentryTags.errorArea, errorArea);
        scope.setTag(sentryTags.runtimeError, 'true');
      }}
      {...rest}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
