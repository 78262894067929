export const getAbPercentages = (systemParamsAbPercentages: string): number[] => {
  const defaultValue = [100, 0];
  const permitedValues = ['0,100', '25,75', '50,50', '75,25', '100,0'];
  const percentages = String(systemParamsAbPercentages).replace(/\s/g, '');

  if (!permitedValues.includes(percentages)) {
    return defaultValue;
  }

  const options = percentages.split(',').map((item) => Number(item));

  return options;
};

export function getAbPercentageSection(n: number, percentages: number[]): number {
  const minNumber = Math.max(Math.min(...percentages), 1);
  const gcdPercentages = percentages.map((v) => v / minNumber);
  const total = gcdPercentages.reduce((a, v) => a + v, 0);

  for (let i = 0, percent = 0; i < gcdPercentages.length; i++) {
    percent += gcdPercentages[i];

    if (n % total < percent) {
      return i;
    }
  }

  return -1;
}
