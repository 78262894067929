import { TemplateBlock } from '@redislabsdev/redislabs-ui-components';
import { useRef, useState } from 'react';
import { DownloadIcon } from '@redislabsdev/redis-ui-icons';
import * as S from './ConnectionWizardRedisInsight.style';
import * as CWS from '../../ConnectionWizard.style';
import usePrefixedTranslation from '../../../../hooks/usePrefixedTranslation';
import { EndpointSelection } from '../EndpointSelection/EndpointSelection';
import { CopySnippet } from '../CopySnippet/CopySnippet';
import { REDIS_INSIGHT_LINK } from '../../../../utils/constants/docLinks';
import ReadMore from '../../../ReadMore/ReadMore';
import { detectOS } from '../../../../utils/helpers/os';
import RegionSelect from '../../../RegionSelect/RegionSelect';
import { useConnectionWizardData } from '../../hooks/useConnectionWizardData';
import {
  detectDisplayableReplaceMessage,
  getTemplateBlockForCodeSnippets,
  TEMPLATE_BLOCK_STATIC
} from '../utils';
import { connectionWizardTemplatesMutations } from '../../../../queryClient/connectionWizardTemplates/connectionWizardTemplates.mutations';

interface ConnectionWizardRedisInsightProps {
  redisInsight: ConnectionWizardRedisInsight;
}

export function ConnectionWizardRedisInsight({ redisInsight }: ConnectionWizardRedisInsightProps) {
  const sectionName = 'redis-insight';
  const { prefixedT } = usePrefixedTranslation('databasesTable.connectionWizard');
  const {
    availableRegions,
    bdb,
    crdb,
    selectedRegion,
    endpoint,
    handleRegionSelect,
    handleOnEndpointChange,
    radioButtonChecked
  } = useConnectionWizardData(sectionName);

  const logRedisInsightDownloadMutation =
    connectionWizardTemplatesMutations.useLogRedisInsightDownload();

  const ref = useRef();
  const operatingSystem = detectOS();
  const [selectedRedisInsight, setSelectedRedisInsight] = useState(
    redisInsight.download_links.find(({ os_name }) => os_name === operatingSystem) ||
      redisInsight.download_links[0]
  );

  const handleOnDownloadClick = () => {
    logRedisInsightDownloadMutation.mutate(selectedRedisInsight.os_name);
  };

  if (!bdb) {
    return null;
  }

  return (
    <S.Wrapper>
      {crdb && (
        <CWS.RegionSelectDropdownContainer>
          <span>{prefixedT('selectProducerRegion')}</span>
          <RegionSelect
            withTitle={false}
            onRegionSelect={handleRegionSelect}
            selectedRegion={selectedRegion}
            allRegions={availableRegions}
          />
        </CWS.RegionSelectDropdownContainer>
      )}
      <S.RedisInsightInfo>
        <span>{prefixedT('redisInsightInfo')}</span>
        <ReadMore readMoreLink={REDIS_INSIGHT_LINK} dataTestId="link--read-more-redis-insight" />
        <S.Separator />
      </S.RedisInsightInfo>
      {bdb.dns_master_private ? (
        <EndpointSelection
          section={sectionName}
          handleOnEndpointChange={handleOnEndpointChange}
          radioButtonChecked={radioButtonChecked}
        />
      ) : null}
      <span>{prefixedT('copyRedisInsightSnippet')}</span>
      <CWS.CodeSnippetBox>
        <CWS.CodeSnippetBoxContent data-testid="connectionWizardRedisInsight" ref={ref}>
          <TemplateBlock
            templates={getTemplateBlockForCodeSnippets(endpoint)}
            text={redisInsight.connection_uri_template}
          />
        </CWS.CodeSnippetBoxContent>
      </CWS.CodeSnippetBox>
      <CWS.CopyWrapper>
        <span>
          <TemplateBlock
            templates={TEMPLATE_BLOCK_STATIC}
            text={prefixedT(
              detectDisplayableReplaceMessage(redisInsight.connection_uri_template, false)
            )}
          />
        </span>
        <CopySnippet copyReference={ref} />
      </CWS.CopyWrapper>
      <S.FullWidthSeparator />
      <span>{prefixedT('downloadRedisInsight')}</span>
      <S.DownloadLinks>
        <S.DropdownMenu
          items={redisInsight.download_links}
          selectedItem={selectedRedisInsight}
          dataTestIdSuffix="redis-insight-selection"
          renderItem={(item: ConnectionWizardRedisInsightDownloadLink) =>
            prefixedT('forOSName', { osName: item.os_name })
          }
          onSelectedItemChange={({ selectedItem }) => {
            setSelectedRedisInsight(selectedItem as ConnectionWizardRedisInsightDownloadLink);
          }}
        />
        <a
          data-testid="link--download-redis-insight"
          href={selectedRedisInsight.link}
          target="_self"
          rel="noreferrer"
          onClick={handleOnDownloadClick}
        >
          <DownloadIcon size="M" />
          <span>{prefixedT('download')}</span>
        </a>
      </S.DownloadLinks>
    </S.Wrapper>
  );
}
