import styled from 'styled-components/macro';
import { SvgIcon } from '@redislabsdev/redislabs-ui-components';
import { CONTENT_LEFT_SPACE } from '../../UserMenuContent.constants';

export const Container = styled.div`
  margin-top: 1.8rem;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.darkBlue1};
  margin-left: ${CONTENT_LEFT_SPACE}; ;
`;

export const AccountsList = styled.ul`
  max-height: 20.4rem;
  overflow: auto;
  margin: 0.8rem 0;
`;

export const AccountNameAndId = styled.span<{ isCurrentAccount: boolean }>`
  color: ${({ theme, isCurrentAccount }) =>
    isCurrentAccount ? theme.colors.blue6 : theme.colors.blue2};
`;

export const AccountItem = styled.li`
  display: flex;
  :not(:only-child) {
    cursor: pointer;
    :hover {
      background-color: ${({ theme }) => theme.colors.lightGray1};
    }
  }
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem ${CONTENT_LEFT_SPACE};
`;

export const CheckIcon = styled(SvgIcon)`
  height: 1.8rem;
  path {
    fill: ${({ theme }) => theme.colors.blue6};
  }
`;
