import styled, { css } from 'styled-components/macro';
import { Button, Loader as LoaderComponent } from '@redislabsdev/redislabs-ui-components';

const smallTitleRules = css`
  font-size: 1.8rem;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.darkBlue1};
`;

const bigTitleRules = css`
  font-size: 2.8rem;
  color: ${({ theme: { colors } }) => colors.darkBlue1};
  font-weight: 600;
  padding-bottom: 4rem;
`;

export const LoginPageContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: row;
  align-items: center;
`;

export const FormTitle = styled.div<{ paddingBottom?: string }>`
  ${bigTitleRules};
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  word-break: normal;
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};

  & hr {
    background-color: ${({ theme: { colors } }) => colors.gray1};
    width: 100%;
    height: 1px;
    margin-right: 3.5rem;

    &:last-of-type {
      margin-right: 0;
      margin-left: 3.5rem;
    }
  }
`;

export const LogoSide = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.blue15};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & img:first-child {
    width: 30.2rem;
  }

  & img:last-child {
    position: absolute;
    transform: scale(1.3);
  }
`;

export const FormSide = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const MfaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40rem;

  & > button {
    margin-bottom: 3.5rem;
    display: flex;
    width: 19.3rem;
  }

  & svg {
    width: 2rem;
    height: 2.2rem;
  }

  & span {
    min-width: 4.6rem;
    text-align: left;
  }
`;

export const GoogleButton = styled(Button)`
  background-color: ${({ theme: { colors } }) => colors.blue10};

  & svg {
    margin: 0 1rem 0 0;
  }

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.blue10};
  }
`;

export const GitHubButton = styled(Button)`
  background-color: #393939;

  & svg {
    margin: 0 1rem 0 0;
  }

  &:hover {
    background-color: #393939;
  }
`;

export const SsoButton = styled(Button)`
  & svg {
    color: white;
    stroke: ${({ theme: { colors } }) => colors.blue6};
    margin: 0 1rem 0 0;
    width: 1.8rem;
    height: 2rem;
  }

  &:hover {
    box-shadow: inset 0 0 0 2px ${({ theme: { colors } }) => colors.blue6};
    color: ${({ theme: { colors } }) => colors.blue6};
  }
`;

export const ThirdPartyTitle = styled.div<{ marginBottom?: string }>`
  ${smallTitleRules};
  margin-bottom: ${({ marginBottom }) => marginBottom || '4.5rem'};
`;

export const SmallTitleBlackText = styled.div<{ alignCenter?: boolean; marginBottom?: string }>`
  ${smallTitleRules};
  color: ${({ theme: { colors } }) => colors.darkBlue1};
  ${({ alignCenter }) => alignCenter && 'text-align: center'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '1.15rem'};
`;

export const FormWrapper = styled.div`
  width: 40rem;
  justify-content: center;

  & form {
    display: flex;
    flex-direction: column;
  }

  & label {
    display: block;
    padding-top: 1.8rem;

    div {
      margin-top: 0.3rem;
    }
  }

  & a {
    padding-top: 1.2rem;
    text-decoration: underline;
    display: block;
  }

  & button {
    display: block;
    align-self: flex-end;
    margin-top: 4.5rem;
  }
`;

export const BottomTextWithLink = styled.div`
  position: absolute;
  margin-bottom: 2.1rem;
  bottom: 0;

  & a {
    font-weight: bold;
  }
`;

export const BottomNotification = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.105rem;
  color: ${({ theme: { colors } }) => colors.blue1};

  & > span {
    margin-left: 0.5rem;
  }
`;

export const TwoButtonsInline = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button:first-of-type {
    margin-right: 0.8rem;
  }

  /* used to reset the margin when only one button is used */

  & > button:last-of-type {
    margin-right: 0;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.red1};
  height: 4.8rem;
  width: 100%;
  border-radius: 1rem;
  margin-top: 1.8rem;
  padding: 0 2rem;

  + button {
    margin-top: 1.8rem;
  }
`;

export const ErrorText = styled.span`
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.white};
  padding-left: 1.5rem;
`;

export const Loader = styled(LoaderComponent)`
  height: 100vh;
`;

export const LoginLoaderWrapper = styled(Loader)`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 90%);
  display: flex;
  flex: 1;
  justify-content: center;
  position: absolute;
  z-index: 1;
  border-radius: 0.8rem;
`;

export const LegalContent = styled.div`
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 4rem;
  white-space: pre-wrap;

  & a {
    display: inline;
  }
`;
