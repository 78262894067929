import RedisApiAxiosInstance from '../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { Account } from './account.types';

export const getAccount = () => RedisApiAxiosInstance.get('/accounts?current=true');

export const updateAccount = (payload: Account) =>
  RedisApiAxiosInstance.put(`/accounts/${payload.id}`, { account: payload });

export const getAccounts = () => RedisApiAxiosInstance.get('/accounts');

export const setCurrentAccount = (accountId: number) =>
  RedisApiAxiosInstance.post(`/accounts/setcurrent/${accountId}`);

export const createCloudApiKey = () =>
  RedisApiAxiosInstance.post('/accounts/cloud-api/cloudApiAccessKey');

export const assignMarketplaceAccountId = (id: string) =>
  RedisApiAxiosInstance.post('/accounts/assign-marketplace', { id });

export const setProduct = (type: ProductType) =>
  RedisApiAxiosInstance.post(`/accounts/setproduct?type=${type}`);
