/**
 * @param validateFields uniquq interface fields, that we want to check by.
 * @returns a function that validates if object is of specific interface type.
 */

export const typeGuard =
  <T extends object>(...validateFields: (keyof T)[]) =>
  (object: unknown): object is T => {
    for (const field of validateFields) {
      if (!Object.prototype.hasOwnProperty.call(object, field)) {
        return false;
      }
    }

    return true;
  };
