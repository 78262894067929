export const accountStateSelector = (state: RootState) => state.account;

export const getAccountStateSelector = (state: RootState) =>
  accountStateSelector(state).accountState;

export const accountsStateSelector = (state: RootState) =>
  accountStateSelector(state).accountsState;

export const isHerokuAccountSelector = (state: RootState) =>
  getAccountStateSelector(state).data?.paas === 'Heroku';

export const isGcpAccountSelector = (state: RootState) =>
  getAccountStateSelector(state).data?.is_sso_gcp;

export const accountMarketplaceSelector = (state: RootState) =>
  getAccountStateSelector(state).data?.marketplace;

export const accountIdSelector = (state: RootState) => getAccountStateSelector(state).data?.id;

export const accountTimezoneSelector = (state: RootState) =>
  accountStateSelector(state).accountState.data?.time_zone;

export const samlConfigIdSelector = (state: RootState) =>
  accountStateSelector(state).accountState.data?.saml_config_id;

export const canManageCloudAccountsSelector = (state: RootState) =>
  getAccountStateSelector(state).data?.can_manage_cloud_account;
