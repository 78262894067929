import Cookies from 'js-cookie';
import { Loader } from '@redislabsdev/redislabs-ui-components';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { ExtendedAccount } from './hooks/useMarketplaceAccounts';
import { ModalParagraph } from '../../../components';
import MappingConsent from './MappingConsent/MappingConsent';
import ModalMessage from './ModalMessage/ModalMessage';
import { getDataTestId } from '../../../utils/dataAttributesHelpers';
import * as S from './MarketplaceModal.style';
import { useMarketplaceModal } from './hooks/useMarketplaceModal';
import { marketplaceCookies, marketplaceProviders } from './MarketplaceModal.constants';

interface Props {
  marketplaceAccountId: string | undefined;
}

const MarketplaceModalInner: React.FC<Props> = ({ marketplaceAccountId }) => {
  const { t } = useTranslation();

  const keyPrefix = i18translation.MarketplaceModal;

  const {
    handleModalCancel,
    handleModalSubmit,
    handleChange,
    handleConsentChange,
    isModalOpen,
    isMappingAccount,
    isMappingConsent,
    isLoading,
    areAllAccountsNotEligible,
    newMarketplaceAccountsList
  } = useMarketplaceModal({ marketplaceAccountId });

  const marketplaceProvider =
    marketplaceProviders[Cookies.get(marketplaceCookies.provider) || 'aws'];

  return (
    <S.ConfirmationDialog
      isOpen={isModalOpen}
      handleModalCancel={handleModalCancel}
      handleModalSubmit={handleModalSubmit}
      cancelButtonLabel={t(areAllAccountsNotEligible ? keyPrefix.close : keyPrefix.setupLater)}
      submitDisabled={isMappingAccount || !isMappingConsent}
      title={t(keyPrefix.Marketplace, { marketplace: marketplaceProvider })}
      bottomExtraComponent={
        !isLoading &&
        areAllAccountsNotEligible && (
          <ModalMessage
            testId="no-eligible-accounts"
            content={t(keyPrefix.modalMessages.noEligibleAccounts, {
              marketplace: marketplaceProvider
            })}
          />
        )
      }
      submitButtonLabel={areAllAccountsNotEligible ? undefined : t(keyPrefix.mapAccount)}
    >
      {isLoading || isMappingAccount ? (
        <Loader />
      ) : (
        <>
          <ModalParagraph>
            {t(
              areAllAccountsNotEligible
                ? keyPrefix.noEligibleAccounts
                : keyPrefix.selectAccountToMap,
              {
                marketplace: marketplaceProvider
              }
            )}
          </ModalParagraph>
          <S.RadioButtonsContainer>
            {newMarketplaceAccountsList.map(({ id, name, isEligible }: ExtendedAccount, idx) => (
              <S.RadioContainer key={id}>
                <S.Input
                  type="radio"
                  id={String(id)}
                  name="account"
                  value={id}
                  onChange={handleChange}
                  defaultChecked={!areAllAccountsNotEligible && idx === 0}
                  disabled={!isEligible}
                  {...getDataTestId('radiobutton', name)}
                />
                <S.Label htmlFor={String(id)} isDisabled={!isEligible}>
                  <S.AccountName>{name}</S.AccountName>
                  <S.AccountId>{id}</S.AccountId>
                </S.Label>
              </S.RadioContainer>
            ))}
          </S.RadioButtonsContainer>
          {!areAllAccountsNotEligible && (
            <MappingConsent
              marketplaceProvider={marketplaceProvider}
              isMappingConsent={isMappingConsent}
              handleConsentChange={handleConsentChange}
            />
          )}
        </>
      )}
    </S.ConfirmationDialog>
  );
};

const MarketplaceModal = () => {
  const marketplaceAccountId = Cookies.get(marketplaceCookies.id);
  if (!marketplaceAccountId) {
    return null;
  }

  return <MarketplaceModalInner marketplaceAccountId={marketplaceAccountId} />;
};

export default MarketplaceModal;
