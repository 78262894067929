import ReactGA from 'react-ga';
import { GATrack } from './ga/googleAnalytics.types';

const analytics = {
  ga: {
    track: (gaTrackEvent: GATrack) => {
      if (process.env.NODE_ENV !== 'test') {
        ReactGA.event(gaTrackEvent);
      }
    },
    pageview: (path: string) => ReactGA.pageview(path)
  }
};

export default analytics;
