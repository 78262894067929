import {
  Billing,
  GATrack,
  Mfa,
  NavPopup,
  Payment,
  Profile,
  ServiceLog,
  Settings,
  SignUp,
  SocialLogin,
  Team,
  TeamDeletePopup,
  UsageReport
} from './googleAnalytics.types';

export const trackUsageReport = (action: UsageReport['action']): GATrack<UsageReport> => ({
  category: 'usage-report',
  action
});

export const trackSettings = (action: Settings['action']): GATrack<Settings> => ({
  category: 'settings',
  action
});

export const trackTeam = (action: Team['action']): GATrack<Team> => ({
  category: 'team',
  action
});

export const trackTeamDeletePopup = (
  action: TeamDeletePopup['action']
): GATrack<TeamDeletePopup> => ({
  category: 'team-delete-popup',
  action
});

export const trackProfile = (action: Profile['action']): GATrack<Profile> => ({
  category: 'profile',
  action
});

export const trackMfa = (action: Mfa['action']): GATrack<Mfa> => ({
  category: 'mfa',
  action
});

export const trackServiceLog = (action: ServiceLog['action']): GATrack<ServiceLog> => ({
  category: 'system-log',
  action
});

export const trackBilling = (action: Billing['action']): GATrack<Billing> => ({
  category: 'billing',
  action
});

export const trackPayment = (
  category: Payment['category'] = 'payment',
  action: Payment['action']
): GATrack<Payment> => ({
  category,
  action
});

export const trackNavPopup = (action: NavPopup['action']): GATrack<NavPopup> => ({
  category: 'nav-popup',
  action
});

export const trackSocialLogin = (
  category: SocialLogin['category'],
  action: SocialLogin['action']
): GATrack<SocialLogin> => ({
  category,
  action
});

export const trackSignUp = (
  category: SignUp['category'],
  action: SignUp['action'],
  label: SignUp['label'] = 'Redis-e Cloud'
): GATrack<SignUp> => ({
  category,
  action,
  label
});
