import { useCallback } from 'react';
import { AppBarItem } from '@redislabsdev/redislabs-ui-components/ui/components/AppBar';
import { useSelector } from 'react-redux';
import {
  isAgentTypeSupportOrSuper,
  isSuperAgentSelector
} from '../../../store/auth/auth.selectors';
import SuperAgentNotify from '../components/SuperAgentNotify/SuperAgentNotify';
import useAccount from '../../../hooks/useAccount';
import PocAccountAlert from '../../PocAccountAlert/PocAccountAlert';
import useTimezoneFormat from '../../../hooks/useTimezoneFormat';
import {
  isHerokuAccountSelector,
  isGcpAccountSelector
} from '../../../store/account/account.selectors';
import EnvBanner from '../components/EnvBanner/EnvBanner';
import { getEnvBannerProps } from '../components/EnvBanner/EnvBanner.utils';

const useTopBarItems = () => {
  const shouldShowSuperAgentStamp = useSelector(isAgentTypeSupportOrSuper);
  const isSuperAgent = useSelector(isSuperAgentSelector);
  const { account } = useAccount();
  const isAccountPoc = account?.is_poc;
  const timeZoneFormat = useTimezoneFormat();
  const pocEndDate = timeZoneFormat(account?.poc_account_expiration_date, 'dd-MMM-yyyy');
  const isHerokuAccount = useSelector(isHerokuAccountSelector);
  const isAWSAccount = account?.marketplace?.provider === 'aws';
  const isGcpAccount =
    useSelector(isGcpAccountSelector) || account?.marketplace?.provider === 'gcp';
  const shouldUseEnvBanner = isHerokuAccount || isGcpAccount || isAWSAccount;

  const getAppBarItems = () => {
    const appBarItems: AppBarItem[] = [];
    const EnvBannerComponent = getEnvBannerComponent(shouldShowSuperAgentStamp);

    if (shouldShowSuperAgentStamp) {
      appBarItems.push({
        component: () => SuperAgentNotify(isSuperAgent, EnvBannerComponent),
        encompassingStyle: isAccountPoc ? 'margin-inline-end: 4rem' : ''
      });
    } else if (shouldUseEnvBanner) {
      appBarItems.push({
        component: () => EnvBannerComponent
      });
    }
    if (isAccountPoc) {
      appBarItems.push({
        component: () => <PocAccountAlert isSuperAgent={isSuperAgent} date={pocEndDate} />
      });
    }

    return appBarItems;
  };

  const getEnvBannerComponent = useCallback(
    (isSuperOrSupportAgent: boolean) => {
      if (shouldUseEnvBanner) {
        const envBannerProps = getEnvBannerProps(
          isSuperOrSupportAgent,
          isHerokuAccount,
          isGcpAccount,
          isAWSAccount
        );

        return <EnvBanner {...envBannerProps} />;
      }

      return null;
    },
    [shouldUseEnvBanner, isHerokuAccount, isGcpAccount, isAWSAccount]
  );

  return getAppBarItems;
};

export default useTopBarItems;
