import { showToast } from '@redislabsdev/redislabs-ui-components';
import { FC } from 'react';
import { parametersQueries } from '../../queryClient/parameters/parameters.queries';
import useServices from '../../services/hooks/useServices';
import { Loader } from '../Login/Login.style';

export const LoadSystemParameters: FC = ({ children }) => {
  const {
    isInitialLoading: isSysParamsLoading,
    isError,
    systemParams
  } = parametersQueries.useSystemParameters();

  useServices(systemParams);

  if (isError) {
    showToast('We encountered a problem. Please try to refresh the browser or come again later');

    return <Loader dataTestId="loader-load-load-system-params" />;
  }

  if (isSysParamsLoading) return <Loader dataTestId="loader-load-load-system-params" />;

  return <>{children}</>;
};
