import { SvgIcon } from '@redislabsdev/redislabs-ui-components';
import { NewTabIcon } from '@redislabsdev/redis-ui-icons';
import styled, { css } from 'styled-components/macro';

const SELECTED_ICON_HEIGHT = 1.9;
const CARD_HEIGHT = 10.7;
const UNDER_TEXT_HEIGHT = 1.4;
const UNDER_TEXT_MARGIN = 0.6;

const calculateCardContainerHeight = () =>
  CARD_HEIGHT + UNDER_TEXT_HEIGHT + UNDER_TEXT_MARGIN + SELECTED_ICON_HEIGHT / 2;

export const StoryBookWrapper = styled.div`
  background-color: #f4f6f7;
  width: 100%;
  height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardUnderText = styled.div`
  height: ${UNDER_TEXT_HEIGHT}rem;
  margin-top: ${UNDER_TEXT_MARGIN}rem;
  font-size: 1rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.blue10};
`;

const hoverAndSelectedCardShadow = css`
  box-shadow: 0px 0px 10px ${({ theme }) => theme.colors.shadow1};
`;

export const WhiteCircle = styled.div<{ selected: boolean }>`
  opacity: 0;
  z-index: 1;
  width: 1.2rem;
  height: 1.2rem;
  visibility: hidden;
  border-radius: 50%;
  margin-bottom: -0.75rem;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ selected }) =>
    selected &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export const ActiveCardIcon = styled(SvgIcon)<{ selected: boolean }>`
  opacity: 0;
  position: absolute;
  visibility: hidden;
  margin-top: -0.35rem;
  margin-left: -0.63rem;

  ${({ selected }) =>
    selected &&
    css`
      opacity: 1;
      z-index: 1;
      visibility: visible;
    `}
`;

export const LinkWrapper = styled.a`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s;
  border-radius: 0.8rem;
  overflow: hidden;
`;

export const Card = styled.div<{ selected: boolean }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  height: ${CARD_HEIGHT}rem;
  width: 100%;
  border-radius: 0.8rem;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 0 3px ${({ theme }) => theme.colors.shadow1};
  transition: box-shadow 0.4s;

  &:hover {
    ${hoverAndSelectedCardShadow};
    cursor: pointer;

    ${LinkWrapper} {
      opacity: 1;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      ${hoverAndSelectedCardShadow};
      border: 2px solid ${({ theme }) => theme.colors.green1};
    `}
`;

export const CardWrapper = styled.div<{ disabled: boolean }>`
  width: 14rem;
  height: ${calculateCardContainerHeight()}rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      ${Card} {
        opacity: 0.5;
      }
    `}
`;

export const DisabledText = styled.h3`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  font-weight: 400;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.blue11};
`;

export const LinkText = styled.h6`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 0 15px;
  font-size: 1rem;
  text-align: center;
  font-weight: 400;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.blue6};
`;

export const LinkUnderlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.8;
`;

export const LinkIconBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 30px 0;
  border-color: ${({ theme }) => `transparent ${theme.colors.blue6} transparent transparent`};
`;

export const LinkIcon = styled(NewTabIcon)`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  margin: 3px;
`;
