import RadioButtonGroup from '../../../RadioButtonGroup/RadioButtonGroup';
import usePrefixedTranslation from '../../../../hooks/usePrefixedTranslation';
import { EndpointType } from './endpointSelection.types';

interface EndpointSelectionProps {
  handleOnEndpointChange: (endpoint: EndpointType) => void;
  radioButtonChecked: string;
  section: string;
}

export function EndpointSelection({
  section,
  handleOnEndpointChange,
  radioButtonChecked
}: EndpointSelectionProps) {
  const { prefixedT } = usePrefixedTranslation('databasesTable.connectionWizard');

  const handleRadioButtonClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = e.target;
    handleOnEndpointChange(
      id === `${section}-${EndpointType.PRIVATE_ENDPOINT}`
        ? EndpointType.PRIVATE_ENDPOINT
        : EndpointType.PUBLIC_ENDPOINT
    );
  };

  const radioButtons = [
    {
      id: `${section}-${EndpointType.PRIVATE_ENDPOINT}`,
      label: prefixedT('privateEndpoint'),
      onChange: handleRadioButtonClick
    },
    {
      id: `${section}-${EndpointType.PUBLIC_ENDPOINT}`,
      label: prefixedT('publicEndpoint'),
      onChange: handleRadioButtonClick
    }
  ];

  return (
    <>
      <span>{prefixedT('selectEndpoint')}</span>
      <RadioButtonGroup
        radioButtons={radioButtons}
        name={section}
        labelsColor="darkBlue1"
        checkedRadioBtnId={radioButtonChecked}
      />
    </>
  );
}
