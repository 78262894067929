import { lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { resetPage } from '../../../../store/store.actions';
import { routes } from '../../../../utils/constants/routes';
import { RootReducers } from '../../../../store/store.types';
import { RouteItem } from './useMainRoutes.types';
import { isEnforcedMfaRoleSelector } from '../../../../store/mfa/mfa.selectors';
import { billingHistoryQueryKey } from '../../../BillingAndPayments/BillingHistory/hooks/useBillingHistoryQuery';
import usersKeys from '../../../../queryClient/users/users.keys';
import { authIdSelector } from '../../../../store/auth/auth.selectors';

const Subscriptions = lazy(
  () => import('../../../SubscriptionsAndDatabases/Subscriptions/Subscriptions')
);
const Databases = lazy(() => import('../../../SubscriptionsAndDatabases/Databases/Databases'));
const ServiceLogs = lazy(() => import('../../../Logs/Logs'));
const UsageReport = lazy(() => import('../../../UsageReport/UsageReport'));
const AccessManagement = lazy(() => import('../../../AccessManagement/AccessManagement'));
const AccountSettings = lazy(() => import('../../../AccountSettings/AccountSettings'));
const ChangeSubPlan = lazy(
  () => import('../../../SubscriptionsAndDatabases/Subscriptions/ChangeSubPlan/ChangeSubPlan')
);
const CreateSubscription = lazy(() => import('../../../CreateSubscription/CreateSubscription'));
const BillingAndPayments = lazy(() => import('../../../BillingAndPayments/BillingAndPayments'));
const DataAccessControlRoutes = lazy(
  () => import('../../../DataAccessControl/DataAccessControl.Routes')
);

const UserProfile = lazy(() => import('../../../UserProfile/UserProfile'));
const DownloadCenter = lazy(() => import('../../../DownloadCenter/DownloadCenter'));

const useMainRoutes = () => {
  const isEnforcedMfaRole = useSelector(isEnforcedMfaRoleSelector);
  const userId = useSelector(authIdSelector);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const resetComponent = (pageName: RootReducers) => {
    dispatch(resetPage(pageName));
  };

  const resetDatabasesPage = () => {
    dispatch(resetPage('subscriptionAndPlans'));
    resetComponent('databases');
  };

  let appRoutes: RouteItem[] = [
    {
      Component: Subscriptions,
      path: routes.subscriptions.root,
      onReset: resetDatabasesPage,
      sectionName: 'subscriptions'
    },
    {
      Component: Databases,
      path: routes.databases.root,
      onReset: resetDatabasesPage,
      sectionName: 'databases'
    },
    {
      Component: DataAccessControlRoutes,
      path: routes.dataAccessControl.root,
      onReset: () => resetComponent('dataAccessControl'),
      sectionName: 'acl'
    },
    {
      Component: ServiceLogs,
      path: routes.serviceLogs.root,
      onReset: () => resetComponent('systemLogs'),
      sectionName: 'service-logs'
    },
    {
      Component: UsageReport,
      path: routes.usageReport.root,
      onReset: () => queryClient.clear(),
      sectionName: 'usage-report'
    },
    {
      Component: ChangeSubPlan,
      path: routes.changeSubscriptionPlan.root,
      onReset: resetDatabasesPage,
      sectionName: 'change-plan'
    },
    {
      Component: CreateSubscription,
      path: [`${routes.createSubscription.root}/:plan`, `${routes.createSubscription.root}`],
      onReset: () => resetComponent('createSubscription'),
      sectionName: 'create-subscription'
    },
    {
      Component: CreateSubscription,
      path: `${routes.createSubscription.root}`,
      onReset: () => resetComponent('createSubscription'),
      sectionName: 'create-subscription'
    },
    {
      Component: BillingAndPayments,
      path: routes.billingHistory.root,
      onReset: () => queryClient.setQueryData<{}>(billingHistoryQueryKey, {}),
      sectionName: 'billing-and-payments'
    },
    {
      Component: AccessManagement,
      path: routes.accessManagement.root,
      onReset: () => {
        queryClient.invalidateQueries(usersKeys.getAll);
      },
      sectionName: 'access-management'
    },
    {
      Component: AccountSettings,
      path: routes.accountSettings.root,
      onReset: () => resetComponent('account'),
      sectionName: 'account-settings'
    },
    {
      Component: UserProfile,
      path: routes.userProfile,
      onReset: () => {
        queryClient.invalidateQueries(usersKeys.getUser(Number(userId)));
      },
      sectionName: 'user-profile'
    },
    {
      Component: DownloadCenter,
      path: routes.downloadCenter,
      sectionName: 'download-center'
    }
  ];

  if (isEnforcedMfaRole) {
    appRoutes = appRoutes.filter((route) => {
      return route.path === routes.userProfile;
    });
  }

  return { appRoutes };
};

export default useMainRoutes;
