import React, { useEffect } from 'react';
import { Loader } from '../Login/Login.style';
import useSamlTestingConnection from './hooks/useSamlTestingConnection';

export const CheckPendingTestConnection: React.FC = ({ children }) => {
  const samlTestStep = window.sessionStorage.getItem('samlTestStep');

  const { flagSamlTestConnectionEnd } = useSamlTestingConnection();

  useEffect(() => {
    if (samlTestStep) {
      flagSamlTestConnectionEnd({ testStatus: 'failed' }); // failed saml test
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return samlTestStep ? (
    <Loader dataTestId="loader-check-pending-test-connection" />
  ) : (
    <>{children}</>
  );
};
