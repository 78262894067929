import styled, { css } from 'styled-components/macro';
import { ConfirmationDialog as ConfirmationDialogComponent } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';

export const RadioButtonsContainer = styled.div`
  max-height: 14.4rem;
  overflow-y: auto;
  width: 50rem;
  margin-top: 4.4rem;
`;

export const RadioContainer = styled.div`
  margin-bottom: 2.2rem;
`;

export const Label = styled.label<{ isDisabled?: boolean }>`
  ${({ isDisabled, theme: { colors } }) =>
    isDisabled &&
    css`
      & > span {
        color: ${colors.gray1};
      }
    `};
`;

export const Input = styled.input`
  margin-inline-end: 1rem;
`;

export const AccountName = styled.span`
  margin-inline-end: 0.5rem;
  color: ${({ theme: { colors } }) => colors.darkBlue1};
`;

export const AccountId = styled.span`
  color: ${({ theme: { colors } }) => colors.blue1};
`;

export const ConfirmationDialog = styled(ConfirmationDialogComponent)`
  position: relative;
  background-color: ${({ theme: { colors } }) => colors.white};
  min-width: 64rem !important;
`;
