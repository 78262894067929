import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useModalState from '../../../../hooks/useModalState';
import RedisApiAxiosInstance from '../../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { assignMarketplaceAccountIdRequest } from '../../../../store/account/account.actions';
import { extractErrorAndShowToast } from '../../../../utils/helpers/extractErrorAndShowToast';
import useMarketplaceAccounts from './useMarketplaceAccounts';

interface UseMarketPlaceModalProps {
  marketplaceAccountId: string;
}

export const useMarketplaceModal = ({ marketplaceAccountId }: UseMarketPlaceModalProps) => {
  const { newMarketplaceAccountsList, isLoading, areAllAccountsNotEligible } =
    useMarketplaceAccounts();

  const dispatch = useDispatch();
  const { isModalOpen, toggleModal } = useModalState(!!marketplaceAccountId);
  const [selectedAccountId, setSelectedAccountId] = useState<number>(null);
  const [isMappingAccount, setIsMappingAccount] = useState(false);
  const [isMappingConsent, setIsMappingConsent] = useState(false);

  const successCb = () => {
    toggleModal();
    setIsMappingAccount(false);
  };

  const errorCb = () => {
    setIsMappingAccount(false);
  };

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAccountId(Number(value));
  };

  const handleModalSubmit = () => {
    setIsMappingAccount(true);
    const shouldSetAccount = newMarketplaceAccountsList.length > 1;

    dispatch(
      assignMarketplaceAccountIdRequest({
        errorCb,
        successCb,
        shouldSetAccount,
        marketplaceAccountId,
        selectedAccountId: selectedAccountId || newMarketplaceAccountsList[0].id
      })
    );
  };

  const handleModalCancel = async () => {
    try {
      await RedisApiAxiosInstance.delete('/accountMarketplaces/cookie');
      successCb();
    } catch (e) {
      extractErrorAndShowToast(e);
      errorCb();
    }
  };

  const handleConsentChange = () => {
    setIsMappingConsent(!isMappingConsent);
  };

  return {
    handleModalCancel,
    handleModalSubmit,
    handleChange,
    handleConsentChange,
    isModalOpen,
    isMappingAccount,
    isMappingConsent,
    isLoading,
    areAllAccountsNotEligible,
    newMarketplaceAccountsList
  };
};
