import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../../../../../store/auth/auth.action';
import { mfaPayloadSelector } from '../../../../../../../store/auth/auth.selectors';
import { MfaTypes } from '../../../../../../../store/auth/auth.types';

const useMfaForm = (mfaType: MfaTypes) => {
  const dispatch = useDispatch();
  const { username, referer } = useSelector(mfaPayloadSelector);
  const [value, setValue] = useState('');
  // sets a flag in order to remember the answer and attach it to the login request after a mfa
  // confirmation
  const migrateToSocialLoginAgreement = window.sessionStorage.getItem(
    'migrateToSocialLoginAgreement'
  );
  const migrateToSamlAgreement = window.sessionStorage.getItem('migrateToSamlAgreement');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      login({
        mfa_code: value,
        referer,
        username,
        mfa_type: mfaType,
        migrateToSocialLoginAgreement,
        migrateToSamlAgreement,
        callCsrfFlag: true
      })
    );
  };

  return { value, onChange, handleSubmit };
};

export default useMfaForm;
