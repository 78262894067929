import styled from 'styled-components/macro';
import { SvgIcon } from '@redislabsdev/redislabs-ui-components';

export const StoryWrapper = styled.div<{ isSuperAgent?: boolean }>`
  padding: 15rem;
  background-color: ${({ theme: { colors }, isSuperAgent }) =>
    isSuperAgent ? colors.pink1 : colors.white};
`;

export const AlertContainer = styled.div<{ isSuperAgent?: boolean }>`
  background-color: ${({ isSuperAgent }) =>
    isSuperAgent ? `rgba(255, 255, 255, 0.2)` : `rgba(117, 71, 222, 0.1)`};
  color: ${({ theme: { colors }, isSuperAgent }) => (isSuperAgent ? colors.white : colors.purple1)};
  min-width: 42.4rem;
  height: 3.6rem;
  border-radius: 6px;
  padding: 1rem 2rem;
  display: inline;
  margin-left: 1rem;
`;

export const ClockIcon = styled(SvgIcon)`
  margin-right: 0.8rem;
  margin-top: -0.5rem;
`;

export const Text = styled.span`
  font-weight: 600;
`;
