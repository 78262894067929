/* eslint-disable @typescript-eslint/naming-convention */
import { FC } from 'react';
import { mapKeys, capitalize } from 'lodash';
import { CountryFlag } from '@redislabsdev/redislabs-ui-components';
import { usePrefixedTranslation } from '../../hooks';
import * as S from './RegionDisplayField.style';
import useRegions from '../../hooks/useRegions';

interface IProps {
  region: string;
  noHeader?: boolean;
  hideRegionLocationName?: boolean;
  className?: string;
}

const RegionDisplayField: FC<IProps> = ({
  region,
  noHeader = true,
  hideRegionLocationName = false,
  className
}) => {
  const { prefixedT } = usePrefixedTranslation('labels');

  const { allRegions, isInitialLoading: isRegionsLoading } = useRegions();

  if (isRegionsLoading || !region) {
    return null;
  }

  const cloudRegion = mapKeys<CloudRegion>(allRegions, 'name')[region];

  if (!cloudRegion) return null;

  const { flag, city_name, country_name } = cloudRegion;

  return (
    <S.Container data-testid={`readonly--region-${region}`} className={className}>
      {!noHeader && <S.Label>{prefixedT('region')}</S.Label>}
      <S.RegionContainer>
        {flag && (
          <CountryFlag
            countryCode={flag}
            data-name={`Flag: ${capitalize(flag)}`}
            title={city_name}
          />
        )}

        {!hideRegionLocationName && city_name && country_name && (
          <S.RegionValue data-testid="readonlyvalue--region-name">
            {`${country_name} (${city_name})`}
          </S.RegionValue>
        )}
        <S.RegionId data-testid="readonlyvalue--region-id">{`${region}`}</S.RegionId>
      </S.RegionContainer>
    </S.Container>
  );
};

export default RegionDisplayField;
