import { FC, useEffect, useRef, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import { routes } from '../../utils/constants/routes';
import { errorsMap } from '../../utils/constants/api/errorsMap';
import { showNotification } from '../../components/Notification/Notification';
import useSamlTestingConnection from './hooks/useSamlTestingConnection';
import { Loader } from '../Login/Login.style';
import { SmOktaAuth } from '../../utils/oktaInstance';
import { useSetOktaAuthorizationHeaders } from './hooks/useSetOktaAuthorizationHeaders';
import { useSetCsrfToken } from './hooks/useSetCsrfToken';

const ParseTokens: FC = ({ children }) => {
  const isMounted = useRef(false);
  const { addToken, setExistingTokens } = useSetOktaAuthorizationHeaders();
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const { tokenManager, token } = oktaAuth;
  const { flagSamlTestConnectionEnd } = useSamlTestingConnection();
  const [displayLoader, setDisplayLoader] = useState(true);
  const { setCsrToken } = useSetCsrfToken();

  const showLoader = (flag = true) => {
    isMounted.current && setDisplayLoader(flag);
  };
  const hideLoader = (flag = false) => {
    isMounted.current && setDisplayLoader(flag);
  };

  const parseOktaToken = async () => {
    const samlTestStep = window.sessionStorage.getItem('samlTestStep');
    try {
      const res = await token.parseFromUrl();
      const loggedIdp = res && res.tokens.idToken.claims.idp;

      if (samlTestStep) {
        const { idp } = JSON.parse(samlTestStep);

        if (loggedIdp === idp) {
          await flagSamlTestConnectionEnd({ testStatus: 'success' }); // success saml test
        } else {
          await flagSamlTestConnectionEnd({ testStatus: 'failed' }); // failed saml test
        }
        hideLoader();
      } else {
        tokenManager.setTokens(res.tokens); // not related to saml test cases
        addToken(res.tokens.accessToken);
        // we need to wait for the login process to finish successfully but that process is in a saga and we are doing react-query inside CSRF
        await setCsrToken(true);
        hideLoader();
      }
    } catch (e) {
      // this console is used in order to facilitate debugging in a case of error from idp
      console.log({ ...e }, 'social login error'); // eslint-disable-line
      if (e?.errorCode?.includes('access_denied') && e?.errorSummary?.includes('email')) {
        // the case of mail not public on a social idp (eg. github)
        history.push(routes.missingEmailSocialLogin);
        hideLoader();
      } else {
        // any other okta related error
        showNotification({ body: e?.errorSummary || errorsMap.error_general.message }, 'error');
        if (samlTestStep) {
          await flagSamlTestConnectionEnd({ testStatus: 'failed' }); // failed saml test
        }
        hideLoader();
      }
    }
  };

  const getExistingTokens = async () => {
    const { accessToken } = await tokenManager.getTokens();
    setExistingTokens(accessToken);
    hideLoader();
  };

  const setToken = async () => {
    if (SmOktaAuth.getInstance().isLoginRedirect()) {
      showLoader();
      await parseOktaToken();
    } else {
      await setCsrToken();
      await getExistingTokens();
    }
  };

  useEffect(() => {
    isMounted.current = true;
    setToken();

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (displayLoader) {
    return <Loader dataTestId="loader-parse-tokens-on-redirect" />;
  }

  return <>{children}</>;
};

export default ParseTokens;
