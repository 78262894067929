import styled, { css } from 'styled-components/macro';
import { IColor } from '../../../types/styled';

export const ToggleContainer = styled.div<{ disabled: boolean; labelColor: IColor }>`
  display: flex;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;

      label {
        cursor: not-allowed;
      }
    `}
  label {
    background-color: transparent;
  }

  input + label {
    color: ${({ theme: { colors }, labelColor }) => colors[labelColor]};
  }
`;

export const ToggleStateIndicator = styled.label<{ disabled: boolean }>`
  margin-left: 0.4rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: pointer;
`;
