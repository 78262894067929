import { Crdb } from '../databases/databases.types';
import {
  ConnectionWizardAccordionIds,
  ConnectionWizardTypesEnum,
  ToggleConnectionWizardDrawerAction,
  SetOpenedAccordionIdAction,
  SetConnectionWizardBdbAction,
  SetConnectionWizardCrdbAction
} from './connectionWizard.types';

export const toggleConnectionWizardDrawer = (
  isOpen: boolean
): ToggleConnectionWizardDrawerAction => ({
  type: ConnectionWizardTypesEnum.TOGGLE_CONNECTION_WIZARD_DRAWER,
  payload: isOpen
});

export const setConnectionWizardBdb = (bdb: Bdb): SetConnectionWizardBdbAction => ({
  type: ConnectionWizardTypesEnum.SET_CONNECTION_WIZARD_BDB,
  payload: bdb
});

export const setConnectionWizardCrdb = (crdb: Crdb): SetConnectionWizardCrdbAction => ({
  type: ConnectionWizardTypesEnum.SET_CONNECTION_WIZARD_CRDB,
  payload: crdb
});

export const setOpenedAccordionId = (
  id: ConnectionWizardAccordionIds
): SetOpenedAccordionIdAction => ({
  type: ConnectionWizardTypesEnum.SET_OPENED_ACCORDION_ID,
  payload: id
});
