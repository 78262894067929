import styled from 'styled-components/macro';
import LoginSharedModal from '../../../LoginSharedModal/LoginSharedModal';

export const MfaModal = styled(LoginSharedModal)`
  height: 40rem;
  position: relative;

  [data-role='dialog-hub'] {
    margin: 3.7rem 0 0 0;
  }
`;
