import { useContext } from 'react';
import { getDataTestId } from 'utils';
import * as S from './CollapseHeader.styles';
import { CollapseContext } from '../../context/CollapseContext';

export interface CollapseHeaderProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  onToggled: (isOpen: boolean) => void;
}

const CollapseHeader = ({ children, onToggled, ...props }: CollapseHeaderProps) => {
  const { isOpen, testId } = useContext(CollapseContext);

  return (
    <S.CollapseHeader
      role="button"
      onClick={() => onToggled(!isOpen)}
      {...getDataTestId('summary', testId)}
      {...props}
    >
      {children}
    </S.CollapseHeader>
  );
};

export default CollapseHeader;
