import { FC } from 'react';
import { useTheme } from 'styled-components/macro';
import { Tooltip } from '@redislabsdev/redislabs-ui-components';
import { InfoIcon } from '@redislabsdev/redis-ui-icons';
import { HoverAbleInfoIconProps } from './HoverableInfoIcon.types';

const HoverableInfoIcon: FC<HoverAbleInfoIconProps> = ({
  tooltipContent,
  tooltipMaxWidth,
  size = 'XS',
  iconColor,
  textColor = 'blue1',
  tooltipProps = {}
}) => {
  const theme = useTheme();

  return (
    <Tooltip
      tooltipContent={tooltipContent}
      maxWidth={tooltipMaxWidth}
      textColor={textColor}
      zIndex={10}
      {...tooltipProps}
    >
      <InfoIcon size={size} color={iconColor ?? theme.colors.blue11} />
    </Tooltip>
  );
};

export default HoverableInfoIcon;
