import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.lightGray1};
`;

export const ContentContainer = styled.div`
  padding: 4.4rem;
  width: 36rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
`;

export const RedisLogoImg = styled.img`
  width: 9rem;
`;

export const HeadingText = styled.h2`
  margin-top: 4rem;
  margin-bottom: 2.4rem;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontTypes.bold};
  font-size: 2.8rem; ;
`;

export const ExplanationText = styled.p`
  :last-of-type {
    margin-top: 1.6rem;
  }
`;

export const Footer = styled.div`
  margin-top: 4rem;
  display: flex;
  justify-content: flex-end;
`;
