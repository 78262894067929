import { IColors } from '@redislabsdev/redislabs-ui-components/styles';
import styled from 'styled-components/macro';

export const RadioButtonsContainer = styled.fieldset<{ labelsColor: keyof IColors }>`
  border: none;
  color: ${({ theme, labelsColor }) => theme.colors[labelsColor]};

  label {
    margin: 0;
    margin-left: 0.4rem;
  }
`;

export const Legend = styled.legend`
  margin-bottom: 1.7rem;
  font-size: 1.4rem;
`;
