import { InputControl } from '@redislabsdev/redislabs-ui-components';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import * as S from './MappingConsent.style';

interface MappingConsentProps {
  marketplaceProvider: string;
  isMappingConsent: boolean;
  handleConsentChange: () => void;
}

const MappingConsent: React.FC<MappingConsentProps> = ({
  marketplaceProvider,
  isMappingConsent,
  handleConsentChange
}) => {
  const { t } = useTranslation();

  const keyPrefix = i18translation.MarketplaceModal.mappingConsent;

  return (
    <S.Wrapper data-testid="mapping-cant-be-changed">
      <S.Title>{t(keyPrefix.title)}</S.Title>
      <S.Body>{t(keyPrefix.body, { marketplace: marketplaceProvider })}</S.Body>
      <S.CheckboxContainer>
        <InputControl id="consentCheckbox" label={t(keyPrefix.action)}>
          <S.StyledCheckbox
            type="checkbox"
            checked={isMappingConsent}
            onChange={handleConsentChange}
          />
        </InputControl>
      </S.CheckboxContainer>
    </S.Wrapper>
  );
};

export default MappingConsent;
