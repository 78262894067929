import { SvgIcon } from '@redislabsdev/redislabs-ui-components';
import styled, { css } from 'styled-components/macro';
import { CONTENT_LEFT_SPACE } from './UserMenuContent.constants';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue1};
  width: 32rem;
`;

const actionItemSvgCommonStyle = css`
  margin-left: auto;
  margin-right: 0.5rem;
`;

export const EditProfileIcon = styled(SvgIcon)`
  ${actionItemSvgCommonStyle};
  path {
    fill: ${({ theme }) => theme.colors.blue2};
  }
`;

export const SignOutIcon = styled(SvgIcon)`
  ${actionItemSvgCommonStyle};
  path {
    stroke: ${({ theme }) => theme.colors.blue2};
  }
`;

export const MenuActionItem = styled.li`
  cursor: pointer;
  padding: 1.2rem ${CONTENT_LEFT_SPACE};
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray2};
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGray1};
    ${EditProfileIcon} {
      path {
        fill: ${({ theme }) => theme.colors.blue6};
      }
    }
    ${SignOutIcon} {
      path {
        stroke: ${({ theme }) => theme.colors.blue6};
      }
    }
  }
`;

export const UserName = styled.span`
  margin-left: 1.2rem;
  color: ${({ theme }) => theme.colors.blue2};
`;
