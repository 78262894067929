import { useSelector } from 'react-redux';

import { loginStatusSelector } from '../../store/auth/auth.selectors';
import { Loader } from '../Login/Login.style';

const SmAuthWrapper: React.FC = ({ children }) => {
  const loginStatus = useSelector(loginStatusSelector);

  if (loginStatus !== 'pending') {
    return <>{children}</>;
  }

  return <Loader dataTestId="loader-smauth" />;
};

export default SmAuthWrapper;
