import { isArray } from 'lodash';
import { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as S from './Main.style';
import TopBar from '../../components/TopBar/TopBar';
import Welcome from '../../components/LoginRelatedComponents/Welcome';
import useMainRoutes from './hooks/useMainRoutes/useMainRoutes';
import TopBarErrorBoundary from '../../components/TopBarErrorBoundary/TopBarErrorBoundary';
import ProtectedRoute from '../../components/PermissionsProtectors/components/ProtectedRoute/ProtectedRoute';
import { routes } from '../../utils/constants/routes';
import { isEnforcedMfaRoleSelector } from '../../store/mfa/mfa.selectors';
import MarketplaceModal from './MarketplaceModal/MarketplaceModal';
import useSessionMonitor from './hooks/useSessionMonitor';
import SentryErrorBoundary from '../../services/sentry/components/SentryErrorBoundary';
import usePreviousPath from './hooks/usePreviousPath';
import useSamlLogoutUrl from '../AccessManagement/SingleSignOn/hooks/useSamlLogoutUrl';
import MainSideBar from './Components/MainSideBar/MainSideBar';
import useAccount from '../../hooks/useAccount';
import useRegions from '../../hooks/useRegions';
import usePendo from '../../services/pendo/hooks/usePendo';
import ConnectionWizard from '../../components/ConnectionWizzard/ConnectionWizard';
import OffboardingFeedbackWrapper from '../SubscriptionsAndDatabases/OffboardingFeedback/OffboardingFeedbackWrapper';

interface MainProps {
  hideNavigation?: boolean;
}

const Main = ({ hideNavigation }: MainProps) => {
  useSessionMonitor();
  const { account } = useAccount();
  const { isInitialLoading: isRegionsLoading } = useRegions();
  const { appRoutes } = useMainRoutes();
  usePendo();

  const { previousFirstWord, previousPath } = usePreviousPath();
  useSamlLogoutUrl();

  const isEnforcedMfaRole = useSelector(isEnforcedMfaRoleSelector);

  window.sessionStorage.removeItem('showErrorNotification'); // removes the flag used to show
  // the error message on login page
  window.sessionStorage.removeItem('migrateToSocialLoginAgreement'); // removes the social login
  // migration flag
  window.sessionStorage.removeItem('loginType'); // removes the login type flag
  window.sessionStorage.removeItem('queryParams'); // removes the query params used for social login
  window.sessionStorage.removeItem('migrateToSamlAgreement'); // removes the saml login
  // migration flag

  const redirectToSamlConfig = window.sessionStorage.getItem('redirectToSamlConfig');

  if (!account || isRegionsLoading) {
    return <S.Loader />;
  }

  return (
    <S.PageContainer key={account.id}>
      <MainSideBar hideNavigation={hideNavigation}>
        {(allAvailablePaths) => {
          const getRedirectPath = () => {
            const prevPathExistsInAvailableRoutes = allAvailablePaths.some((path) => {
              return path && path.includes(previousFirstWord);
            }); // need to check if the previous path is available for this user

            window.sessionStorage.removeItem('locationPathName');

            if (prevPathExistsInAvailableRoutes) {
              return previousPath;
            }

            const firstPath = allAvailablePaths[0];
            if (isArray(allAvailablePaths[0])) {
              return firstPath[0] || routes.root;
            }

            return firstPath || routes.root;
          };

          return (
            <S.Container>
              {!hideNavigation && (
                <SentryErrorBoundary fallback={TopBarErrorBoundary} errorArea="top-bar">
                  <TopBar />
                </SentryErrorBoundary>
              )}
              <S.BodyContainer $hideNavigation={hideNavigation}>
                <Suspense fallback={null}>
                  <Switch>
                    {appRoutes.map(({ onReset, Component, path, sectionName }) => (
                      <ProtectedRoute key={String(path)} path={path}>
                        <SentryErrorBoundary onReset={onReset && onReset} errorArea={sectionName}>
                          <Component />
                        </SentryErrorBoundary>
                      </ProtectedRoute>
                    ))}
                    {isEnforcedMfaRole && <Redirect push to={routes.userProfile} />}
                    {redirectToSamlConfig && (
                      <Redirect push to={routes.accessManagement.singleSignOn} />
                    )}
                    <Redirect to={getRedirectPath()} />
                  </Switch>
                </Suspense>
              </S.BodyContainer>
            </S.Container>
          );
        }}
      </MainSideBar>
      <Welcome />
      <ConnectionWizard />
      <MarketplaceModal />
      <OffboardingFeedbackWrapper />
    </S.PageContainer>
  );
};

export default Main;
