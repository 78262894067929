import { useMutation } from '@tanstack/react-query';
import { showToast } from '@redislabsdev/redislabs-ui-components/ui/components/Toast';
import JSZip from 'jszip';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { accountsApi } from 'services/api/resources/accounts/accounts.resource';
import { i18translation } from 'locale/translations/i18NsPaths';
import accountKeys from './account.keys';

const useDownloadCertificateAuthorityMutation = () => {
  const { t } = useTranslation();
  const mutateFn = async () => {
    try {
      const { data } = await accountsApi.getDownloadCertificateAuthority();
      const zip = new JSZip();
      zip.file('redis_ca.pem', data);
      const blob = await zip.generateAsync({ type: 'blob' });
      saveAs(blob, 'redis_ca.zip');
    } catch (err) {
      showToast(t('certificates.errors.downloadCertificateError'), 'error');
    }
  };

  return useMutation(accountKeys.downloadCAkey, mutateFn);
};

const useGetZendeskLinkMutation = () => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.support;

  const mutateFn = async () => {
    try {
      const { data } = await accountsApi.getZendeskLink();

      return { url: data?.url };
    } catch (err) {
      showToast(t(keyPrefix.errorMessage), 'error');
    }

    return { url: null };
  };

  return useMutation(accountKeys.getZendeskLink, mutateFn);
};

export const accountMutations = {
  useDownloadCA: useDownloadCertificateAuthorityMutation,
  useGetZendeskLink: useGetZendeskLinkMutation
};
