import { NotificationButtonProps } from '../../components/Notification/Notification.types';
import { ExtendedAxiosError } from '../../types/interfaces';
import { unauthorizedStatus } from '../constants/api/statuses';
import { handleErrorDisplay } from './extractError';

export const extractErrorAndShowToast = (
  e: ExtendedAxiosError,
  notificationButtonProps?: NotificationButtonProps
): string => {
  let errMessage = '';
  if (e?.response) {
    if (e.response.status !== unauthorizedStatus) {
      errMessage = handleErrorDisplay(e, notificationButtonProps);
    }
  }

  return errMessage;
};
