import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import analytics from '../../../services/analytics/analytics';
import { trackSocialLogin } from '../../../services/analytics/ga/general';
import LoginSharedModal from '../components/LoginSharedModal/LoginSharedModal';
import RedisBackground from '../components/RedisBackground/RedisBackground';
import { routes } from '../../../utils/constants/routes';

const MissingEmailSocialLogin = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const keyPrefix = i18translation.MissingEmailSocialLogin;

  const handleCancel = async () => {
    analytics.ga.track(trackSocialLogin('social-login-canceled', 'yes'));

    history.push(routes.logout);
  };

  return (
    <RedisBackground>
      <LoginSharedModal
        title={t(keyPrefix.modal.title)}
        submitButtonLabel={t(keyPrefix.modal.buttons.close)}
        handleModalSubmit={handleCancel}
        handleModalCancel={handleCancel}
        isOpen
      >
        <p>{t(keyPrefix.modal.body)}</p>
      </LoginSharedModal>
    </RedisBackground>
  );
};

export default MissingEmailSocialLogin;
