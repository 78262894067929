import * as CWS from '../ConnectionWizard.style';

export const detectDisplayableReplaceMessage = (template: string, isTls: boolean) => {
  if (isTls) {
    return 'replaceParams';
  }

  const usernameRegex = /(<user>|<username>)/g;
  if (usernameRegex.test(template)) {
    return 'replaceUserAndPass';
  }

  const passwordRegex = /(<pass>|<password>)/g;
  if (passwordRegex.test(template)) {
    return 'replacePass';
  }

  return 'replaceParams';
};

export const TEMPLATE_BLOCK_STATIC = [
  {
    placeholder: '<user>',
    replaceable: <CWS.HighlightedPlaceholder bold>{'<username>'}</CWS.HighlightedPlaceholder>
  },
  {
    placeholder: '<pass>',
    replaceable: <CWS.HighlightedPlaceholder bold>{'<password>'}</CWS.HighlightedPlaceholder>
  },
  {
    placeholder: '<parameter>',
    replaceable: <CWS.HighlightedPlaceholder bold>{'<parameter>'}</CWS.HighlightedPlaceholder>
  }
];

export const getTemplateBlockForCodeSnippets = (endpoint: string) => {
  return [
    {
      placeholder: /(<host>|<hostname>)/g,
      replaceable: endpoint.split(':')[0]
    },
    {
      placeholder: '<port>',
      replaceable: endpoint.split(':')[1]
    },
    {
      placeholder: /(<user>|<username>)/g,
      replaceable: <CWS.HighlightedPlaceholder>{'<username>'}</CWS.HighlightedPlaceholder>
    },
    {
      placeholder: /(<pass>|<password>)/g,
      replaceable: <CWS.HighlightedPlaceholder>{'<password>'}</CWS.HighlightedPlaceholder>
    },
    {
      placeholder: /(<path_to_ca_certfile>)/g,
      replaceable: (
        <CWS.HighlightedPlaceholder>{'<path_to_ca_certfile>'}</CWS.HighlightedPlaceholder>
      )
    },
    {
      placeholder: /(<path_to_user_certfile>)/g,
      replaceable: (
        <CWS.HighlightedPlaceholder>{'<path_to_user_certfile>'}</CWS.HighlightedPlaceholder>
      )
    },
    {
      placeholder: /(<path_to_user_private_keyfile>)/g,
      replaceable: (
        <CWS.HighlightedPlaceholder>{'<path_to_user_private_keyfile>'}</CWS.HighlightedPlaceholder>
      )
    },
    {
      placeholder: /(<path_to_ca_truststore>)/g,
      replaceable: (
        <CWS.HighlightedPlaceholder>{'<path_to_ca_truststore>'}</CWS.HighlightedPlaceholder>
      )
    },
    {
      placeholder: /(<ca_truststore_password>)/g,
      replaceable: (
        <CWS.HighlightedPlaceholder>{'<ca_truststore_password>'}</CWS.HighlightedPlaceholder>
      )
    },
    {
      placeholder: /(<client_keystore_password>)/g,
      replaceable: (
        <CWS.HighlightedPlaceholder>{'<client_keystore_password>'}</CWS.HighlightedPlaceholder>
      )
    },
    {
      placeholder: /(<path_to_pfx_certfile>)/g,
      replaceable: (
        <CWS.HighlightedPlaceholder>{'<path_to_pfx_certfile>'}</CWS.HighlightedPlaceholder>
      )
    }
  ];
};
