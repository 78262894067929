import { FC } from 'react';
import { usePrefixedTranslation } from '../../hooks';
import Card from '../Shared/IconCard/IconCard';
import { CloudProviders, DISABLED_CLOUD_STRING, LINK_CLOUD_STRING } from './CloudProvider.types';
import { getIconAndTextByProviderType } from './utils';

interface IProps {
  isSelected: boolean;
  onSelect: () => void;
  cloudProvider: CloudProviders;
  azureLink?: string;
}

const CloudProvider: FC<IProps> = ({ isSelected, onSelect, cloudProvider, azureLink }) => {
  const { prefixedT } = usePrefixedTranslation('cloudProviderCard');
  const iconName = getIconAndTextByProviderType(cloudProvider);
  const isComingSoon = cloudProvider.includes(DISABLED_CLOUD_STRING);
  const isLink = cloudProvider.includes(LINK_CLOUD_STRING);

  return (
    <Card
      isSelected={isSelected}
      onSelect={onSelect}
      iconName={iconName}
      cardUnderText={prefixedT(
        cloudProvider.replace(new RegExp(`(${DISABLED_CLOUD_STRING}|${LINK_CLOUD_STRING})`), '')
      )}
      testId={`${cloudProvider}-provider`}
      disabled={isComingSoon}
      disabledText={prefixedT('comingSoon')}
      role="listitem"
      underTextTestId={`readonlyvalue--${cloudProvider}`}
      linkUrl={isLink && azureLink}
      linkText={isLink && prefixedT(`linkText.${cloudProvider.replace(LINK_CLOUD_STRING, '')}`)}
    />
  );
};

export default CloudProvider;
