import { ConfirmationModalProps } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { mfaPayloadSelector } from '../../../../../../store/auth/auth.selectors';
import { MfaTypes } from '../../../../../../store/auth/auth.types';
import useLogOut from '../../../../../../hooks/useLogOut';
import useFactorChange from './hooks/useFactorChange';
import useMfaForm from './hooks/useMfaForm';
import * as S from './MfaModalWrapper.style';

interface Props extends Pick<ConfirmationModalProps, 'bottomLeftComponent'> {
  title: string;
  children: (
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    value: string
  ) => React.ReactNode;
  mfaType: MfaTypes;
}

const MfaModalWrapper = ({ title, children, mfaType, ...modalProps }: Props) => {
  const { t } = useTranslation();
  const { totpFactorAvailable } = useSelector(mfaPayloadSelector);
  const { performLogout } = useLogOut();

  const status = useFactorChange(mfaType);
  const { handleSubmit, value, onChange } = useMfaForm(mfaType);

  return (
    <S.MfaModal
      title={title}
      submitButtonLabel={t('LoginScreen.mfaLogin.verify')}
      submitDisabled={!value || (totpFactorAvailable && status !== 'success')}
      handleModalSubmit={handleSubmit}
      handleModalCancel={() => performLogout()}
      isOpen
      {...modalProps}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        {children(onChange, value)}
      </form>
    </S.MfaModal>
  );
};

export default MfaModalWrapper;
