import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useLogOut from '../../../hooks/useLogOut';

const SESSION_TIME_MINUTES = 30;
const SESSION_TIME_MS = SESSION_TIME_MINUTES * 60 * 1000;
const CHECK_SESSION_INTERVAL_MS = 10000;

const useSessionMonitor = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { performLogout } = useLogOut();

  const lastActivityTimeRef = useRef(new Date().getTime());

  useEffect(() => {
    lastActivityTimeRef.current = new Date().getTime();
  }, [location.pathname]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const now = new Date().getTime();
      if (now - lastActivityTimeRef.current >= SESSION_TIME_MS) {
        await performLogout();
      }
    }, CHECK_SESSION_INTERVAL_MS);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]); // eslint-disable-line
};

export default useSessionMonitor;
