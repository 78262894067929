import styled from 'styled-components/macro';

export const UnderInputText = styled.p`
  margin-top: 0.5rem;
  color: ${({ theme }) => theme.colors.darkBlue1};
  font-size: 1.2rem;
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const FlexContainer = styled.span`
  display: flex;
`;
