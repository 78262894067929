import { FC } from 'react';
import { SvgIcon, IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { useTheme } from 'styled-components/macro';
import * as S from './IconCard.style';

interface IconCardProps {
  isSelected: boolean;
  onSelect: () => void;
  iconName: IconNamesEnum;
  cardUnderText: string;
  testId?: string;
  disabled?: boolean;
  disabledText?: string;
  role?: string;
  underTextTestId?: string;
  linkUrl?: string;
  linkText?: string;
}

const IconCard: FC<IconCardProps> = ({
  isSelected,
  onSelect,
  iconName,
  cardUnderText,
  testId = 'icon-card',
  disabled,
  disabledText,
  role,
  underTextTestId,
  linkUrl,
  linkText
}) => {
  const theme = useTheme();

  const onClick = () => {
    if (!linkUrl) {
      onSelect();
    }
  };

  return (
    <S.CardWrapper
      data-testid={testId}
      aria-selected={isSelected}
      disabled={disabled}
      data-role={role}
    >
      <S.WhiteCircle selected={isSelected}>
        <S.ActiveCardIcon
          width="2.35rem"
          height="2.35rem"
          selected={isSelected}
          name={IconNamesEnum.VALIDATED}
          data-testid="active-card-check-icon"
        />
      </S.WhiteCircle>
      <S.Card role="card" selected={isSelected} onClick={onClick}>
        <SvgIcon
          height="3rem"
          width="4.2rem"
          name={iconName}
          {...(iconName === IconNamesEnum.AZURE && { fill: theme.colors.lightBlue5 })}
        />
        {disabled && <S.DisabledText>{disabledText}</S.DisabledText>}
        {linkUrl && (
          <S.LinkWrapper href={linkUrl} target="_blank">
            <S.LinkUnderlay />
            <S.LinkText>{linkText}</S.LinkText>
            <S.LinkIconBackground />
            <S.LinkIcon size="S" color="white" />
          </S.LinkWrapper>
        )}
      </S.Card>
      <S.CardUnderText data-testid={underTextTestId}>{cardUnderText}</S.CardUnderText>
    </S.CardWrapper>
  );
};

export default IconCard;
