export enum MfaActionTypesEnum {
  MFA_ENABLE_REQUEST = 'MFA_ENABLE_REQUEST',
  MFA_ENABLE_FAILURE = 'MFA_ENABLE_FAILURE',
  MFA_DISABLE_REQUEST = 'MFA_DISABLE_REQUEST',
  MFA_DISABLE_FAILURE = 'MFA_DISABLE_FAILURE',
  MFA_INFO_REQUEST = 'MFA_INFO_REQUEST',
  MFA_INFO_SUCCESS = 'MFA_INFO_SUCCESS',
  MFA_INFO_FAILURE = 'MFA_INFO_FAILURE',
  MFA_SMS_SETUP_REQUEST = 'MFA_SMS_SETUP_REQUEST',
  MFA_SMS_SETUP_SUCCESS = 'MFA_SMS_SETUP_SUCCESS',
  MFA_SMS_SETUP_FAILURE = 'MFA_SMS_SETUP_FAILURE',
  MFA_SMS_VERIFY_REQUEST = 'MFA_SMS_VERIFY_REQUEST',
  MFA_SMS_VERIFY_SUCCESS = 'MFA_SMS_VERIFY_SUCCESS',
  MFA_SMS_VERIFY_FAILURE = 'MFA_SMS_VERIFY_FAILURE',
  MFA_TOTP_VERIFY_REQUEST = 'MFA_TOTP_VERIFY_REQUEST',
  MFA_TOTP_VERIFY_SUCCESS = 'MFA_TOTP_VERIFY_SUCCESS',
  MFA_TOTP_VERIFY_FAILURE = 'MFA_TOTP_VERIFY_FAILURE',
  MFA_TOTP_DISABLE_REQUEST = 'MFA_TOTP_DISABLE_REQUEST',
  MFA_TOTP_DISABLE_SUCCESS = 'MFA_TOTP_DISABLE_SUCCESS',
  MFA_SET_ERROR = 'MFA_SET_ERROR'
}

export interface MfaData {
  phoneNumber: string;
  smsFactorAvailable: boolean;
  totpFactorAvailable: boolean;
}

export interface MfaState extends BaseState {
  data: MfaData;
}

export type MfaActionTypes =
  | MfaGetInfoSuccessAction
  | MfaSmsVerifySuccessAction
  | MfaSmsVerifyFailureAction
  | MfaTotpVerifySuccessAction
  | MfaTotpVerifyFailureAction
  | MfaTotpDisableSuccessAction
  | MfaSetError;

export interface MfaTOTPVerifyPayload {
  verificationCode: string;
  errorCb: Function;
}

export interface MfaSMSVerifyPayload {
  phoneNumber: string;
  verificationCode: string;
  cb: Function;
  errorCb: Function;
}

export interface MfaGetInfoAction {
  type: MfaActionTypesEnum.MFA_INFO_REQUEST;
}
export interface MfaGetInfoSuccessAction {
  type: MfaActionTypesEnum.MFA_INFO_SUCCESS;
  payload: MfaData;
}

export interface MfaGetInfoFailureAction {
  type: MfaActionTypesEnum.MFA_INFO_FAILURE;
  payload: string;
}

export interface MfaEnableFailureAction {
  type: MfaActionTypesEnum.MFA_ENABLE_FAILURE;
  payload: string;
}

export interface MfaSetError {
  type: MfaActionTypesEnum.MFA_SET_ERROR;
  payload: string;
}

export interface MfaDisableAction {
  type: MfaActionTypesEnum.MFA_DISABLE_REQUEST;
  payload: MfaDisablePayload;
}

export interface MfaDisableFailureAction {
  type: MfaActionTypesEnum.MFA_DISABLE_FAILURE;
  payload: string;
}

export interface MfaDisablePayload {
  successCb: () => void;
  errorCb: () => void;
}

export interface MfaSmsSetupAction {
  type: MfaActionTypesEnum.MFA_SMS_SETUP_REQUEST;
  payload: string;
}

export interface MfaSmsVerifyAction {
  type: MfaActionTypesEnum.MFA_SMS_VERIFY_REQUEST;
  payload: MfaSMSVerifyPayload;
}

export interface MfaSmsVerifySuccessAction {
  type: MfaActionTypesEnum.MFA_SMS_VERIFY_SUCCESS;
  payload: string;
}

interface MfaSmsVerifyFailureAction {
  type: MfaActionTypesEnum.MFA_SMS_VERIFY_FAILURE;
  error: string;
}

export interface MfaTotpVerifyAction {
  type: MfaActionTypesEnum.MFA_TOTP_VERIFY_REQUEST;
  payload: MfaTOTPVerifyPayload;
}

export interface MfaTotpVerifySuccessAction {
  type: MfaActionTypesEnum.MFA_TOTP_VERIFY_SUCCESS;
}

interface MfaTotpVerifyFailureAction {
  type: MfaActionTypesEnum.MFA_TOTP_VERIFY_FAILURE;
  error: string;
}

export interface MfaTotpDisableAction {
  type: MfaActionTypesEnum.MFA_TOTP_DISABLE_REQUEST;
}

export interface MfaTotpDisableSuccessAction {
  type: MfaActionTypesEnum.MFA_TOTP_DISABLE_SUCCESS;
}
