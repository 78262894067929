import {
  cloudProviders,
  CloudProviders,
  DISABLED_CLOUD_STRING
} from '../../../../../../../components/CloudProvider/CloudProvider.types';
import { EssentialPlan } from '../../../../../../../store/createSubscription/essentials/essentials.types';

export const getAllAvailableProviders = (
  essentialPlans: EssentialPlan[],
  isOptedToRedis7: boolean
): CloudProviders[] => {
  const isCloudProviderExists = (provider: CloudProviders) =>
    essentialPlans.some((plan) => plan.cloud.toLowerCase() === provider);

  const providers: CloudProviders[] = [];

  cloudProviders.forEach((provider) => isCloudProviderExists(provider) && providers.push(provider));

  if (isOptedToRedis7) {
    if (!providers.includes('aws')) {
      providers.push(`aws${DISABLED_CLOUD_STRING}`);
    }
    if (!providers.includes('gcp')) {
      providers.push(`gcp${DISABLED_CLOUD_STRING}`);
    }
    if (!providers.includes('azure')) {
      providers.push(`azure${DISABLED_CLOUD_STRING}`);
    }
  }

  return providers;
};
