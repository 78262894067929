import styled from 'styled-components/macro';
import { Button } from '@redislabsdev/redislabs-ui-components';
import { ToastCheckIcon } from '@redislabsdev/redis-ui-icons';

export const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
`;

export const StyledButton = styled(Button)`
  line-height: unset;
`;

export const StyledIcon = styled(ToastCheckIcon)`
  display: flex;
  margin-right: 0.5rem;
`;
export const Label = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.green1};
`;
