export function detectOS() {
  // for Apple, we need to check the webgl renderer since nothing from the navigator will work
  const webGLRenderingContext = document.createElement('canvas').getContext('webgl');
  const debugRendererInfo = webGLRenderingContext?.getExtension('WEBGL_debug_renderer_info');
  const unmaskedWebGLRendered =
    (debugRendererInfo &&
      webGLRenderingContext?.getParameter(debugRendererInfo.UNMASKED_RENDERER_WEBGL)) ||
    '';
  if (
    unmaskedWebGLRendered.match(/Apple/) &&
    (!unmaskedWebGLRendered.match(/Apple GPU/) ||
      webGLRenderingContext
        ?.getSupportedExtensions()
        .indexOf('WEBGL_compressed_texture_s3tc_srgb') === -1)
  ) {
    return 'macOS ARM';
  }

  if (navigator.userAgent.includes('Mac')) {
    return 'macOS Intel';
  }

  if (navigator.userAgent.includes('Win')) {
    return 'Windows';
  }

  if (navigator.userAgent.includes('Linux')) {
    return 'Linux';
  }

  return '';
}
