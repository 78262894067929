import { UseQueryOptions } from '@tanstack/react-query';
import { offboardingApi } from '../../services/api/resources/offboarding/offboarding.resource';
import useSmQuery from '../useSmQuery';
import offboardingFeedbackKeys from './offboardingFeedback.keys';

const useReasonsQuery = (queryOptions: UseQueryOptions<string[]> = {}) => {
  const queryFn = async () => {
    const { data } = await offboardingApi.getReasons();

    return data;
  };

  return useSmQuery({
    permissionKey: false,
    queryKey: offboardingFeedbackKeys.getReasons,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...queryOptions
  });
};

export const offboardingFeedbackQueries = {
  useReasons: useReasonsQuery
};
