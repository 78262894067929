import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { parametersQueries } from '../../queryClient/parameters/parameters.queries';
import useServices from '../../services/hooks/useServices';
import { SmOktaAuth } from '../../utils/oktaInstance';

const InitializeOkta: FC = ({ children }) => {
  const history = useHistory();
  const { systemParams } = parametersQueries.useSystemParameters();

  useServices(systemParams);

  const oktaAuth = SmOktaAuth.getInstance({
    clientId: systemParams?.okta_client_id,
    issuer: systemParams?.okta_token_issuer,
    redirectUri: systemParams?.okta_redirect_uri
  });

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

export default InitializeOkta;
