import { cloneElement } from 'react';
import { Tooltip } from '@redislabsdev/redislabs-ui-components';
import usePermissionValidation from '../../hooks/usePermissionValidation';
import { PROTECTED_CLASS_NAME } from '../../utils/permissions.constants';
import { ProtectedComponentProps } from './ProtectedComponent.types';

const ProtectedComponent = ({
  requiredPermissions,
  hideOnPermissionBlocked = true,
  permissionBlockedTooltipContent,
  partialPermissions,
  blockedComponentPropsOverride,
  children
}: ProtectedComponentProps) => {
  const hasPermissions = usePermissionValidation(requiredPermissions, partialPermissions);

  if (!hasPermissions) {
    if (hideOnPermissionBlocked) {
      return null;
    }

    const DisabledComponent = cloneElement(children, {
      onClick: () => {},
      onRowClick: () => {},
      handleClick: () => {},
      disabled: true,
      className: PROTECTED_CLASS_NAME,
      ...blockedComponentPropsOverride
    });

    if (permissionBlockedTooltipContent) {
      return (
        <Tooltip tooltipContent={permissionBlockedTooltipContent}>{DisabledComponent}</Tooltip>
      );
    }

    return DisabledComponent;
  }

  return children;
};

export default ProtectedComponent;
