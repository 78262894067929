import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';

export const KILOBYTE = Math.pow(2, 10);
export const GIGABYTE = Math.pow(2, 30);

export const MEMORY_LIMIT_JUMP_SIZE = 0.1;

export const parseFloatOneDecimal = (value: number): number => parseFloat(value?.toFixed(1));

export const vendorIconMapper: Record<Uppercase<PlanCloud>, IconNamesEnum> = {
  AWS: IconNamesEnum.AMAZON,
  GCP: IconNamesEnum.GOOGLE_CLOUD,
  AZURE: IconNamesEnum.AZURE
};
