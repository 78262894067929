import { useSupportPage } from 'hooks/useSupportPage';
import { i18translation } from 'locale/translations/i18NsPaths';
import { useTranslation } from 'react-i18next';
import * as S from './ContactSupportLink.style';

export const ContactSupportLink = () => {
  const { openSupportPage } = useSupportPage();
  const { t } = useTranslation();
  const keyPrefix = i18translation.generalFallback;

  return <S.SupportLink onClick={openSupportPage}>{t(keyPrefix.contactSupport)}</S.SupportLink>;
};
