import { FC } from 'react';
import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import * as S from './ConnectionWizard.style';
import {
  bdbSelector,
  isDrawerOpenedSelector,
  openedAccordionSelector
} from '../../store/connectionWizard/connectionWizard.selectors';
import {
  setOpenedAccordionId,
  toggleConnectionWizardDrawer
} from '../../store/connectionWizard/connectionWizard.action';
import usePrefixedTranslation from '../../hooks/usePrefixedTranslation';
import { ConnectionWizardCli } from './components/ConnectionWizardCli/ConnectionWizardCli';
import { ConnectionWizardClient } from './components/ConnectionWizardClient/ConnectionWizardClient';
import { ConnectionWizardAccordionIds } from '../../store/connectionWizard/connectionWizard.types';
import { ConnectionWizardRedisInsight } from './components/ConnectionWizardRedisInsight/ConnectionWizardRedisInsight';
import { useConnectionWizardEnabled } from '../../hooks/useConnectionWizardEnabled';
import { connectionWizardTemplatesQueries } from '../../queryClient/connectionWizardTemplates/connectionWizardTemplates.queries';
import SMDrawer from '../Shared/SMDrawer/SMDrawer';
import RecommendedAnimation from '../../assets/lottie/recomended.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: RecommendedAnimation
};

const ConnectionWizard: FC = () => {
  const dispatch = useDispatch();
  const connectionWizardEnabled = useConnectionWizardEnabled();
  const { prefixedT } = usePrefixedTranslation('databasesTable.connectionWizard');
  const { data } =
    connectionWizardTemplatesQueries.useConnectionWizardTemplate(connectionWizardEnabled);
  const bdb = useSelector(bdbSelector);
  const isDrawerOpened = useSelector(isDrawerOpenedSelector);
  const openedAccordionId = useSelector(openedAccordionSelector);
  const toggleDrawer = (value: boolean) => {
    dispatch(toggleConnectionWizardDrawer(value));
  };
  const openAccordion = (id: ConnectionWizardAccordionIds) => {
    dispatch(setOpenedAccordionId(id));
  };

  if (!connectionWizardEnabled || !data?.connectionWizardTemplates) {
    return null;
  }

  const { cliCommand, clients, redisInsight } = data.connectionWizardTemplates;

  return (
    <SMDrawer
      isOpen={isDrawerOpened}
      onClose={() => toggleDrawer(false)}
      width="52rem"
      data-testid="connection-wizard-drawer"
    >
      <>
        <S.DrawerHeader data-testid="title--connection-wizard-drawer">
          <div>
            <SMDrawer.Caption>{prefixedT('drawerTitle')}</SMDrawer.Caption>
            <SMDrawer.Title>{bdb?.name || ''}</SMDrawer.Title>
          </div>

          <S.CloseButton
            data-testid="icon--close-connection-wizard-drawer"
            onClick={() => toggleDrawer(false)}
            fill="blue6"
            height={20}
            width={20}
            name={IconNamesEnum.CANCEL}
          />
        </S.DrawerHeader>

        <S.DrawerBody>
          <S.CollapseContainer
            testId="accordion-cli"
            className="connection-wizard-collapse"
            title={prefixedT('accordion.redisCli')}
            onToggled={() => openAccordion('redisCli')}
            open={openedAccordionId === 'redisCli'}
            headerIcon={IconNamesEnum.REDIS_CLI}
          >
            <ConnectionWizardCli connectionWizardCliCommand={cliCommand} />
          </S.CollapseContainer>

          <S.CollapseContainer
            testId="accordion-client"
            className="connection-wizard-collapse"
            title={prefixedT('accordion.redisClient')}
            onToggled={() => openAccordion('redisClient')}
            open={openedAccordionId === 'redisClient'}
            headerIcon={IconNamesEnum.REDIS_CLIENT}
          >
            <ConnectionWizardClient clients={clients} />
          </S.CollapseContainer>

          <S.CollapseContainer
            testId="accordion-redis-insight"
            className="connection-wizard-collapse"
            title={
              <>
                {prefixedT('accordion.redisInsight')}
                <Lottie options={defaultOptions} style={S.RecommendedAnimation} />
              </>
            }
            onToggled={() => openAccordion('redisInsight')}
            open={openedAccordionId === 'redisInsight'}
            headerIcon={IconNamesEnum.REDIS_INSIGHT}
          >
            <ConnectionWizardRedisInsight redisInsight={redisInsight} />
          </S.CollapseContainer>
        </S.DrawerBody>
      </>
    </SMDrawer>
  );
};

export default ConnectionWizard;
