import Card from 'components/Shared/Card/Card';
import { Body, Header } from 'components/Shared/Card/Card.style';
import styled, { css } from 'styled-components/macro';
import { Arrow } from '../CollapseArrow/CollapseArrow.style';

export const CollapseContainer = styled(Card)<{ open: boolean }>`
  ${({ open }) =>
    !open &&
    css`
      ${Body} {
        display: none;
      }
    `}

  &.connection-wizard-collapse {
    border: 1px solid ${({ theme }) => `${theme.colors.gray1}`};
    margin-bottom: 16px;

    ${Arrow} path {
      fill: ${({ theme }) => `${theme.colors.blue1}`};
    }

    ${Header} {
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;

      ${({ open }) =>
        open &&
        css`
          background: ${({ theme }) => `${theme.colors.lightGray1}`};
        `}
    }

    ${Body} {
      border-top: 1px solid ${({ theme }) => `${theme.colors.gray1}`};
    }

    & h3 {
      font-family: ${({ theme: { fontTypes } }) => fontTypes.regular};
      font-size: 1.4rem;
      color: ${({ theme }) => theme.colors.blue2};
      ${({ open }) =>
        open &&
        css`
          font-family: ${({ theme: { fontTypes } }) => fontTypes.bold};
        `}
    }
  }
`;
