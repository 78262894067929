import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { getDataTestId } from '../../../../utils';
import * as S from './ModalMessage.style';

interface ModalMessageProps {
  content: string | JSX.Element;
  testId?: string;
}

const ModalMessage: React.FC<ModalMessageProps> = ({ content, testId }) => {
  return (
    <S.Container {...getDataTestId('readonlylabel', testId)}>
      <S.SvgIcon name={IconNamesEnum.ATTENTION2} fill="white" width={19} />
      <div>{content}</div>
    </S.Container>
  );
};

export default ModalMessage;
