import { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../utils/constants/routes';
import { mfaPayloadSelector } from '../store/auth/auth.selectors';
import MfaLogin from './Login/components/MfaLogin/MfaLogin';

const Login = lazy(() => import('./Login/Login'));
const SignUp = lazy(() => import('./Login/SignUp/SignUp'));
const SsoLogin = lazy(() => import('./Login/SsoLogin/SsoLogin'));
const UserActivation = lazy(() => import('./Login/UserActivation/UserActivation'));
const ResetPasswordCallback = lazy(() => import('./Login/ResetPassword/ResetPasswordCallback'));
const ResetPassword = lazy(() => import('./Login/ResetPassword/ResetPassword'));
const ForgotPassword = lazy(() => import('./Login/ForgotPassword/ForgotPassword'));

const AuthRoutes = () => {
  const mfaParams = useSelector(mfaPayloadSelector);

  if (mfaParams) {
    const isBothMfaMethodsExists = mfaParams.smsFactorAvailable && mfaParams.totpFactorAvailable;

    return <MfaLogin isBothMfaMethodsExists={isBothMfaMethodsExists} />;
  }

  return (
    <Switch>
      <Route exact path={routes.login} component={Login} />
      <Route exact path={routes.signUp} component={SignUp} />
      <Route exact path={routes.saml} component={SsoLogin} />
      <Route exact path={routes.activateRegistration} component={UserActivation} />
      <Route exact path={routes.activateResetPassword} component={ResetPasswordCallback} />
      <Route exact path={routes.resetPassword} component={ResetPassword} />
      <Route exact path={routes.forgotPassword} component={ForgotPassword} />

      {/* This helps for not changing the current route when the user refreshes the page or is not logged in */}
      <Route component={Login} />
    </Switch>
  );
};

export default AuthRoutes;
