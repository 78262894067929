import RedisApiAxiosInstance from '../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { SubscriptionsPaymentData } from './paymentMethods.types';

export const getPaymentsInfo = () => RedisApiAxiosInstance.get('/paymentInfos');

export const deletePaymentInfo = (id: number) =>
  RedisApiAxiosInstance.delete(`/paymentInfos/${id}`);

export const addSubsToPaymentInfo = ({ pmID, subIDs }: SubscriptionsPaymentData) =>
  RedisApiAxiosInstance.post(`/paymentInfos/${pmID}/actions/update-subs/`, subIDs);
