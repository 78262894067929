import { SubscriptionPostBody } from '../../../../store/createSubscription/createSubscription.types';
import { createResourceApi } from '../../createResourceApi';

export const subscriptionsApi = createResourceApi('/subscriptions', (rest) => ({
  getAll: (params: GetSubscriptionParams) =>
    rest.get<{ subscriptions: Subscription[] }>({ config: { params } }),

  getOne: (id: number) => rest.get<{ subscription: Subscription }>({ path: `${id}` }),

  update: (subscription: Subscription) =>
    rest.put<{ subscription: Subscription }>({
      path: `${subscription.id}`,
      data: { subscription }
    }),

  create: (body: SubscriptionPostBody) => rest.post<{ subscription: Subscription }>({ data: body }),

  remove: ({ subscriptionId, params }: DeleteSubscriptionRequestPayload) =>
    rest.delete({ path: `${subscriptionId}`, config: { params } }),

  activate: ({
    subscription,
    activationKey
  }: {
    subscription: Subscription;
    activationKey: string;
  }) =>
    rest.post<{ subscription: Subscription }>({
      path: `${subscription.id}/actions/activate?key=${activationKey}`,
      data: { subscription },
      config: { params: { t: new Date().getTime() } }
    })
}));
