import { getAbPercentageSection } from 'utils/abTest/abTest';
import { routes } from 'utils/constants/routes';

export const getCreateSubscriptionPlanPath = (
  accountId: number,
  abPercentages: number[]
): string => {
  const subscriptionsPlansRoutes = [
    routes.createSubscription.essential,
    routes.createSubscription.pro.create
  ];

  const index = getAbPercentageSection(accountId, abPercentages);

  return subscriptionsPlansRoutes[index];
};
