import styled from 'styled-components/macro';
import {
  Subtitle,
  Content,
  CancelIcon,
  HeaderWrapper,
  Footer
} from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog/ConfirmationDialog.style';
import { ConfirmationDialog as ConfirmationDialogComponent } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';
import { TextArea as TextAreaComponent } from '@redislabsdev/redislabs-ui-components';
import { RadioButtonGroup as RadioButtonGroupComponent } from '../../../../../components';

export const modalOverlayStyle = {
  zIndex: '8'
};

export const modalContentStyle = {
  padding: '5.6rem',
  paddingRight: '5.6rem',
  width: '53rem',
  backgroundColor: '#FFFFFF'
};

export const ConfirmationDialog = styled(ConfirmationDialogComponent)`
  ${CancelIcon} {
    display: none;
  }

  ${Subtitle} {
    margin-top: 2.4rem;
  }

  ${Content} {
    margin: 0;
    padding: 0;
  }

  ${HeaderWrapper} {
    padding-right: 0;
  }

  ${Footer} {
    margin-top: 3.6rem;
    padding-right: 0;
  }
`;

export const ContentContaier = styled.div`
  min-height: 20rem;
`;

export const LoaderContaier = styled.div`
  display: flex;
  width: 100%;
  height: 20rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FeedbacksContaier = styled.div`
  margin-top: 1.2rem;
  display: flex;
  flex-direction: column;
`;

export const RadioButtonGroup = styled(RadioButtonGroupComponent)`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  user-select: none;

  & > *:not(:first-of-type) {
    padding-left: 0rem;
  }

  & label {
    width: 100%;
    margin-left: 0;
    padding-left: 0.4rem;
  }

  & > * {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-radius: 10rem;
  }

  & input {
    flex-shrink: 0;
  }
`;

export const CommentsContainer = styled.div`
  margin-top: 1.2rem;
`;

export const TextArea = styled(TextAreaComponent)`
  height: 9.6rem;
`;
