import { IndicatorErrorIcon } from '@redislabsdev/redis-ui-icons';
import { newTheme } from '@redislabsdev/redis-ui-styles';
import { Button } from '@redislabsdev/redislabs-ui-components';
import { FallbackRender } from '@sentry/react';
import { i18translation } from 'locale/translations/i18NsPaths';
import { useTranslation } from 'react-i18next';
import * as S from './TopBarErrorBoundary.style';

const TopBarErrorBoundary: FallbackRender = ({ resetError }) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.topBarErrorBoundary;

  return (
    <S.ErrorFallbackContainer>
      <S.ErrorInformationContainer>
        <IndicatorErrorIcon color={newTheme.semantic.color.text.danger500} />
        <S.ErrorInformationText>{t(keyPrefix.informationText)}</S.ErrorInformationText>
      </S.ErrorInformationContainer>
      <Button onClick={resetError} size="medium">
        {t(keyPrefix.refreshButton)}
      </Button>
    </S.ErrorFallbackContainer>
  );
};

export default TopBarErrorBoundary;
