import styled, { css } from 'styled-components/macro';

export const CardsContainer = styled.div<{ providersLen: number }>`
  display: flex;

  ${({ providersLen }) =>
    providersLen > 2
      ? css`
          justify-content: space-between;
        `
      : css`
          & > * {
            margin-right: 2rem;
          }
        `};
`;
