import { FC } from 'react';
import { UseComboboxStateChange } from 'downshift';
import { DropdownMenuProps } from '@redislabsdev/redislabs-ui-components/ui/components/DropdownMenu';
import * as S from './RegionSelect.style';
import { usePrefixedTranslation } from '../../hooks';

interface IProps {
  onRegionSelect: (region: CloudRegion) => void;
  selectedRegion: CloudRegion;
  allRegions: CloudRegion[];
  dropdownProps?: Partial<DropdownMenuProps<string>>;
  withTitle?: boolean;
}

const RegionSelect: FC<IProps> = ({
  selectedRegion,
  onRegionSelect,
  allRegions,
  dropdownProps,
  withTitle = true
}) => {
  const { prefixedT } = usePrefixedTranslation('createSubscription.cloudVendor');

  const handleRegionSelect = (option: UseComboboxStateChange<CloudRegion>) => {
    onRegionSelect(option.selectedItem);
  };

  const handleRegionFilter = (items: CloudRegion[], searchValue: string) => {
    if (!searchValue) return items;

    return items.filter((item) => {
      return [item.name, item.city_name, item.country_name]
        .join(' ')
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    });
  };

  return (
    <S.RegionSelectContainer data-testid="single-region-select">
      {withTitle && <S.Title>{prefixedT('selectRegion')}</S.Title>}
      <S.DropdownMenu
        items={allRegions}
        renderItem={(region: CloudRegion) => <S.RegionDisplayField region={region?.name} />}
        selectedItem={selectedRegion}
        onSelectedItemChange={handleRegionSelect}
        dataTestIdSuffix="region"
        toggleButtonId="selection-area-button--region-select"
        filterFunction={handleRegionFilter}
        isSearchable
        {...dropdownProps}
      />
    </S.RegionSelectContainer>
  );
};

export default RegionSelect;
