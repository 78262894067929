import styled from 'styled-components/macro';
import { IconTextColorMap } from './EnvBanner.constants';
import { IconName } from './EnvBanner.types';

export const AnchorLink = styled.a`
  display: flex;
  align-items: center;
`;

export const HrefText = styled.span<{
  iconName: IconName;
  shouldUseWhiteColor?: boolean;
}>`
  margin-inline-start: 1rem;
  text-decoration: underline;
  color: ${({ iconName, shouldUseWhiteColor, theme: { colors } }) =>
    shouldUseWhiteColor ? colors.white : colors[IconTextColorMap[iconName]]};
`;

export const IconContainer = styled.span<{ shouldUseWhiteColor?: boolean }>`
  && * {
    ${({ shouldUseWhiteColor, theme: { colors } }) =>
      shouldUseWhiteColor && `fill: ${colors.white}`}
  }
`;

export const VerticalLine = styled.div`
  height: 1.3rem;
  width: 0.1rem;
  margin: 0 1.5rem;
  background-color: ${({ theme: { colors } }) => colors.white};
`;
