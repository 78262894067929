import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { showToast } from '@redislabsdev/redislabs-ui-components/ui/components/Toast';
import * as calls from './credits.calls';
import * as actions from './credits.action';
import { ApplyCouponRequestAction, CouponsSuccessAnswer, CreditsTypesEnum } from './credits.types';
import { extractErrorAndShowToast } from '../../utils/helpers/extractErrorAndShowToast';
import i18n from '../../locale/i18n';

function* getCoupons() {
  try {
    const {
      data: { coupons, couponsBalance, couponsTotal, couponsUsedAmount }
    }: AxiosResponse<CouponsSuccessAnswer> = yield call(calls.getCoupons);

    yield put(
      actions.getCouponsSuccess({ coupons, couponsBalance, couponsTotal, couponsUsedAmount })
    );
  } catch (e) {
    if (e.response) {
      const { data } = e.response;

      yield put(actions.getCouponsFailure(data));
      extractErrorAndShowToast(e);
    }
  }
}

function* applyCoupon(action: ApplyCouponRequestAction) {
  try {
    yield call(calls.applyCoupon, action.payload);
    yield getCoupons();
    showToast(i18n.t('billingAndPayments.tabs.credits.success'), 'success');
  } catch (e) {
    if (e.response) {
      const { data } = e.response;
      showToast(i18n.t(`billingAndPayments.tabs.credits.errors.${data.errors.code}`), 'error');
    }
  }
}

function* creditsSaga() {
  yield takeLatest(CreditsTypesEnum.GET_COUPONS_REQUEST, getCoupons);
  yield takeLatest(CreditsTypesEnum.APPLY_COUPON_REQUEST, applyCoupon);
}

export default creditsSaga;
