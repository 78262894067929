import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useRegions from '../../../hooks/useRegions';
import { bdbsSelector } from '../../../store/databases/databases.selectors';
import {
  bdbSelector,
  crdbSelector
} from '../../../store/connectionWizard/connectionWizard.selectors';
import { setConnectionWizardBdb } from '../../../store/connectionWizard/connectionWizard.action';
import { EndpointType } from '../components/EndpointSelection/endpointSelection.types';

export function useConnectionWizardData(sectionName: string) {
  const dispatch = useDispatch();
  const { allRegions } = useRegions();

  const bdb = useSelector(bdbSelector);
  const bdbs = useSelector(bdbsSelector);
  const crdb = useSelector(crdbSelector);

  const [endpoint, setEndpoint] = useState('');
  const [selectedRegion, setSelectedRegion] = useState<CloudRegion>();
  const [availableRegions, setAvailableRegions] = useState<CloudRegion[]>([]);
  const [radioButtonChecked, setRadioButtonChecked] = useState(
    `${sectionName}-${EndpointType.PRIVATE_ENDPOINT}`
  );

  const handleOnEndpointChange = (id: EndpointType) => {
    setEndpoint(
      id === EndpointType.PRIVATE_ENDPOINT ? bdb.dns_master_private : bdb.dns_master_public
    );
    setRadioButtonChecked(`${sectionName}-${id}`);
  };

  const handleRegionSelect = (region: CloudRegion) => {
    const newBdbSelected = crdb.crdb_instances.find(
      (instance) => instance.region_id === region.region_id
    );
    dispatch(setConnectionWizardBdb(bdbs[newBdbSelected.bdb_id]));
    setSelectedRegion(region);
    handleOnEndpointChange(EndpointType.PRIVATE_ENDPOINT);
  };

  useEffect(() => {
    if (bdb) {
      setEndpoint(bdb.dns_master_private || bdb.dns_master_public);
      if (bdb.dns_master_private) {
        handleOnEndpointChange(EndpointType.PRIVATE_ENDPOINT);
      } else {
        handleOnEndpointChange(EndpointType.PUBLIC_ENDPOINT);
      }
    }
    if (crdb) {
      const regionIds = crdb.crdb_instances.map((instance) => instance.region_id);
      const regions = allRegions.filter((region) => regionIds.includes(region.region_id));
      if (regions.length > 0) {
        const relatedCrdbInstanceToBdb = crdb.crdb_instances.find(
          (instance) => instance.bdb_id === bdb.id
        );
        setAvailableRegions(regions);
        setSelectedRegion(
          allRegions.find((region) => region.region_id === relatedCrdbInstanceToBdb?.region_id) ||
            regions[0]
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRegions, bdb, crdb, selectedRegion, setEndpoint]);

  return {
    availableRegions,
    bdb,
    crdb,
    endpoint,
    handleOnEndpointChange,
    handleRegionSelect,
    selectedRegion,
    radioButtonChecked,
    setRadioButtonChecked
  };
}
