import create from 'zustand';

interface DeleteSubscriptionStore {
  deletedSubscriptionId: number;
  isLastPaindSubscriptionDeleted: boolean;
  setDeletedSubscription: (deletedSubscriptionId, isLastPaindSubscriptionDeleted) => void;
  reset: () => void;
}

const initialState = {
  deletedSubscriptionId: -1,
  isLastPaindSubscriptionDeleted: false
};

export const useDeleteSubscriptionStore = create<DeleteSubscriptionStore>()((set) => ({
  ...initialState,
  setDeletedSubscription: (deletedSubscriptionId, isLastPaindSubscriptionDeleted) =>
    set(() => ({ deletedSubscriptionId, isLastPaindSubscriptionDeleted })),
  reset: () => set(() => ({ ...initialState }))
}));
