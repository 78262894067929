import { useSelector } from 'react-redux';
import { accountIdSelector } from '../store/auth/auth.selectors';
import { parametersQueries } from '../queryClient/parameters/parameters.queries';

export function useConnectionWizardEnabled(): boolean {
  const accountId = useSelector(accountIdSelector);
  const { systemParams } = parametersQueries.useSystemParameters();

  const checkAccountHasAccess = systemParams?.connection_wizard_enabled_accounts
    ?.toString()
    ?.replace(/\s/g, '')
    ?.split(',')
    ?.includes(accountId);

  const doesIncludeEnabledAccounts = Object.keys(systemParams || {}).includes(
    'connection_wizard_enabled_accounts'
  );

  return (
    systemParams?.connection_wizard_enabled &&
    (!doesIncludeEnabledAccounts || checkAccountHasAccess)
  );
}
