import { keyBy } from 'lodash';
import findRegionMinimalPrice, { FindRegionMinimalPriceProps } from './findRegionMinimalPrice';
import { ShardTypePricing } from '../../store/databases/databases.types';

interface GetCalculatorTotalPriceProps extends Omit<FindRegionMinimalPriceProps, 'regionName'> {
  shardTypePricings: ShardTypePricing[];
  isExternal: boolean;
  databases?: { name: string; quantity: number }[];
}

type PriceByRegionObj = { [regionName: string]: number };

const getCalculatorTotalPrice = (props: GetCalculatorTotalPriceProps) => {
  const { shardTypePricings, isExternal, databases = [] } = props;

  const dbsNamedObject = keyBy(databases, 'name');

  const priceByRegionsObj = {} as PriceByRegionObj;

  shardTypePricings.forEach((shardTypePricing) => {
    shardTypePricing.regions.forEach((region) => {
      const quantity = dbsNamedObject[shardTypePricing.name]?.quantity || 1;
      const currentRegionPrice = quantity * region.hourly_shard_price;

      if (priceByRegionsObj[region.name]) {
        priceByRegionsObj[region.name] += currentRegionPrice;
      } else {
        priceByRegionsObj[region.name] = currentRegionPrice;
      }
    });
  });
  const subscriptionHourlyPrice = Object.entries(priceByRegionsObj).reduce(
    (acc, [regionName, totalRegionPrice]) => {
      if (isExternal) {
        return acc + totalRegionPrice;
      }
      const regionMinimalPrice = findRegionMinimalPrice({ ...props, regionName });

      return acc + Math.max(totalRegionPrice, regionMinimalPrice);
    },
    0
  );

  return parseFloat(subscriptionHourlyPrice.toFixed(3));
};

export default getCalculatorTotalPrice;
