/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect } from 'react';
import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './UserMenu.style';
import { getAccountsRequest } from '../../../../store/account/account.actions';
import UserMenuContent from './components/UserMenuContent/UserMenuContent';
import {
  authReducerSelector,
  shouldNotifyProductionForAgentSelector
} from '../../../../store/auth/auth.selectors';
import useAccount from '../../../../hooks/useAccount';

const UserMenu = () => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = () => setIsMenuOpen(false);
  const { current_account_id, name } = useSelector(authReducerSelector);
  const shouldNotifyProductionForAgent = useSelector(shouldNotifyProductionForAgentSelector);
  const { account } = useAccount();

  useEffect(() => {
    if (current_account_id) {
      dispatch(getAccountsRequest());
    }
  }, [dispatch, current_account_id]);

  return (
    <S.UserMenuContainer>
      <S.UserMenuTooltip
        tooltipContent={isMenuOpen ? <UserMenuContent closeMenu={closeMenu} /> : ''}
        zIndex={6}
        trigger="click"
        padding="0"
        displayOnContent
        onVisibilityChange={(isShown) => setIsMenuOpen(isShown)}
        placement="bottom"
        offset={[-50, 10]}
        maxWidth="32rem"
      >
        <S.MenuContainer
          data-testid="button--toggle-account-menu"
          shouldNotifyProductionForAgent={shouldNotifyProductionForAgent}
        >
          <S.UserName
            data-testid="readonlyvalue--current-account-name"
            shouldNotifyProductionForAgent={shouldNotifyProductionForAgent}
          >
            {name}
          </S.UserName>
          <S.NameAndArrowContainer shouldNotifyProductionForAgent={shouldNotifyProductionForAgent}>
            <S.AccountName data-testid="readonlyvalue--account-name">{account?.name}</S.AccountName>
            <S.ArrowIcon $isMenuOpen={isMenuOpen} name={IconNamesEnum.ARROW_DOWN} width={17} />
          </S.NameAndArrowContainer>
        </S.MenuContainer>
      </S.UserMenuTooltip>
    </S.UserMenuContainer>
  );
};

export default UserMenu;
