import * as Sentry from '@sentry/react';
import sentryTags from '../../../sentry/constants/sentryTags.constants';

export const handleUnsupportedClient = (error: unknown) => {
  // We need this interval since we try to init the Broadcast before Sentry was initialized.
  const interval = setInterval(() => {
    if (Sentry.getCurrentHub().getClient()) {
      Sentry.withScope((scope) => {
        scope.setFingerprint(['Unsupported broadcastChannel API']);
        scope.setTag(sentryTags.broadcastApiNotSupported, 'true');
        Sentry.captureException(error);
        clearInterval(interval);
      });
    }
  }, 2000);
};
