import { SetProductTypeFailureAction, SetProductTypeSuccessAction } from '../account/account.types';

export enum AuthActionTypesEnum {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  SET_LOGIN_ERROR_CODE = 'SET_LOGIN_ERROR_CODE',
  MAKE_CSRF_CALL = 'MAKE_CSRF_CALL',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  SESSION_EXPIRED = 'SESSION_EXPIRED',

  UPDATE_USER_NAME = 'UPDATE_USER_NAME',
  FILTER_PERMISSIONS = 'FILTER_PERMISSIONS',
  UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS',

  SET_CURRENT_USER = 'SET_CURRENT_USER',

  MFA_LOGIN = 'MFA_LOGIN',
  MFA_CLEAR = 'MFA_CLEAR'
}

export type AccessPermissions =
  | 'bdb_get_bdb'
  | 'bdb_get_bdbs'
  | 'bdb_create'
  | 'bdb_update'
  | 'bdb_delete'
  | 'bdb_validate_security_groups'
  | 'bdb_validate_source_ips'
  | 'bdb_validate_regex_rules'
  | 'bdb_test_regex_keys'
  | 'bdb_validate_sync_sourses'
  | 'bdb_reset_import'
  | 'bdb_validate_import_path'
  | 'bdb_validate_backup_path'
  | 'bdb_import'
  | 'bdb_backup'
  | 'bdb_get_backups'
  | 'bdb_read_info_stats'
  | 'bdb_reset_info_stats'
  | 'bdb_flush'
  | 'bdb_metric'
  | 'bdb_slowlog_read'
  | 'bdb_slowlog_reset'
  | 'billing_get_paynow_url'
  | 'can_access_secret_data'
  | 'subscription_create'
  | 'subscription_create_essentials'
  | 'subscription_update'
  | 'subscription_delete'
  | 'subscription_view_pricing'
  | 'subscription_get_subscription'
  | 'subscription_get_subscriptions'
  | 'subscription_get_plans'
  | 'subscription_get_change_plans'
  | 'subscription_get_cluster'
  | 'session_log_get'
  | 'account_get_accounts'
  | 'account_get_account'
  | 'account_set_account'
  | 'account_set_product'
  | 'account_create'
  | 'account_zone_mapping'
  | 'account_update'
  | 'account_call_helpdesk'
  | 'account_cam_get_rlec_links'
  | 'account_cam_download_rlec'
  | 'account_ssl_ca_get'
  | 'account_viewer'
  | 'user_get_users'
  | 'user_get_user'
  | 'user_invite'
  | 'user_remove'
  | 'user_update'
  | 'usage_report'
  | 'system_parameter_get'
  | 'system_log_get'
  | 'system_log_export'
  | 'plan_get_cloud_regions'
  | 'plan_get_cloud_providers'
  | 'plan_get_plans'
  | 'paymentinfo_get_payment'
  | 'paymentinfo_get_all'
  | 'paymentinfo_delete'
  | 'paymentinfo_update'
  | 'paymentinfo_create'
  | 'paymentinfo_validate_vatid'
  | 'paymentinfo_get_countries'
  | 'billing_get_billings'
  | 'billing_get_billing'
  | 'billing_get_invoice'
  | 'coupon_get_coupons'
  | 'rcp_plan'
  | 'rcp_update'
  | 'rcp_update_psc'
  | 'rcp_provision'
  | 'rcp_delete'
  | 'rcp_get_rcp'
  | 'rcp_get_rcps'
  | 'rcp_get_rcp_modules'
  | 'rcp_get_psc'
  | 'rcp_get_allowed_instance_types'
  | 'rcp_update_aws_credentials'
  | 'cloud_account_get_account'
  | 'cloud_account_get_accounts'
  | 'cloud_account_delete'
  | 'cloud_account_update'
  | 'cloud_account_create'
  | 'validate_permissions'
  | 'cloud_account_get_availability_zones'
  | 'manage_acl'
  | 'view_acl'
  | 'change_request_get'
  | 'change_request_create'
  | 'create_change_execute'
  | 'crdb_get'
  | 'crdb_flush'
  | 'crdb_import'
  | 'crdb_reset_import'
  | 'gcp_iam_check_role'
  | 'saml_read'
  | 'saml_create'
  | 'saml_update'
  | 'saml_link_accounts'
  | 'ui_can_access_billing_section'
  | 'ui_can_access_subscriptions'
  | 'ui_can_access_plan_selection_page'
  | 'ui_can_access_team_page'
  | 'ui_can_access_cloud_api_page'
  | 'ui_can_add_rcp_plan_metadata'
  | 'ui_mfa_enforcement'
  | 'cloud_api_permission'
  | 'update_crdb_compression'
  | 'TEMP_can_access_saml_single_sign_on_tab'
  | 'TEMP_can_access_cloud_accounts_tab'
  | 'TEMP_manage_passwords'
  | 'TEMP_account_mfa_enforcement'
  | 'TEMP_user_account_mfa'
  | 'TEMP_can_update_email'
  | 'TEMP_account_time_zone'
  | 'TEMP_account_address';

export interface LoginRequestAction {
  type: AuthActionTypesEnum.LOGIN_REQUEST;
  payload: LoginPayload;
}

export interface SetCurrentUserAction {
  type: AuthActionTypesEnum.SET_CURRENT_USER;
  payload: ApiAuthResponse;
}

export interface LoginSuccessAction {
  type: AuthActionTypesEnum.LOGIN_SUCCESS;
  payload: ApiAuthResponseNormalizedPermissions;
}

export interface SetLoginErrorCodeAction {
  type: AuthActionTypesEnum.SET_LOGIN_ERROR_CODE;
  payload: number;
}

export interface MfaLoginAction {
  type: AuthActionTypesEnum.MFA_LOGIN;
  payload: MfaPayload & LoginPayloadWithCredentials;
}

interface ClearMfa {
  type: AuthActionTypesEnum.MFA_CLEAR;
}

export interface LoginPayloadWithCredentials {
  username: string;
  password: string;
  referer?: string;
  origin?: string;
  auth_mode?: string;
  callCsrfFlag?: boolean;
  migrateToSamlAgreement?: boolean;
}

interface LoginPayloadWithoutCredentials {
  referer?: string;
  username?: undefined;
  password?: undefined;
  origin?: string;
  auth_mode?: string;
  callCsrfFlag?: boolean;
  migrateToSamlAgreement?: boolean;
}

interface LoginPayloadWithSocialLoginMigration {
  migrateToSocialLoginAgreement: boolean;
  referer?: string;
  username?: undefined;
  password?: undefined;
  origin?: string;
  auth_mode?: string;
  callCsrfFlag?: boolean;
  migrateToSamlAgreement?: boolean;
}

export type MfaTypes = 'SMS' | 'Totp';

interface LoginPayloadWithMfa {
  aws_customer_identifier?: null;
  aws_product_code?: null;
  download?: null;
  mfa_code?: string;
  mfa_type?: MfaTypes;
  product?: null;
  referer?: string;
  return_to?: null;
  username?: string;
  utm_campaign?: null;
  utm_content?: null;
  utm_medium?: null;
  utm_source?: null;
  utm_term?: null;
  origin?: string;
  migrateToSocialLoginAgreement?: boolean | string;
  auth_mode?: string;
  callCsrfFlag?: boolean;
  migrateToSamlAgreement?: boolean | string;
}

export type LoginPayload =
  | LoginPayloadWithCredentials
  | LoginPayloadWithoutCredentials
  | LoginPayloadWithSocialLoginMigration
  | LoginPayloadWithMfa;

type LogoutPayload = Record<string, string>;

export interface LogoutActionRequest {
  type: AuthActionTypesEnum.LOGOUT_REQUEST;
  payload?: LogoutPayload;
}

export interface LogoutSuccessAction {
  type: AuthActionTypesEnum.LOGOUT_SUCCESS;
}

export interface SessionExpiredAction {
  type: AuthActionTypesEnum.SESSION_EXPIRED;
}

export interface MakeCsrfCallAction {
  type: AuthActionTypesEnum.MAKE_CSRF_CALL;
  payload: ApiAuthResponseNormalizedPermissions;
}

export interface FilterPermissions {
  type: AuthActionTypesEnum.FILTER_PERMISSIONS;
  payload: (permissions: PermissionsDict) => PermissionsDict;
}

export interface UpdatePermissions {
  type: AuthActionTypesEnum.UPDATE_PERMISSIONS;
  payload: AccessPermissions[];
}

export type AuthActionTypes =
  | LogoutActionRequest
  | LogoutSuccessAction
  | SessionExpiredAction
  | MakeCsrfCallAction
  | LoginSuccessAction
  | SetLoginErrorCodeAction
  | UpdateUserNameAction
  | FilterPermissions
  | UpdatePermissions
  | LoginRequestAction
  | SetProductTypeSuccessAction
  | SetProductTypeFailureAction
  | MfaLoginAction
  | ClearMfa;

export type AgentType = 'super_agent' | 'support_agent';

export interface ApiAuthResponse {
  name: string;
  production: boolean;
  id: string;
  current_account_id: string;
  email: string;
  product_type: ProductType;
  user_id: number;
  is_new_ui_access?: boolean;
  firstSocialLogin?: boolean;
  role: UserPermissionType;
  permissions: AccessPermissions[];
  agent_type?: AgentType;
  url?: string;
}

export type PermissionsDict = Record<Partial<AccessPermissions>, Partial<AccessPermissions>>;

export interface ApiAuthResponseNormalizedPermissions extends Omit<ApiAuthResponse, 'permissions'> {
  permissions: PermissionsDict;
}

export interface AuthUserState extends ApiAuthResponseNormalizedPermissions {
  loggedIn: boolean;
  loginErrorCode: number;
  mfaPayload?: MfaPayload & LoginPayloadWithCredentials;
  loginStatus: ReducerStateStatus;
  callCsrfFlag?: boolean;
  callCsrfPayload?: ApiAuthResponseNormalizedPermissions;
}

export interface UpdateUserNameAction {
  type: AuthActionTypesEnum.UPDATE_USER_NAME;
  payload: string;
}

export interface MfaPayload {
  phoneNumber: string;
  smsFactorAvailable: boolean;
  totpFactorAvailable: boolean;
}
