import * as S from './LoginRelatedComponents.style';

import emailPatternBg from '../../assets/icons/EmailPatternBg.svg';
import usePrefixedTranslation from '../../hooks/usePrefixedTranslation';
import RedisLogo from './RedisLogo';

interface PasswordResetExpiredProps {
  showActivation?: boolean;
}

const PasswordResetExpired: React.FC<PasswordResetExpiredProps> = ({ showActivation }) => {
  const { prefixedT } = usePrefixedTranslation('LoginScreen.resetPasswordScreen');

  return (
    <S.ThanksForSignUpContainer>
      <img src={emailPatternBg} alt="background with starts" />

      <RedisLogo />

      <S.ThanksForSignUpTitle>
        {showActivation ? prefixedT('setPassLinkExpired') : prefixedT('passResetLinkExpired')}
      </S.ThanksForSignUpTitle>
    </S.ThanksForSignUpContainer>
  );
};

export default PasswordResetExpired;
