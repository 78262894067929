import { useHistory } from 'react-router-dom';
import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { useSelector } from 'react-redux';
import * as S from './UserMenuContent.style';
import SwitchAccount from './components/SwitchAccount/SwitchAccount';
import { getDataTestId } from '../../../../../../utils';
import usePrefixedTranslation from '../../../../../../hooks/usePrefixedTranslation';
import useLogOut from '../../../../../../hooks/useLogOut';
import { authReducerSelector } from '../../../../../../store/auth/auth.selectors';

interface Props {
  closeMenu(): void;
}

const UserMenuContent = ({ closeMenu }: Props) => {
  const { prefixedT } = usePrefixedTranslation('navBar.userMenu');
  const history = useHistory();
  const { performLogout } = useLogOut();
  const { name } = useSelector(authReducerSelector);

  return (
    <S.Container>
      <SwitchAccount closeMenu={closeMenu} />
      <ul>
        <S.MenuActionItem
          data-role="navitem"
          onClick={() => {
            closeMenu();
            history.push('/profile');
          }}
          {...getDataTestId('button', 'user-profile')}
        >
          {prefixedT('userProfile')}
          <S.UserName>{name}</S.UserName>

          <S.EditProfileIcon name={IconNamesEnum.EDIT} height={18} />
        </S.MenuActionItem>
        <S.MenuActionItem
          data-role="navitem"
          onClick={() => {
            closeMenu();
            performLogout();
          }}
          {...getDataTestId('button', 'log-out')}
        >
          {prefixedT('logout')}
          <S.SignOutIcon name={IconNamesEnum.SIGN_OUT} height={18} />
        </S.MenuActionItem>
      </ul>
    </S.Container>
  );
};

export default UserMenuContent;
