import { useState } from 'react';
import RedisBackground from '../RedisBackground/RedisBackground';
import AppAuthModal from './components/AppAuthModal/AppAuthModal';
import SmsLoginModal from './components/SmsLoginModal/SmsLoginModal';

type MfaModalTypes = 'smsFactor' | 'totpFactor';

interface Props {
  isBothMfaMethodsExists: boolean;
}

const MfaLogin = ({ isBothMfaMethodsExists }: Props) => {
  const [modalToDisplay, setModalToDisplay] = useState<MfaModalTypes>(
    isBothMfaMethodsExists ? 'totpFactor' : 'smsFactor'
  );

  const switchToTotFactor = () => setModalToDisplay('totpFactor');
  const switchToSmsFactor = () => setModalToDisplay('smsFactor');

  const Modals: Record<MfaModalTypes, JSX.Element> = {
    smsFactor: <SmsLoginModal switchToAuthAppModal={switchToTotFactor} />,
    totpFactor: <AppAuthModal switchToSmsModal={switchToSmsFactor} />
  };

  return <RedisBackground>{Modals[modalToDisplay]}</RedisBackground>;
};

export default MfaLogin;
