import { PartialWithId } from '../../types/interfaces';
import {
  BackupDbFailureAction,
  BackupDbRequestAction,
  BackupDbSuccessAction,
  CreateUpdateDatabaseRequestAction,
  DatabasesActionTypes,
  DeleteDatabaseSuccessAction,
  GetBdbSyncSourcesFailureAction,
  GetBdbSyncSourcesRequestAction,
  GetBdbSyncSourcesSuccessAction,
  GetDatabasesFailureAction,
  GetDatabasesRequestAction,
  GetDatabasesSuccessAction,
  ImportDbFailureAction,
  ImportDbRequestAction,
  ImportDbSuccessAction,
  RefreshDbSuccessAction,
  ResetBdbValidationStateAction,
  ResetImportDbStateAction,
  StartDatabasesPollingRequestAction,
  ValidateBdbSyncSourcesFailureAction,
  ValidateBdbSyncSourcesRequestAction,
  ValidateBdbSyncSourcesRequestActionPayload,
  ValidateBdbSyncSourcesSuccessAction,
  UserUpdateSubsPriceConfirmationAction,
  CreateUpdateDatabaseRequestPayload,
  SetNewPriceDataAction,
  NewPriceData,
  SetNewDbRcpPlanAction,
  CancelCreateUpdateDbAction,
  UpdateDbRequestAction,
  UpdateDbPayload,
  SetShouldShowClusteringDialogAction,
  UserClusteringConfirmationAction,
  SetDBtablePageSizeAction,
  UpdateDbSuccessAction,
  CreateDbSuccessAction,
  BdbById,
  SetShowRedisStackCongratulationsAction
} from './databases.types';

export const startDatabasesPolling = (): StartDatabasesPollingRequestAction => ({
  type: DatabasesActionTypes.START_DATABASES_POLLING
});

export const getDatabases = (): GetDatabasesRequestAction => ({
  type: DatabasesActionTypes.GET_DATABASES_REQUEST
});

export const getDatabasesSuccess = (data: BdbById): GetDatabasesSuccessAction => ({
  type: DatabasesActionTypes.GET_DATABASES_SUCCESS,
  payload: data
});

export const getDatabasesFailure = (error: string): GetDatabasesFailureAction => ({
  type: DatabasesActionTypes.GET_DATABASES_FAILURE,
  payload: error
});

export const createUpdateDBRequest = (
  payload: CreateUpdateDatabaseRequestPayload
): CreateUpdateDatabaseRequestAction => ({
  type: DatabasesActionTypes.CREATE_UPDATE_DB_REQUEST,
  payload
});

export const UpdateDBSuccessAction = (payload: Bdb): UpdateDbSuccessAction => ({
  type: DatabasesActionTypes.UPDATE_DB_SUCCESS,
  payload
});

export const CreateDBSuccessAction = (payload: Bdb): CreateDbSuccessAction => ({
  type: DatabasesActionTypes.CREATE_DB_SUCCESS,
  payload
});

export const updateDBRequest = (payload: UpdateDbPayload): UpdateDbRequestAction => ({
  type: DatabasesActionTypes.UPDATE_DB_REQUEST,
  payload
});

export const userUpdateSubsPriceConfirmation = (
  confirmed: boolean
): UserUpdateSubsPriceConfirmationAction => ({
  type: DatabasesActionTypes.USER_UPDATE_SUBS_PRICE_CONFIRMATION,
  payload: confirmed
});

export const setNewPriceData = (payload: NewPriceData): SetNewPriceDataAction => ({
  type: DatabasesActionTypes.SET_NEW_PRICE_DATA,
  payload
});

export const setNewDbRcpPlan = (payload: RcpPlanStatusResponse): SetNewDbRcpPlanAction => ({
  type: DatabasesActionTypes.SET_NEW_DB_RCP_PLAN,
  payload
});

export const setShouldShowClusteringDialog = (): SetShouldShowClusteringDialogAction => ({
  type: DatabasesActionTypes.SET_SHOULD_SHOW_CLUSTERING_DIALOG
});

export const clusteringConfirmation = (): UserClusteringConfirmationAction => ({
  type: DatabasesActionTypes.USER_CLUSTERING_CONFIRMATION
});

export const cancelCreateUpdateDb = (): CancelCreateUpdateDbAction => ({
  type: DatabasesActionTypes.CANCEL_CREATE_UPDATE_DB
});

export const deleteDBSuccess = (deletedBdb: Bdb): DeleteDatabaseSuccessAction => ({
  type: DatabasesActionTypes.DELETE_DB_SUCCESS,
  payload: deletedBdb
});

export const getBdbSyncSourcesRequest = (
  cloudProvider: string
): GetBdbSyncSourcesRequestAction => ({
  type: DatabasesActionTypes.GET_BDB_SYNC_SOURCES_REQUEST,
  payload: cloudProvider
});
export const getBdbSyncSourcesSuccess = (data: ReplicaItem[]): GetBdbSyncSourcesSuccessAction => ({
  type: DatabasesActionTypes.GET_BDB_SYNC_SOURCES_SUCCESS,
  payload: data
});
export const getBdbSyncSourcesFailure = (error: string): GetBdbSyncSourcesFailureAction => ({
  type: DatabasesActionTypes.GET_BDB_SYNC_SOURCES_FAILURE,
  payload: error
});

export const validateBdbSyncSourceRequest = (
  requestData: ValidateBdbSyncSourcesRequestActionPayload['requestData'],
  cb: ValidateBdbSyncSourcesRequestActionPayload['cb']
): ValidateBdbSyncSourcesRequestAction => ({
  type: DatabasesActionTypes.VALIDATE_BDB_SYNC_SOURCES_REQUEST,
  payload: { requestData, cb }
});
export const validateBdbSyncSourcesSuccess = (): ValidateBdbSyncSourcesSuccessAction => ({
  type: DatabasesActionTypes.VALIDATE_BDB_SYNC_SOURCES_SUCCESS
});
export const validateBdbSyncSourcesFailure = (
  error: string
): ValidateBdbSyncSourcesFailureAction => ({
  type: DatabasesActionTypes.VALIDATE_BDB_SYNC_SOURCES_FAILURE,
  payload: error
});
export const resetBdbValidationState = (): ResetBdbValidationStateAction => ({
  type: DatabasesActionTypes.RESET_DBD_VALIDATION_STATE
});

export const refreshDbSuccess = (bdb: PartialWithId<Bdb>): RefreshDbSuccessAction => ({
  type: DatabasesActionTypes.REFRESH_DB_SUCCESS,
  payload: bdb
});

export const backupDbRequest = (bdbId: number): BackupDbRequestAction => ({
  type: DatabasesActionTypes.BACKUP_DB_REQUEST,
  payload: bdbId
});

export const backupDbSuccess = (): BackupDbSuccessAction => ({
  type: DatabasesActionTypes.BACKUP_DB_SUCCESS
});

export const backupDbFailure = (): BackupDbFailureAction => ({
  type: DatabasesActionTypes.BACKUP_DB_FAILURE
});

export const importDbRequest = (payload: ImportDbRequestPayload): ImportDbRequestAction => ({
  type: DatabasesActionTypes.IMPORT_DB_REQUEST,
  payload
});

export const importDbSuccess = (): ImportDbSuccessAction => ({
  type: DatabasesActionTypes.IMPORT_DB_SUCCESS
});

export const importDbFailure = (error: string): ImportDbFailureAction => ({
  type: DatabasesActionTypes.IMPORT_DB_FAILURE,
  payload: error
});

export const resetImportDbState = (): ResetImportDbStateAction => ({
  type: DatabasesActionTypes.RESET_IMPORT_DB_STATE
});

export const setDBtablePageSize = (pageSize: number): SetDBtablePageSizeAction => ({
  type: DatabasesActionTypes.SET_DB_TABLE_PAGE_SIZE,
  payload: pageSize
});

export const setShowRedisStackCongratulations = (
  showRedisStackCongratulations: boolean
): SetShowRedisStackCongratulationsAction => ({
  type: DatabasesActionTypes.SET_SHOW_REDIS_STACK_CONGRATULATIONS,
  payload: showRedisStackCongratulations
});
