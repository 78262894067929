import { useMutation } from '@tanstack/react-query';
import connectionWizardTemplatesKeys from './connectionWizardTemplates.keys';
import { connectionWizardTemplatesApi } from '../../services/api/resources/connectionWizardTemplates/connectionWizardTemplates.resource';

const useLogRedisInsightDownloadMutation = () => {
  const logRedisInsightDownload = async (osName: string) => {
    await connectionWizardTemplatesApi.logRedisInsightDownload({ os_name: osName });
  };

  return useMutation(
    connectionWizardTemplatesKeys.logRedisInsightDownload,
    logRedisInsightDownload
  );
};

export const connectionWizardTemplatesMutations = {
  useLogRedisInsightDownload: useLogRedisInsightDownloadMutation
};
