import Lottie from 'react-lottie';
import FreeDBLoaderAnimation from '../../assets/lottie/loader-freeDB.json';
import * as S from './LoginRelatedComponents.style';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: FreeDBLoaderAnimation
};

const FreeDBCreateAnimation = () => {
  return (
    <S.FreeDBLoaderContainer data-testid="free-db-loader">
      <Lottie options={defaultOptions} />
    </S.FreeDBLoaderContainer>
  );
};

export default FreeDBCreateAnimation;
