import * as S from './RadioButtonGroup.style';
import { RadioButtonGroupProps } from './RadioButtonGroup.types';
import RadioButton from './components/RadioButton/RadioButton';

const RadioButtonGroup = <T extends string>({
  title,
  name,
  radioButtons,
  checkedRadioBtnId,
  labelsColor = 'blue2',
  className
}: RadioButtonGroupProps<T>) => {
  return (
    <S.RadioButtonsContainer
      className={className}
      labelsColor={labelsColor}
      data-testid={`radio-button-group-${name}`}
    >
      {title && (typeof title === 'string' ? <S.Legend>{title}</S.Legend> : title)}
      {radioButtons.map((props) => {
        const { Component } = props;
        const renderProps = { ...props, isChecked: props.id === checkedRadioBtnId };

        return Component ? (
          <Component key={props.id} {...renderProps} />
        ) : (
          <RadioButton key={props.id} {...renderProps} />
        );
      })}
    </S.RadioButtonsContainer>
  );
};

export default RadioButtonGroup;
