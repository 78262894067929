import { useSelector } from 'react-redux';
import { getSamlLogoutUrl } from '../utils/saml.calls';
import { authIdSelector } from '../../../../store/auth/auth.selectors';
import { usersQueries } from '../../../../queryClient/users/users.queries';
import useSmQuery from '../../../../queryClient/useSmQuery';

export const SAML_LOGOUT_URL_KEY = ['samlLogoutUrl'];

const useSamlLogoutUrl = () => {
  const userId = useSelector(authIdSelector);
  const { data: user } = usersQueries.useUser(Number(userId));

  return useSmQuery({
    permissionKey: false,
    queryKey: SAML_LOGOUT_URL_KEY,
    queryFn: getSamlLogoutUrl,
    enabled: user?.user_type === 'saml',
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onSuccess: (samlLogoutUrl) => {
      if (samlLogoutUrl) {
        window.sessionStorage.setItem('samlLogoutUrl', samlLogoutUrl);
      } else {
        window.sessionStorage.removeItem('samlLogoutUrl');
      }
    }
  });
};

export default useSamlLogoutUrl;
