import React from 'react';
import * as S from './Card.style';

interface CardCompositionProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const Card = ({ children, ...props }: CardCompositionProps) => (
  <S.CardContainer role="card" {...props}>
    {children}
  </S.CardContainer>
);

const CardHeader = React.forwardRef<HTMLDivElement, CardCompositionProps>(
  ({ children, ...props }, ref) => (
    <S.Header {...props} ref={ref}>
      {children}
    </S.Header>
  )
);

const CardTitle = ({ children, ...props }: CardCompositionProps) => (
  <S.Title {...props}>{children}</S.Title>
);

const CardBody = React.forwardRef<HTMLDivElement, CardCompositionProps>(
  ({ children, ...props }, ref) => (
    <S.Body {...props} ref={ref}>
      {children}
    </S.Body>
  )
);

const ListGroup = ({ children, ...props }: CardCompositionProps) => (
  <S.ListGroupContainer {...props}>{children}</S.ListGroupContainer>
);

const ListItem = ({ children, ...props }: CardCompositionProps) => (
  <S.ListGroupItem {...props}>{children}</S.ListGroupItem>
);

Card.Header = CardHeader;
Card.Title = CardTitle;
Card.Body = CardBody;
Card.List = {
  Group: ListGroup,
  Item: ListItem
};

export default Card;
