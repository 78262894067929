import { omit } from 'lodash';
import { useSelector } from 'react-redux';
import { PermissionKey } from '../protectedComponents.types';
import { permissionsSelector } from '../../../store/auth/auth.selectors';
import userHasPermission from '../utils/userHasPermission';

const useProtectedPermissionsList = <T extends object>(
  protectedList: (T & { requiredPermissions?: PermissionKey; partialPermissions?: boolean })[]
) => {
  const userPermissions = useSelector(permissionsSelector);

  const allowedList: T[] = [];
  const blockedList: T[] = [];

  for (const item of protectedList) {
    (!item.requiredPermissions ||
    userHasPermission(item.requiredPermissions, userPermissions, item.partialPermissions)
      ? allowedList
      : blockedList
    ).push(omit<typeof item, 'requiredPermissions'>(item, 'requiredPermissions') as T);
  }

  return { allowedList, blockedList };
};

export default useProtectedPermissionsList;
