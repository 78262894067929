import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleLabel = styled.span`
  margin-left: 0.4rem;
  margin-right: 0.65rem;
`;
