import styled, { css } from 'styled-components/macro';
import { SvgIcon } from '@redislabsdev/redislabs-ui-components';
import * as CloudAndRegionSelectionStyle from '../../screens/CreateSubscription/components/CloudAndRegionSelection/CloudAndRegionSelection.style';

const commonContainer = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

const titleCss = css`
  color: ${({ theme: { colors } }) => colors.blue2};
  font-size: 2.8rem;
  font-family: ${({ theme }) => theme.fontTypes.bold};
`;

export const WelcomeContainer = styled.div`
  ${commonContainer};
  opacity: 0.94;

  & img {
    margin-top: 2.33rem;
    margin-bottom: 9.15rem;
  }
`;

export const WelcomeText = styled.div<{ marginBottom?: string }>`
  ${titleCss};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
`;

export const ThanksForSignUpContainer = styled.div`
  ${commonContainer};

  & > img:first-of-type {
    position: absolute;
    transform: scale(1.3);
  }

  & button {
    z-index: 1;
  }
`;

export const RedisLogoContainer = styled(SvgIcon)`
  position: absolute;
  top: 3.33rem;
  left: 3rem;
  width: 19rem;
  height: 5.5rem;
`;

export const ImgContainer = styled.div`
  margin-bottom: 4.37rem;
`;

export const ThanksForSignUpTitle = styled.div`
  ${titleCss};
  margin-bottom: 7.8rem;
`;

export const CheckEmailText = styled.div`
  color: ${({ theme: { colors } }) => colors.blue2};
  font-size: 2.2rem;
  font-family: ${({ theme }) => theme.fontTypes.bold};
  margin-top: 3.6rem;
  margin-bottom: 1.4rem;
`;

export const ClickMessage = styled.div`
  color: ${({ theme: { colors } }) => colors.blue2};
  font-size: 1.4rem;
  margin: 0.6rem;
`;

export const FreeDBContainer = styled.div`
  ${commonContainer};
  background-color: rgba(255, 255, 255, 0.6);
`;

export const FreeDBWrapper = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 6;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 85.5rem;
  height: 51.5rem;
  box-shadow: 0 0 0.6rem rgba(0, 0, 0, 0.24);
  border-radius: 0.8rem;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

const commonSide = css`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

export const FreeDBLoaderContainer = styled.div`
  ${commonSide};
  pointer-events: none;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

export const LeftSide = styled.div`
  ${commonSide};
  align-items: flex-start;
  justify-content: flex-start;
  background: ${({ theme: { colors } }) => colors.lightGray2};
  flex: 7;
  position: relative;
  padding: 8rem 4rem;
  align-self: stretch;
`;

export const LeftEllipse = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const RightEllipse = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const RightSide = styled.div`
  ${commonSide};
  flex: 11;
  height: 100%;
  padding: 4rem 0;
  align-self: stretch;
`;

export const VendorSection = styled.div`
  ${commonSide};
  width: 100%;
  height: 100%;
  padding: 4rem;
`;

export const FreeDbCloudVendorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${CloudAndRegionSelectionStyle.Title} {
    text-align: left;
    margin-bottom: 1rem;
  }

  > div:first-child > div > div {
    width: 12rem;
    height: 12rem;
  }
`;

export const ButtonSection = styled.div`
  ${commonSide};
  button {
    width: 20rem;
  }
`;

export const LineContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  word-break: normal;
  color: ${({ theme: { colors } }) => colors.gray1};
  margin-bottom: -0.8rem;

  & hr {
    background-color: ${({ theme: { colors } }) => colors.lightGray2};
    width: 100%;
    height: 0.2rem;
    margin: 2rem 1rem 2rem 0;

    &:last-of-type {
      margin-right: 0;
      margin-left: 1rem;
    }
  }
`;

export const SubTitle = styled.div`
  color: ${({ theme: { colors } }) => colors.blue2};
  font-size: 1.8rem;
  margin-bottom: 3rem;
`;

export const BoldSpan = styled.span`
  font-family: ${({ theme }) => theme.fontTypes.bold};
`;
export const SmallDiv = styled.div`
  font-size: 1.2rem;
  margin-bottom: -1.7rem;
`;

export const Row = styled.div`
  color: ${({ theme: { colors } }) => colors.blue2};
  font-size: 1.4rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Icon = styled(SvgIcon)<{ padding?: string }>`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.6rem;
  background: ${({ theme: { colors } }) => colors.white};
  border-radius: 50%;
  padding: 0.314rem;
  ${({ padding }) => padding && `padding: ${padding}`};
`;

export const BlueIcon = styled.span`
  & path {
    fill: ${({ theme: { colors } }) => colors.blue2};
  }
`;
