import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { Title } from './CloudAndRegionSelection.style';
import CloudSelect from './components/CloudSelect/CloudSelect';
import CreationRegionSelect from './components/CreationRegionSelect/CreationRegionSelect';
import { CloudAndRegionSelectionProps } from './CloudAndRegionSelection.types';

const CloudAndRegionSelection = ({
  allRegions,
  onCloudSelect,
  onRegionSelect,
  selectedRegion,
  cloudProviders,
  selectedProvider,
  isSupportingRedis7OptIn,
  onRedis7OptInClick,
  withMoreRegionsPageLink = false,
  isOptedToRedis7
}: CloudAndRegionSelectionProps) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.createSubscription.cloudVendor;

  return (
    <>
      <div>
        <Title>{t(keyPrefix.title)}</Title>
        <CloudSelect {...{ selectedProvider, cloudProviders, onCloudSelect }} />
      </div>
      <CreationRegionSelect
        {...{
          selectedRegion,
          onRegionSelect,
          allRegions,
          onRedis7OptInClick,
          withMoreRegionsPageLink,
          isOptedToRedis7,
          isSupportingRedis7OptIn
        }}
      />
    </>
  );
};

export default CloudAndRegionSelection;
