import { enUS, he } from 'date-fns/locale';
import { SupportedLanguages } from '../../locale/i18n.types';

type LocaleMapper = {
  [K in SupportedLanguages]: Locale;
};

const localeMapper: LocaleMapper = {
  en: enUS,
  he
};
export default localeMapper;
