const connectionWizardReducerSelector = (state: RootState) => state.connectionWizard;

export const isDrawerOpenedSelector = (state: RootState) =>
  connectionWizardReducerSelector(state).drawerOpen;

export const openedAccordionSelector = (state: RootState) =>
  connectionWizardReducerSelector(state).openedAccordionId;

export const bdbSelector = (state: RootState) => connectionWizardReducerSelector(state).bdb;

export const crdbSelector = (state: RootState) => connectionWizardReducerSelector(state).crdb;
