import { CreateDbSuccessAction, DeleteDatabaseSuccessAction } from '../databases/databases.types';

export enum SubscriptionsActionTypes {
  START_SUBSCRIPTIONS_POLLING = 'START_SUBSCRIPTIONS_POLLING',

  GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST',
  GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS',
  GET_SUBSCRIPTIONS_FAILURE = 'GET_SUBSCRIPTIONS_FAILURE',

  DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS',

  UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST',
  UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS',
  UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE',

  RESET_UPDATE_SUBSCRIPTION_STATE = 'RESET_UPDATE_SUBSCRIPTION_STATE',
  ADD_NEW_SUBSCRIPTION = 'ADD_NEW_SUBSCRIPTION'
}

export interface StartSubscriptionPollingRequestAction {
  type: SubscriptionsActionTypes.START_SUBSCRIPTIONS_POLLING;
}

export interface GetSubscriptionRequestAction {
  type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_REQUEST;
}

export interface GetSubscriptionSuccessAction {
  type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_SUCCESS;
  payload: SubById;
}

export interface GetSubscriptionFailureAction {
  type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_FAILURE;
  payload: string;
}

export interface DeleteSubscriptionSuccessAction {
  type: SubscriptionsActionTypes.DELETE_SUBSCRIPTION_SUCCESS;
  payload: number;
}

export interface UpdateSubscriptionRequestAction {
  type: SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_REQUEST;
  payload: {
    subscription: Subscription;
    onSuccess?: () => void;
    onError?: () => void;
  };
}

export interface UpdateSubscriptionSuccessAction {
  type: SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_SUCCESS;
  payload: Subscription;
}

export interface UpdateSubscriptionFailureAction {
  type: SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_FAILURE;
  payload: string;
}

export interface ResetUpdateSubscriptionAction {
  type: SubscriptionsActionTypes.RESET_UPDATE_SUBSCRIPTION_STATE;
}

export interface AddNewSubscriptionAction {
  type: SubscriptionsActionTypes.ADD_NEW_SUBSCRIPTION;
  payload: Subscription;
}

export type SubscriptionTypes =
  | StartSubscriptionPollingRequestAction
  | GetSubscriptionRequestAction
  | GetSubscriptionSuccessAction
  | GetSubscriptionFailureAction
  | DeleteSubscriptionSuccessAction
  | UpdateSubscriptionRequestAction
  | UpdateSubscriptionSuccessAction
  | UpdateSubscriptionFailureAction
  | ResetUpdateSubscriptionAction
  | AddNewSubscriptionAction
  | CreateDbSuccessAction
  | DeleteDatabaseSuccessAction;
