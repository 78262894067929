export const marketplaceCookies = {
  id: 'account_marketplace_id',
  provider: 'account_marketplace_provider'
} as const;

export const marketplaceProviders = {
  aws: 'AWS',
  gcp: 'Google Cloud',
  azure: 'Azure'
} as const;
