import styled from 'styled-components/macro';
import { DropdownMenu as DropdownMenuComponent } from '@redislabsdev/redislabs-ui-components';
import { ReadMoreContainer } from '../../../ReadMore/ReadMore.style';

export const Wrapper = styled.div`
  padding: 0.8rem 0.8rem 1rem 0.8rem;
  display: flex;
  flex-direction: column;

  & > span {
    margin-bottom: 0.4rem;
  }

  & > fieldset {
    margin-bottom: 2.8rem;
  }

  & button {
    font-size: 1.2rem;
  }
`;

export const DropdownMenu = styled(DropdownMenuComponent)`
  width: 100%;
  margin-right: 2rem;

  & span {
    font-size: 1.4rem;
  }
`;

export const DownloadLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  & > a {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
    svg {
      margin-right: 0.4rem;
    }
  }
`;

export const Separator = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightGray2};
`;

export const FullWidthSeparator = styled.div`
  margin-top: 2.6rem;
  margin-bottom: 1.6rem;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightGray2};
`;

export const RedisInsightInfo = styled.div`
  margin-bottom: 2rem;

  & ${ReadMoreContainer} {
    margin-bottom: 2rem;
    margin-top: 1.2rem;
  }
`;
