import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { useContext } from 'react';
import { CollapseContext } from '../../context/CollapseContext';
import * as S from './CollapseArrow.style';

const CollapseArrow = () => {
  const { isOpen } = useContext(CollapseContext);

  return (
    <S.Arrow
      width={15}
      open={isOpen}
      height={15}
      fill="darkBlue1"
      name={IconNamesEnum.ARROW_DOWN}
    />
  );
};

export default CollapseArrow;
