import isString from 'lodash/isString';
import { LOADING_CHUNK_MESSAGE } from '../constants/sentryErrorMessages.constants';

const isChunkErrorMessage = (errorMessage: string) => {
  if (isString(errorMessage)) {
    return errorMessage.toLowerCase().includes(LOADING_CHUNK_MESSAGE.toLowerCase());
  }

  return false;
};

export default isChunkErrorMessage;
