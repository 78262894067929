export const USER_MFA_REQUIRED = 'user-mfa-required';
export const USER_MFA_GENERAL_ERROR = 'user-mfa-general-error';
export const NO_ACCOUNT_ASSOCIATED_FOR_CURRENT_AUTH_MODE =
  'no-account-associated-for-current-authentication-mode';
const SAML_INVALID_ACCOUNT_MAPPING = 'saml-config-invalid-account-mapping';
const SAML_CONFIG_NOT_OWNED_BY_ACCOUNT = 'saml-config-not-owned-by-account';
export const SAML_ACCOUNT_MAPPING_DEPROVISION_WITH_NO_OWNER =
  'saml-account-mapping-deprovision-with-no-owner';

export const SAML_ERRORS = [
  SAML_INVALID_ACCOUNT_MAPPING,
  SAML_CONFIG_NOT_OWNED_BY_ACCOUNT,
  SAML_ACCOUNT_MAPPING_DEPROVISION_WITH_NO_OWNER
];

export const SOCIAL_CONFIRM_MIGRATION = 'user-agreement-for-social-login-migration-missing';
export const SAML_CONFIRM_MIGRATION = 'user-agreement-for-saml-migration-missing';
