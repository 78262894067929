import { Route, Redirect } from 'react-router-dom';
import usePermissionValidation from '../../hooks/usePermissionValidation';
import { getPermissionsFromPath } from '../../utils/getPermissionsFromPath';
import { ProtectedRouteProps } from './ProtectedRoute.types';

const ProtectedRoute = ({ redirectTo, ...rest }: ProtectedRouteProps) => {
  const { requiredRoutePermissions, isRoot } = getPermissionsFromPath(
    rest.path as string | string[]
  );
  const hasPermissions = usePermissionValidation(requiredRoutePermissions, isRoot);

  if (!hasPermissions) {
    return <Redirect to={redirectTo || '/'} />;
  }

  return <Route {...rest} />;
};

export default ProtectedRoute;
