import RedisApiAxiosInstance from '../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';

export const getCloudAccounts = () => RedisApiAxiosInstance.get('/cloudAccounts');

export const validateCloudAccount = (payload: CloudAccount) =>
  RedisApiAxiosInstance.post(`/cloudAccounts/actions/validate_credentials/`, {
    cloudAccount: payload
  });

export const getCloudAccount = (id: number) => RedisApiAxiosInstance.get(`/cloudAccounts/${id}`);

export const postCloudAccount = (payload: CloudAccount) =>
  RedisApiAxiosInstance.post(`/cloudAccounts`, { cloudAccount: payload });

export const updateCloudAccount = (payload: CloudAccount) => {
  return RedisApiAxiosInstance.put(`/cloudAccounts/${payload.id}`, { cloudAccount: payload });
};

export const deleteCloudAccount = (id: number) =>
  RedisApiAxiosInstance.delete(`/cloudAccounts/${id}`);
