import { matchPath } from 'react-router-dom';
import { showToast } from '@redislabsdev/redislabs-ui-components/ui/components/Toast';
import { put, select, call, takeLatest, delay, race, take } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { isEmpty, isEqual, keyBy } from 'lodash';
import * as actions from './databases.actions';
import * as subsActions from '../subscriptions/subscriptions.actions';
import { isLoggedInSelector, productTypeForPollingSelector } from '../auth/auth.selectors';
import { databasesStateSelector } from './databases.selectors';
import { sortById } from '../../utils/helpers/misc';
import {
  CreateUpdateDatabaseRequestAction,
  UpdateDbRequestAction,
  DatabasesActionTypes,
  GetBdbSyncSourcesRequestAction,
  ValidateBdbSyncSourcesRequestAction,
  BdbState,
  BackupDbRequestAction,
  ImportDbRequestAction
} from './databases.types';
import history from '../../hashHistory';
import { routes } from '../../utils/constants/routes';
import { bdbsCallInterval } from '../../utils/constants/api/intervals';
import { errorsMap } from '../../utils/constants/api/errorsMap';
import { genericErrorStatus } from '../../utils/constants/api/statuses';
import { extractError } from '../../utils/helpers/extractError';
import i18n from '../../locale/i18n';
import { rcpUpdateFlow, oldPricingRcpUpdateFlow } from './rcpUpdateFlow.saga';
import { ErrorInfo, ExtendedAxiosError, SubscriptionAndDbParams } from '../../types/interfaces';
import { extractErrorAndShowToast } from '../../utils/helpers/extractErrorAndShowToast';
import { isOldPricingSelector } from '../../components/oldPricing/selectors/oldPricing.selectors';
import {
  getBdbsToUpdateBasedOnSubBdbs,
  processFirstDatabaseActiveTooltip,
  sortBdbsInnerArrays
} from './databases.utils';
import { isOnSubsOrDatabasesRoute } from '../../utils/isOnSubsOrDatabasesRoute';
import { bdbsApi } from '../../services/api/resources/bdbs/bdbs.resource';

function* fetchBdbsBasedOnSubId(productType: string) {
  const matchResult = matchPath<SubscriptionAndDbParams>(history.location.pathname, {
    path: routes.subscriptions.subscription.root,
    exact: false
  });

  const subscriptionId = matchResult?.params?.subscriptionId;

  if (subscriptionId) {
    const {
      data: { bdbs: subBdbs }
    }: AxiosResponse<{ bdbs: Bdb[] }> = yield call(bdbsApi.getAll, {
      productType,
      subscription: subscriptionId
    });

    processFirstDatabaseActiveTooltip(subBdbs);

    const { data: currentBdbs }: BdbState = yield select(databasesStateSelector);

    const bdbsToUpdate = getBdbsToUpdateBasedOnSubBdbs(currentBdbs, subBdbs, subscriptionId);

    if (!isEmpty(bdbsToUpdate)) {
      yield put(actions.getDatabasesSuccess(bdbsToUpdate));
    }
  }
}

function* databasesPolling() {
  const productType: ProductType = yield select(productTypeForPollingSelector);

  let shouldFetchOnlySubBdbs = false;

  while (isOnSubsOrDatabasesRoute().isOnDbsOrSubs) {
    const isLoggedIn = yield select(isLoggedInSelector);
    if (!isLoggedIn) {
      break;
    }
    if (document?.visibilityState === 'visible') {
      try {
        if (shouldFetchOnlySubBdbs) {
          // fetch bdbs by sub id every 10 sec after get all bdbs call
          yield fetchBdbsBasedOnSubId(productType);
        } else {
          yield fetchAllBdbs(productType);
        }

        shouldFetchOnlySubBdbs = !shouldFetchOnlySubBdbs;
      } catch (e) {
        if (e.response) {
          yield put(actions.getDatabasesFailure(e.response.data));
        }
        break;
      }
    }

    yield delay(bdbsCallInterval);
  }
}

function* getDatabases() {
  const productType: ProductType = yield select(productTypeForPollingSelector);
  try {
    yield fetchAllBdbs(productType);
  } catch (e) {
    yield put(actions.getDatabasesFailure(e.response?.data));
    extractErrorAndShowToast(e);
  }
}

function* fetchAllBdbs(productType: string) {
  const { data: databases, status: dbsStatus }: BdbState = yield select(databasesStateSelector);
  try {
    const {
      data: { bdbs }
    }: AxiosResponse<{ bdbs: Bdb[] }> = yield call(bdbsApi.getAll, { productType });

    processFirstDatabaseActiveTooltip(bdbs);

    const sortedDbs = sortBdbsInnerArrays(sortById(bdbs, false));
    const bdbsById = keyBy(sortedDbs, 'id');

    if (
      (isEmpty(bdbsById) && isEmpty(databases)) ||
      !isEqual(bdbsById, databases) ||
      dbsStatus === 'pending'
    ) {
      yield put(actions.getDatabasesSuccess(bdbsById));
    }
  } catch (e) {
    if (e.response) {
      const { data, status } = e.response;
      yield put(actions.getDatabasesFailure(data));
      throw status;
    }
    throw e;
  }
}

function* getBdbSyncSources(action: GetBdbSyncSourcesRequestAction) {
  const cloudProvider = action.payload;
  try {
    const { data } = yield call(bdbsApi.getSyncSources, cloudProvider);
    yield put(actions.getBdbSyncSourcesSuccess(data));
  } catch (e) {
    if (e.response) {
      const errors = e.response?.data?.errors;
      yield put(actions.getBdbSyncSourcesFailure(errors?.message));
    }
  }
}

function* validateBdbSyncSources(action: ValidateBdbSyncSourcesRequestAction) {
  const { requestData, cb } = action.payload;
  try {
    yield call(bdbsApi.validateSyncSources, requestData);
    yield put(actions.validateBdbSyncSourcesSuccess());
    yield call(cb);
  } catch (e) {
    if (e.response) {
      const errors = e.response?.data?.errors;
      if (typeof errors === 'string') {
        const [errorsObj] = JSON.parse(errors);
        const errorMsg = errorsMap[errorsObj.error_code].message;
        yield put(actions.validateBdbSyncSourcesFailure(errorMsg));
      }
    }
  }
}

function* getBdb(bdbId: number, bdbOverride?: Partial<Bdb>) {
  try {
    const { data }: AxiosResponse<{ bdb: Bdb }> = yield call(bdbsApi.getOne, bdbId);

    const updatedBdb = {
      ...data.bdb,
      ...bdbOverride
    };
    yield put(actions.refreshDbSuccess(updatedBdb));
  } catch (e) {
    extractErrorAndShowToast(e);
  }
}

function* backupDbSaga({ payload: bdbId }: BackupDbRequestAction) {
  try {
    yield call(bdbsApi.backup, bdbId);
    yield getBdb(bdbId, { backup_status: 'exporting' });
    yield put(actions.backupDbSuccess());
  } catch (error) {
    if (error?.response?.status === genericErrorStatus) {
      showToast(i18n.t('createUpdateDBForm.validations.backupDbFailed'));
    } else {
      extractErrorAndShowToast(error);
    }
    yield put(actions.backupDbFailure());
  }
}

function* importDbSaga({ payload }: ImportDbRequestAction) {
  try {
    yield call(bdbsApi.import, payload);
    yield getBdb(parseInt(payload.bdbId, 10), { import_status: 'importing' });
    yield put(actions.importDbSuccess());
    payload.onImportSuccess();
  } catch (error) {
    const { message } = extractError(error.response?.data);
    yield put(actions.importDbFailure(i18n.t(message)));
    extractErrorAndShowToast(error);
  }
}

function* handleCreateUpdateRequestError(
  e: ExtendedAxiosError,
  errorCb: (errorInfo?: ErrorInfo | ErrorInfo[]) => void
) {
  yield errorCb(e.response?.errorInfo || { message: e.message });
}

function* createUpdateDB(action: CreateUpdateDatabaseRequestAction) {
  const {
    updatedBdb,
    shouldUpdateRcp,
    updatedRcp,
    shardTypePricingPostBody,
    isCreateMode,
    errorCb,
    successCb,
    selectedSubscription,
    setSubmitting,
    selectedDatabase,
    shardTypes
  } = action.payload;

  try {
    const isOldPricing: boolean = yield select(isOldPricingSelector);
    const isReserved = updatedRcp?.rcp_type === 'reserved';

    let shouldUpdateBdb = true;
    let shouldEnableClustering = updatedBdb.has_sharding;

    if (shouldUpdateRcp) {
      const rcpFlowConfirmFlags: {
        shouldUpdate: boolean;
        didConfirmClustering: boolean;
      } = yield call(isOldPricing || isReserved ? oldPricingRcpUpdateFlow : rcpUpdateFlow, {
        updatedRcp,
        shardTypePricingPostBody,
        isCreateMode,
        selectedSubscription,
        setSubmitting,
        selectedDatabase,
        shardTypes
      });

      shouldUpdateBdb = rcpFlowConfirmFlags.shouldUpdate;
      if (!shouldEnableClustering) {
        shouldEnableClustering = rcpFlowConfirmFlags.didConfirmClustering;
      }
    }

    if (shouldUpdateBdb) {
      const dbPromise = isCreateMode ? bdbsApi.create : bdbsApi.update;
      const {
        data: { bdb }
      }: AxiosResponse<{ bdb: Bdb }> = yield call(dbPromise, {
        ...updatedBdb,
        has_sharding: shouldEnableClustering
      });

      if (isCreateMode) {
        yield put(actions.CreateDBSuccessAction(bdb));
      } else {
        yield put(actions.UpdateDBSuccessAction(bdb));
      }
      successCb(bdb);

      yield put(actions.getDatabases());
      yield put(subsActions.getSubscriptions());
    } else {
      errorCb();
    }
  } catch (e) {
    yield handleCreateUpdateRequestError(e, errorCb);
  }
}

function* updateDB(action: UpdateDbRequestAction) {
  const { updatedBdb, successCb, errorCb } = action.payload;
  try {
    const {
      data: { bdb }
    }: AxiosResponse<{ bdb: Bdb }> = yield call(bdbsApi.update, updatedBdb);
    yield put(actions.UpdateDBSuccessAction(bdb));
    successCb();
  } catch (e) {
    yield handleCreateUpdateRequestError(e, errorCb);
  }
}

function* cancellableCreateUpdateDbSaga(action: CreateUpdateDatabaseRequestAction) {
  yield race([call(createUpdateDB, action), take(DatabasesActionTypes.CANCEL_CREATE_UPDATE_DB)]);
  if (action.payload.setSubmitting) action.payload.setSubmitting(false);
}

function* databasesSaga() {
  yield takeLatest(DatabasesActionTypes.START_DATABASES_POLLING, databasesPolling);
  yield takeLatest(DatabasesActionTypes.GET_DATABASES_REQUEST, getDatabases);
  yield takeLatest(DatabasesActionTypes.CREATE_UPDATE_DB_REQUEST, cancellableCreateUpdateDbSaga);
  yield takeLatest(DatabasesActionTypes.UPDATE_DB_REQUEST, updateDB);
  yield takeLatest(DatabasesActionTypes.GET_BDB_SYNC_SOURCES_REQUEST, getBdbSyncSources);
  yield takeLatest(DatabasesActionTypes.VALIDATE_BDB_SYNC_SOURCES_REQUEST, validateBdbSyncSources);
  yield takeLatest(DatabasesActionTypes.BACKUP_DB_REQUEST, backupDbSaga);
  yield takeLatest(DatabasesActionTypes.IMPORT_DB_REQUEST, importDbSaga);
}

export default databasesSaga;
