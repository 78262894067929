import redis7Utils from '../../../../../../utils/redis7/redis7.utils';
import useRegions from '../../../../../../hooks/useRegions';
import { FreeDbCloudVendorProps } from '../../../FreeDb.types';
import {
  getVendorRegionPlanMapper,
  getFreePlans,
  getFirstRegion,
  DEFAULT_VENDOR
} from '../../../../utils/loginRelated';
import { useEssentialPlansQuery } from '../../../../../../hooks/useEssentialPlansQuery/useEssentialPlansQuery';
import { CloudProviders } from '../../../../../CloudProvider/CloudProvider.types';
import { parametersQueries } from '../../../../../../queryClient/parameters/parameters.queries';

interface Props
  extends Pick<
    FreeDbCloudVendorProps,
    'setSelectedVendor' | 'setSelectedRegion' | 'setIsOptedToRedis7' | 'isOptedToRedis7'
  > {}

const useRedis7CloudRegionSelectionProps = ({
  setSelectedVendor,
  setSelectedRegion,
  setIsOptedToRedis7,
  isOptedToRedis7
}: Props) => {
  const { systemParams } = parametersQueries.useSystemParameters();
  const { getRegionByRegionName, getRegionByRegionId } = useRegions();
  const { data: plans } = useEssentialPlansQuery({ refetchOnMount: false });

  const isSupportingRedis7OptIn = redis7Utils.getIsSupportingRedis7(
    systemParams.redis_7_region_ids
  );
  const onRedis7OptInClick = (isOptingIn: boolean) => {
    if (isOptingIn) {
      const firstRedis7Region = getRegionByRegionId(
        redis7Utils.parseRegionIds(systemParams.redis_7_region_ids)[0]
      );
      if (firstRedis7Region) {
        setSelectedRegion(firstRedis7Region.name);
        setSelectedVendor(firstRedis7Region.cloud.toLowerCase() as CloudProviders);
      }
    } else {
      const allAvailableFreePlans = getVendorRegionPlanMapper(
        getFreePlans({
          plans
        })
      );
      setSelectedRegion(
        getFirstRegion(allAvailableFreePlans[DEFAULT_VENDOR], getRegionByRegionName)
      );
      setSelectedVendor(DEFAULT_VENDOR);
    }
    setIsOptedToRedis7(isOptingIn);
  };

  return {
    onRedis7OptInClick,
    isSupportingRedis7OptIn,
    isOptedToRedis7
  };
};

export default useRedis7CloudRegionSelectionProps;
