import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import * as S from './RenderRegionItem.style';
import redis7Utils from '../../../../../../../../utils/redis7/redis7.utils';
import { parametersQueries } from '../../../../../../../../queryClient/parameters/parameters.queries';

interface RenderRegionItemProps {
  region: CloudRegion;
  isSupportingRedis7OptIn: boolean;
}
const RenderRegionItem = ({ region, isSupportingRedis7OptIn }: RenderRegionItemProps) => {
  const { systemParams } = parametersQueries.useSystemParameters();
  const { t } = useTranslation();
  const keyPrefix = i18translation.redis7OptIn;
  const redis7regions = redis7Utils.parseRegionIds(systemParams.redis_7_region_ids);
  const isRegionRedis7 = (regionId: number) => redis7regions.includes(regionId);

  return (
    <S.Container>
      <S.RegionDisplayField region={region?.name} />
      {isSupportingRedis7OptIn && isRegionRedis7(region.region_id) && (
        <S.Redis7Text>{`(${t(keyPrefix.regionItemRedis7Text)})`}</S.Redis7Text>
      )}
    </S.Container>
  );
};

export default RenderRegionItem;
