import styled from 'styled-components/macro';
import ReadMoreComponent from '../../../../../../../../../../components/ReadMore/ReadMore';

export const Title = styled.h2`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.darkBlue1};
  margin-bottom: 0.4rem;
`;

export const ReadMore = styled(ReadMoreComponent)`
  margin-top: 1.6rem;
  display: inline-block;
`;
