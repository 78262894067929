import { canChangePlan } from './canChangePlan';
import * as subscriptionStatuses from './subscriptionStatuses';
import * as subTypes from './subType';

const subscriptionUtils = {
  canChangePlan,
  ...subscriptionStatuses,
  ...subTypes
};

export default subscriptionUtils;
