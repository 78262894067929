import { AxiosError } from 'axios';
import RedisApiAxiosInstance from '../../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { extractErrorAndShowToast } from '../../../../utils/helpers/extractErrorAndShowToast';
import { BillingHistoryResponse } from '../billingHistory.types';
import { extractBalanceFromBillings } from '../utils/extractBalanceFromBillings';
import useSmQuery from '../../../../queryClient/useSmQuery';

export const billingHistoryQueryKey = ['billingHistory'];

export const useBillingHistoryQuery = () => {
  const fetchBillingHistory = async () => {
    const { data } = await RedisApiAxiosInstance.get<BillingHistoryResponse>('/billings');

    return extractBalanceFromBillings(data.billings);
  };

  return useSmQuery({
    permissionKey: 'billing_get_billings',
    queryKey: billingHistoryQueryKey,
    queryFn: fetchBillingHistory,
    onError: (err: AxiosError) => {
      extractErrorAndShowToast(err);
    }
  });
};
