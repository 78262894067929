const usePreviousPath = (): { previousFirstWord: string | null; previousPath: string | null } => {
  const getPreviousPath: string | null = window.sessionStorage.getItem('locationPathName');

  // always get the first word from path and use it to match the availability of the path
  return {
    previousFirstWord: getPreviousPath && getPreviousPath.split('/')[1],
    previousPath: getPreviousPath
  };
};

export default usePreviousPath;
