import styled from 'styled-components/macro';
import { SvgIcon } from '@redislabsdev/redislabs-ui-components';
import { CollapseHeader } from 'components/Shared/Collapse/components/CollpaseHeader/CollapseHeader.styles';
import Collapse from '../Shared/Collapse/Collapse';
import SMDrawer from '../Shared/SMDrawer/SMDrawer';
import { Title } from '../Shared/Card/Card.style';

export const DrawerHeader = styled(SMDrawer.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${({ theme }) => `${theme.colors.lightGray1}`};
`;

export const DrawerBody = styled(SMDrawer.Body)`
  margin: 2.5rem 0 2.5rem;
`;

export const CloseButton = styled(SvgIcon)`
  cursor: pointer;
`;

export const CodeSnippetBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 0.4rem;
  padding: 0.8rem;
  margin-bottom: 0.4rem;
  color: ${({ theme }) => theme.colors.darkBlue1};
`;

export const CodeSnippetBoxContent = styled.pre`
  font-family: ${({ theme }) => theme.fontTypes.sourceCodeProRegular};
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
  margin-bottom: 0;
`;

export const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.4rem;
`;

export const HighlightedPlaceholder = styled.span<{ bold?: boolean }>`
  color: ${({ theme }) => theme.colors.purple1};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'inherit')};
`;

export const RegionSelectDropdownContainer = styled.div`
  margin-bottom: 2.4rem;
`;

export const CollapseContainer = styled(Collapse)`
  ${CollapseHeader} {
    height: 40px;
    ${Title} {
      width: 100%;
      display: flex;
      align-items: center;
      font-weight: 400;
    }
  }
`;

export const RecommendedAnimation: React.CSSProperties = {
  width: '11rem',
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: '1.5rem'
};
