import styled from 'styled-components/macro';
import { Drawer as DrawerComponent } from '@redislabsdev/redislabs-ui-components';

export const PADDING = 3.2;
const FOOTER_CHILDREN_SPACE_BETWEEN = 1.6;
export const DRAWER_SIZE = 60.3;

export const Header = styled.div`
  padding: 4rem ${PADDING}rem 0;
`;

export const Body = styled.div`
  overflow-y: auto;
  padding: 0 ${PADDING}rem;
  margin: 4rem 0 2rem;
`;

export const Footer = styled.div`
  padding: 1.2rem ${PADDING}rem;
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  & > *:not(:last-child) {
    margin-right: ${FOOTER_CHILDREN_SPACE_BETWEEN}rem;
  }
`;

export const DrawerContainer = styled(DrawerComponent)`
  z-index: 4;

  .drawer-content {
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow-y: hidden;
  }

  &.drawer-open .drawer-mask {
    opacity: 0.6;
  }

  .drawer-content-wrapper.drawer-content-wrapper {
    box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.03), 0px 0px 32px rgba(0, 0, 0, 0.16);
  }

  ${Body} ~ ${Footer} {
    border-top: 1px solid ${({ theme }) => theme.colors.lightGray2};
  }
`;

export const Title = styled.h4`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fontTypes.bold};
  color: ${({ theme }) => theme.colors.darkBlue1};
  display: inline-flex;
  white-space: pre;
`;

export const Caption = styled.div`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fontTypes.regular};
  color: ${({ theme }) => theme.colors.darkBlue1};
  display: inline-flex;
  white-space: pre;
`;

export const DrawerDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.lightGray2};
  margin: 24px 0;
`;
