import connectionWizardTemplatesKeys from './connectionWizardTemplates.keys';
import { connectionWizardTemplatesApi } from '../../services/api/resources/connectionWizardTemplates/connectionWizardTemplates.resource';
import useSmQuery from '../useSmQuery';

const useConnectionWizardTemplateQuery = (connectionWizardEnabled: boolean) => {
  const getConnectionWizardTemplates = async () => {
    const { data } = await connectionWizardTemplatesApi.getAll();

    return data;
  };

  return useSmQuery({
    permissionKey: false,
    queryKey: connectionWizardTemplatesKeys.connectionWizardTemplates,
    queryFn: getConnectionWizardTemplates,
    enabled: connectionWizardEnabled,
    refetchOnWindowFocus: false
  });
};

export const connectionWizardTemplatesQueries = {
  useConnectionWizardTemplate: useConnectionWizardTemplateQuery
};
