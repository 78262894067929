import * as Yup from 'yup';
import { TFunction } from 'i18next';
import RedisApiAxiosInstance from '../../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { okNoContentStatus, okStatus } from '../../../../utils/constants/api/statuses';
import {
  ActivateInvitationCallbackRequestProps,
  ResetPasswordCallbackRequestProps,
  ResetPasswordRequestProps
} from '../ResetPassword.types';
import { MAX_PASSWORD_FIELD_VALUE, MIN_PASSWORD_FIELD_VALUE } from '../../utils/constants';
import { passwordRegex } from '../../../../utils/validations/formRegexes';
import { i18translation } from '../../../../locale/translations/i18NsPaths';

export const resetPasswordRequest = async ({
  values,
  invitationRequest: { isInvitation, key },
  successCallback,
  errorCallback
}: ResetPasswordRequestProps) => {
  try {
    const path = isInvitation
      ? `/users/activate_invitation?key=${key}`
      : '/users/activate_password_reset';

    const { status } = await RedisApiAxiosInstance.post(path, values);

    if (status === okNoContentStatus) {
      successCallback();
    }
  } catch (e) {
    errorCallback(e);
    console.error(e, 'Error on resetPasswordRequest '); // eslint-disable-line
  }
};

export const resetPasswordCallbackRequest = async ({
  search,
  successCallback,
  errorCallback
}: ResetPasswordCallbackRequestProps) => {
  try {
    const { status } = await RedisApiAxiosInstance.get(`/users/reset_password_callback/${search}`);

    if (status === okNoContentStatus) {
      successCallback();
    }
  } catch (e) {
    errorCallback(e);
    console.error(e, 'Error on resetPasswordCallbackRequest'); // eslint-disable-line
  }
};

export const activateInvitationCallbackRequest = async ({
  search,
  successCallback,
  errorCallback
}: ActivateInvitationCallbackRequestProps) => {
  try {
    const { status, data } = await RedisApiAxiosInstance.get(
      `/users/invitation_callback/${search}`
    );

    if (status === okStatus) {
      const { activation_invite_key, user_account_id } = data; // eslint-disable-line

      successCallback({ key: activation_invite_key, accId: user_account_id });
    }
  } catch (e) {
    errorCallback(e);
    console.error(e, 'Error on activateInvitationCallbackRequest'); // eslint-disable-line
  }
};

export const buildValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    password: Yup.string()
      .required(t(i18translation.LoginScreen.resetPasswordScreen.required))
      .min(
        MIN_PASSWORD_FIELD_VALUE,
        t(i18translation.LoginScreen.resetPasswordScreen.passwordMin, {
          minValue: MIN_PASSWORD_FIELD_VALUE
        })
      )
      .matches(passwordRegex, t(i18translation.LoginScreen.resetPasswordScreen.passwordRegex))
      .max(
        MAX_PASSWORD_FIELD_VALUE,
        t(i18translation.LoginScreen.resetPasswordScreen.passwordMAx, {
          minValue: MAX_PASSWORD_FIELD_VALUE
        })
      ),
    confirm_password: Yup.string().when('password', {
      is: (value) => value,
      then: Yup.string()
        .required(t(i18translation.LoginScreen.resetPasswordScreen.required))
        .oneOf(
          [Yup.ref('password'), null],
          t(i18translation.LoginScreen.resetPasswordScreen.passwordMatch)
        )
    })
  });
