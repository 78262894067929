/* eslint-disable @typescript-eslint/naming-convention */
import formatBytes from '../../../utils/formatBytes';
import { ExtendedDatabasesToCreate } from '../../../screens/CreateSubscription/SubscriptionTypes/Pro/Pro.types';
import { CalculatePriceRequestPayload } from './pro.types';
import { isCloudAccountExternal } from '../../../screens/CreateSubscription/SubscriptionTypes/Pro/utils/isCloudAccountExternal';
import findRegionMinimalPrice from '../../../utils/cloudPricings/findRegionMinimalPrice';

export const findMinimalPricingForMultiRegions = ({
  regions,
  ...rest
}: CalculatePriceRequestPayload) => {
  const filteredPricingList = regions.map((region) =>
    findRegionMinimalPrice({ ...rest, regionName: region.name })
  );

  const totalPrice = filteredPricingList.reduce((a, b) => a + b, 0);

  return totalPrice;
};

const getPricingRegion = (
  name: string,
  provider: CloudProvider,
  db: ExtendedDatabasesToCreate,
  throughput: number = null
): CalculatePriceRegion => {
  const priceRegion: CalculatePriceRegion = {
    cloud_name: provider,
    name,
    replication: db.replication
  };

  if (db.sharding_policy === 'throughput') {
    priceRegion.throughput = throughput || db.throughput;
  } else {
    priceRegion.master_shards_count = db.master_shards;
  }

  return priceRegion;
};

const getTotalWriteThroughput = (regionsThroughput: RegionsThroughput) =>
  Object.values(regionsThroughput).reduce(
    (acc, throughputObj: RegionThroughputTypes) => acc + throughputObj.max_writes,
    0
  );

export const buildCalculatePricePostBody = ({
  cloud_account,
  rof,
  databases_to_create,
  provider,
  region,
  isAA,
  regions
}: CalculatePriceRequestPayload): GetShardPricingListPostBody => {
  return {
    hosted: !isCloudAccountExternal(cloud_account),
    specs: databases_to_create.map((db) => {
      let priceRegions = [];
      if (isAA) {
        const totalWriteThroughput = getTotalWriteThroughput(db.regionsThroughput);

        priceRegions = regions.map((multiRegion) => {
          const readRegionThroughput: number = db.regionsThroughput[multiRegion.id]?.max_reads;

          return getPricingRegion(
            multiRegion.name,
            provider as CloudProvider,
            { ...db, master_shards: db.regionsShards[multiRegion.id] },
            totalWriteThroughput + readRegionThroughput
          );
        });
      } else {
        const priceRegion = getPricingRegion(region, provider as CloudProvider, db);
        priceRegions.push(priceRegion);
      }

      return {
        name: db.name,
        rof,
        memory_size_gb: parseFloat(formatBytes({ bytes: db.memory_size, unit: 'GB', decimals: 1 })),
        regions: priceRegions
      };
    })
  };
};
