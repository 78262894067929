import { useSelector } from 'react-redux';
import { IconsMap } from './EnvBanner.constants';
import { EnvBannerProps } from './EnvBanner.types';
import * as S from './EnvBanner.style';
import { usePrefixedTranslation } from '../../../../hooks';
import { isProductionSelector } from '../../../../store/auth/auth.selectors';
import { stringToTestId } from '../../../../utils';

const EnvBanner: React.FC<EnvBannerProps> = ({ iconName, text, href, isSuperOrSupportAgent }) => {
  const IconComponent = IconsMap[iconName];
  const isProduction = useSelector(isProductionSelector);
  const { prefixedT } = usePrefixedTranslation('envBanner');
  const shouldUseWhiteColor = isProduction && isSuperOrSupportAgent;

  return (
    <>
      {isSuperOrSupportAgent && <S.VerticalLine />}
      <S.AnchorLink href={href} target="_blank">
        <S.IconContainer
          data-testid={`icon--${stringToTestId(prefixedT(text).toLowerCase())}`}
          shouldUseWhiteColor={shouldUseWhiteColor}
        >
          <IconComponent width="1.8rem" height="2rem" />
        </S.IconContainer>
        <S.HrefText iconName={iconName} shouldUseWhiteColor={shouldUseWhiteColor}>
          {prefixedT(text)}
        </S.HrefText>
      </S.AnchorLink>
    </>
  );
};

export default EnvBanner;
