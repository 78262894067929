import { FC, useEffect, useState } from 'react';
import { Button } from '@redislabsdev/redislabs-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import * as S from './LoginRelatedComponents.style';
import redisConfetti from '../../assets/icons/RedisConfetti.svg';
import usePrefixedTranslation from '../../hooks/usePrefixedTranslation';
import FreeDb from './FreeDb/FreeDb';
import { handleClick } from './utils/loginRelated';
import { productTypeSelector } from '../../store/auth/auth.selectors';
import { parametersQueries } from '../../queryClient/parameters/parameters.queries';
import {
  subscriptionsSelector,
  subscriptionStatusSelector
} from '../../store/subscriptions/subscriptions.selectors';
import { getSubscriptions } from '../../store/subscriptions/subscriptions.actions';
import useNavigation from '../../services/hooks/useNavigation';

const Welcome: FC = () => {
  const navigation = useNavigation();
  const productType = useSelector(productTypeSelector);
  const { prefixedT } = usePrefixedTranslation('LoginScreen.welcomeScreen');
  const dispatch = useDispatch();
  const showWelcomeScreen = window.localStorage.getItem('showWelcomeScreen');
  const { systemParams } = parametersQueries.useSystemParameters();
  const subs: SubById = useSelector(subscriptionsSelector, isEqual);
  const subsStatus = useSelector(subscriptionStatusSelector);
  const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(false);

  if (showWelcomeScreen && subsStatus === 'idle') {
    dispatch(getSubscriptions());
  }

  useEffect(() => {
    if (subsStatus === 'resolved' && !isSubscriptionChecked) {
      setIsSubscriptionChecked(true);
      if (!isEmpty(subs)) {
        window.localStorage.removeItem('showWelcomeScreen');
      }
    }
  }, [subsStatus, subs, isSubscriptionChecked]);

  if (showWelcomeScreen && isSubscriptionChecked) {
    if (systemParams?.enable_autoFreeDB) {
      return <FreeDb />;
    }

    return (
      <S.WelcomeContainer data-testid="welcome-container">
        <S.WelcomeText>{prefixedT('welcome')}</S.WelcomeText>

        <img src={redisConfetti} alt={prefixedT('redisConfetti')} />

        <Button
          data-testid="btn-submit-login"
          type="submit"
          size="large"
          onClick={() => {
            handleClick(productType);
            navigation.goToCreateSubscription();
          }}
        >
          {prefixedT('createNewSubs')}
        </Button>
      </S.WelcomeContainer>
    );
  }

  return null;
};

export default Welcome;
