import { IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { getDataTestId } from 'utils';
import { CollapseContext } from '../../context/CollapseContext';
import * as S from './CollapseCompose.styles';

export interface CollapseCompositeProps extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean;
  children: React.ReactNode;
  testId?: string;
  headerIcon?: IconNamesEnum;
  className?: string;
}

const CollapseCompose = ({ children, open, testId, ...props }: CollapseCompositeProps) => {
  return (
    <CollapseContext.Provider
      value={{
        testId,
        isOpen: open
      }}
    >
      <S.CollapseContainer
        {...getDataTestId('collapse', testId)}
        open={open}
        aria-expanded={open}
        {...props}
      >
        {children}
      </S.CollapseContainer>
    </CollapseContext.Provider>
  );
};

export default CollapseCompose;
