/* eslint-disable consistent-return */
import { FC, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Loader } from '@redislabsdev/redislabs-ui-components';
import { routes } from '../utils/constants/routes';
import { isLoggedInSelector } from '../store/auth/auth.selectors';
import ActivateInvitationCallback from './Login/ResetPassword/ActivateInvitationCallback';
import AuthRoutes from './AuthRoutes';
import Main from './Main/Main';
import SentryErrorBoundary from '../services/sentry/components/SentryErrorBoundary';
import MissingEmailSocialLogin from './Login/MissingEmailSocialLogin/MissingEmailSocialLogin';

const SocialLoginMigration = lazy(
  () => import('./Login/SocialLoginMigration/SocialLoginMigration')
);

const SamlLoginMigration = lazy(() => import('./Login/SamlLoginMigration/SamlLoginMigration'));

interface RoutesProps {
  hideNavigation?: boolean;
}

const Routes: FC<RoutesProps> = ({ hideNavigation }) => {
  const isLoggedIn = useSelector(isLoggedInSelector);

  return (
    <Suspense fallback={<Loader dataTestId="loader-routes-suspense" />}>
      <Switch>
        <Route exact path={routes.missingEmailSocialLogin} component={MissingEmailSocialLogin} />
        <Route exact path={routes.socialConfirmMigration} component={SocialLoginMigration} />
        <Route exact path={routes.samlConfirmMigration} component={SamlLoginMigration} />
        <Route exact path={routes.activateInvitation} component={ActivateInvitationCallback} />
        {isLoggedIn ? (
          <Main hideNavigation={hideNavigation} />
        ) : (
          <SentryErrorBoundary errorArea="authentication">
            <AuthRoutes />
          </SentryErrorBoundary>
        )}
      </Switch>
    </Suspense>
  );
};

export default Routes;
