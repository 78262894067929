import { ShardTypePricingRegion } from '../../store/databases/databases.types';
import findRegionMinimalPrice from './findRegionMinimalPrice';

interface GetSubscriptionHourlyPrice {
  minimalPricing: SubsMinimalPricing[];
  shardTypePricings: ShardTypePricingRegion[];
  plan: Plan;
}

const getSubscriptionHourlyPrice = ({
  minimalPricing,
  shardTypePricings,
  plan
}: GetSubscriptionHourlyPrice) => {
  let price = 0;

  if (shardTypePricings.length) {
    const pricesByRegions = shardTypePricings.reduce((acc, pricingRegion) => {
      if (acc[pricingRegion.name]) {
        acc[pricingRegion.name] += pricingRegion.hourly_shard_price;
      } else {
        acc[pricingRegion.name] = pricingRegion.hourly_shard_price;
      }

      return acc;
    }, {} as { [key: string]: number });

    Object.entries(pricesByRegions).forEach(([regionName, regionTotalPrice]) => {
      const minimalRegionPrice = findRegionMinimalPrice({
        regionName,
        minimalPricingList: minimalPricing,
        provider: plan.cloud.toLocaleLowerCase() as CloudProvider,
        rof: plan.is_rof
      });

      price += Math.max(regionTotalPrice, minimalRegionPrice);
    });
  } else {
    price = minimalPricing.reduce((acc, pricingRegion) => acc + pricingRegion.price, 0);
  }

  return Number(parseFloat(price.toFixed(3)));
};

export default getSubscriptionHourlyPrice;
