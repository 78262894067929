import { MutableRefObject, useState } from 'react';
import { useTheme } from 'styled-components/macro';
import { CopyIcon } from '@redislabsdev/redis-ui-icons';
import { useSelector } from 'react-redux';
import * as S from './CopySnippet.style';
import usePrefixedTranslation from '../../../../hooks/usePrefixedTranslation';
import { bdbSelector } from '../../../../store/connectionWizard/connectionWizard.selectors';

interface CopySnippetProps {
  copyReference: MutableRefObject<undefined>;
}

export function CopySnippet({ copyReference }: CopySnippetProps) {
  const { prefixedT } = usePrefixedTranslation('databasesTable.connectionWizard');
  const bdb = useSelector(bdbSelector);
  const [copied, setCopied] = useState(false);
  const theme = useTheme();
  const copyTextToClipboard = (text: string) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }

    return document.execCommand('copy', true, text);
  };

  const handleCopyClick = () => {
    setCopied(true);
    let snippet =
      copyReference && copyReference.current
        ? (copyReference.current as HTMLElement).innerText
        : '';

    snippet = snippet
      .replace(/(<username>)|(<user>)/g, 'default')
      .replace(/(<password>)|(<pass>)/g, bdb.access_control.redis_password);

    copyTextToClipboard(snippet);
    setTimeout(() => {
      setCopied(false); // short timeout to get back to copy button
    }, 2500);
  };

  return (
    <>
      {!copied ? (
        <S.StyledButton variant="primary" onClick={handleCopyClick}>
          <CopyIcon size="M" color="white" />
          {prefixedT('copy')}
        </S.StyledButton>
      ) : (
        <S.CopyWrapper>
          <S.StyledIcon size="M" color={theme.colors.green1} />
          <S.Label>{prefixedT('copied')}</S.Label>
        </S.CopyWrapper>
      )}
    </>
  );
}
