import { SubscriptionPostBody, SubscriptionRequiredPostPayload } from '../createSubscription.types';
import { subscriptionPostBodyDefaults } from './essentials.constants';

export const createSubscriptionPostBody = (
  payload: SubscriptionRequiredPostPayload,
  account: string
): SubscriptionPostBody => ({
  subscription: {
    account,
    ...subscriptionPostBodyDefaults,
    ...payload
  }
});
