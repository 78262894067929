export enum DirectionEnum {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

export enum ReducerStateStatusEnum {
  IDLE = 'idle',
  PENDING = 'pending',
  RESOLVED = 'resolved',
  REJECTED = 'rejected'
}

export enum UserTypeEnum {
  local = 'local',
  google = 'google',
  github = 'github',
  saml = 'saml'
}

export enum UserPermissionEnum {
  VIEWER = 'viewer',
  MEMBER = 'member',
  OWNER = 'owner',
  MFA_ENFORCED = 'mfa_enforced',
  SUPER_AGENT = 'super-agent'
}
