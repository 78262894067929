import { createResourceApi } from '../../createResourceApi';

export const offboardingApi = createResourceApi('/offboarding', (rest) => ({
  getReasons: () =>
    rest.get<string[]>({
      path: 'reasons'
    }),
  postFeedback: (data: OffboardingFeedbackPost) =>
    rest.post<void>({
      path: 'feedback',
      data
    }),
  getRetentionCoupon: () =>
    rest.get<RetentionCouponBody>({
      path: 'retentionCoupon'
    })
}));
