import { SvgIcon, IconNamesEnum } from '@redislabsdev/redislabs-ui-components';
import { Container } from './SuperAgentNotify.style';

const SuperAgentNotify = (isSuperAgent: boolean, EnvBanner: React.ReactNode = null) => {
  const agentType = isSuperAgent ? 'super agent mode' : 'support agent mode';

  return (
    <Container>
      <SvgIcon name={IconNamesEnum.ATTENTION2} height={16} width={16} fill="white" />
      <div>{agentType}</div>
      {EnvBanner}
    </Container>
  );
};

export default SuperAgentNotify;
