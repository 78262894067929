const usersKeys = {
  getAll: ['get-all-users'],
  getAllRoles: ['get-all-users-roles'],
  deleteUser: (userId: number) => ['delete-user', userId],
  updateUser: (userId: number) => ['update-user', userId],
  createUser: ['create-user'],
  getUser: (userId: number) => ['get-user', userId],
  getCurrentUser: ['get-current-user']
};

export default usersKeys;
